.illustration.hero {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	&.space {
		background-color: $black;
		.container {
			z-index: 3;
			color: $white;
		}
		h1 {
			text-align: center;
			font-size: 40vw;

			@include respond-to('md') {
				text-align: left;
				font-size: 20vw;
			}
		}
		.title {
			color: $white;
		}
		.subtitle {
			margin-top: -2.5rem;
			text-align: center;
			font-size: 1.5rem;

			@include respond-to('md') {
				margin-top: -5rem;
				text-align: left;
				font-size: 3rem;
			}
		}
		.moon {
			z-index: 2;
			display: flex;
			flex-direction: row-reverse;
			margin-top: -10rem;

			@include respond-to('lg') {
				margin-top: -50rem;
			}
			svg {
				max-width: 100vh;
				max-height: 100vh;
				width: 75vw;
				height: 75vw;
				.hose,
				.space-bear {
					animation: grow-space-bear-animation 20s infinite;
					animation-timing-function: cubic-bezier(.5, -.3, .5, 1.3);

					will-change: grow-space-bear-animation;
				}
				.moon,
				.base,
				.flag {
					animation: rotate-moon-animation 3s infinite;
					animation-timing-function: cubic-bezier(.5, -.3, .5, 1.3);

					will-change: rotate-moon-animation;
				}
			}
		}
		.star {
			position: fixed;
			z-index: 1;
			width: .1875rem;
			height: .1875rem;
			border-radius: $border-radius-circle;
			background-color: $white;
			color: $white;
			animation-timing-function: ease-in-out;

			will-change: star-animation;
			&.lg {
				display: none;

				@include respond-to('md') {
					display: block;
					width: .375rem;
					height: .375rem;
				}
			}
			@for $i from 1 through 200 {
				&:nth-child(#{$i}) {
					top: random(98) + vh;
					left: random(98) + vw;
					animation: star-animation random(10000) + 700 + ms infinite;
				}
				&.lg:nth-child(#{$i}) {
					top: random(98) + vh;
					left: random(98) + vw;
					animation: star-animation random(10000) + 700 + ms infinite;
				}
			}
		}
	}
}

@keyframes star-animation {
	0% {
		opacity: 1;
		transform: scale(1.2);
	}
	50% {
		opacity: 0;
		transform: scale(.8);
	}
	100% {
		opacity: 1;
		transform: scale(1.2);
	}
}

@keyframes grow-space-bear-animation {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2) rotate(5deg);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes rotate-moon-animation {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(.25rem);
	}
	100% {
		transform: translateY(0);
	}
}
