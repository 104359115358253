@import url('https://fonts.googleapis.com/css?family=Ubuntu:500,700');
@import url('https://fonts.googleapis.com/css?family=Baloo+Bhaina');
@import url('https://rsms.me/inter/inter.css');
html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}
