@import url(https://fonts.googleapis.com/css?family=Ubuntu:500,700);
@import url(https://fonts.googleapis.com/css?family=Baloo+Bhaina);
@import url(https://rsms.me/inter/inter.css);
*,
*:before,
*:after {
  box-sizing: inherit; }

html {
  box-sizing: border-box; }

input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'] {
  -webkit-appearance: none;
  -moz-appearance: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 0.0625rem dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #FFFF00;
  color: #000000; }

small {
  font-size: .8rem; }

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: .75rem;
  line-height: 0; }

sup {
  top: -.5rem; }

sub {
  bottom: -.25rem; }

img {
  display: block;
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

hr {
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-size: 1rem;
  font-family: monospace, monospace; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit; }

button {
  overflow: visible;
  outline: none; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  outline: none;
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  -webkit-appearance: textfield; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  margin: 0 .125rem;
  padding: .35rem .625rem .75rem;
  border: .0625rem solid #C0C0C0; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td,
th {
  padding: 0; }

html {
  font: normal normal normal 1rem/1.6 "Inter", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #FFFFFF;
  color: #030303;
  font-size: 1rem; }
  body p:not(:first-child) {
    padding-top: 0.5rem; }

body,
p {
  margin: 0;
  padding: 0;
  line-height: 1.6; }

main {
  flex: 1 1; }

p,
ol,
ul,
dl,
table {
  margin: 0; }

ul li ul {
  margin-bottom: 0; }

ol li ol {
  margin-bottom: 0; }

h1,
h2,
h3,
h4,
h5 {
  color: inherit;
  font-weight: 700;
  font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
  line-height: 1.6; }
  h1:not(:first-child),
  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child),
  h5:not(:first-child) {
    margin: 1.5rem 0; }

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child) {
  margin-top: 2rem; }

a {
  background: transparent;
  color: #030303;
  text-decoration: none;
  cursor: pointer;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: black;
    text-decoration: underline; }

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 75rem; }
  .container.full-x {
    max-width: none;
    padding: 0; }

.hero {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  background: #fafafa;
  color: #030303; }
  .hero h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .hero h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .hero h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .hero h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .hero h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .hero p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
    color: #030303; }
  .hero a:not(.bb-btn):not(.link):not(.item) {
    color: #030303; }
  .hero.invert {
    background: #FFFFFF;
    color: #030303; }
    .hero.invert h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.invert h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.invert h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.invert h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.invert h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.invert p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #030303; }
    .hero.invert a:not(.bb-btn):not(.link):not(.item) {
      color: #030303; }
  .hero.primary {
    background: #0A344D;
    color: #FFFFFF; }
    .hero.primary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.primary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.primary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.primary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.primary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.primary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .hero.primary a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .hero.secondary {
    background: #FA7268;
    color: #FFFFFF; }
    .hero.secondary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.secondary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.secondary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.secondary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.secondary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.secondary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .hero.secondary a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .hero.positive {
    background: #50BF63;
    color: #FFFFFF; }
    .hero.positive h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.positive h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.positive h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.positive h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.positive h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.positive p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .hero.positive a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .hero.negative {
    background: #DC4C41;
    color: #FFFFFF; }
    .hero.negative h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.negative h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.negative h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.negative h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.negative h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .hero.negative p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .hero.negative a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .hero.full-y {
    min-height: 100vh; }
    .hero.full-y .body {
      display: flex;
      align-items: center; }
      .hero.full-y .body > .container {
        flex-grow: 1;
        flex-shrink: 1; }
  .hero .head,
  .hero .tail {
    flex-grow: 0;
    flex-shrink: 0; }
  .hero .body {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 2rem 1.5rem; }

.section {
  margin: 0;
  padding: 2rem 1.5rem; }
  .section.lg {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .section.invert {
    background: #FFFFFF;
    color: #030303; }
    .section.invert h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.invert h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.invert h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.invert h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.invert h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.invert p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #030303; }
    .section.invert a:not(.bb-btn):not(.link):not(.item) {
      color: #030303; }
  .section.primary {
    background: #0A344D;
    color: #FFFFFF; }
    .section.primary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.primary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.primary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.primary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.primary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.primary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .section.primary a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .section.secondary {
    background: #FA7268;
    color: #FFFFFF; }
    .section.secondary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.secondary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.secondary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.secondary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.secondary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.secondary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .section.secondary a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .section.positive {
    background: #50BF63;
    color: #FFFFFF; }
    .section.positive h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.positive h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.positive h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.positive h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.positive h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.positive p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .section.positive a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .section.negative {
    background: #DC4C41;
    color: #FFFFFF; }
    .section.negative h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.negative h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.negative h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.negative h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.negative h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .section.negative p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .section.negative a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .section.full-y {
    padding: 0 1.5rem; }
  .section.full-x {
    padding: 2rem 0; }
  .section.p-t-0 {
    padding-top: 0; }
  .section.p-b-0 {
    padding-bottom: 0; }

.bb-grid {
  display: block;
  flex-flow: wrap;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: -0.5rem;
  margin-bottom: 1rem;
  margin-left: -0.5rem; }
  @media (min-width: 57.5rem) {
    .bb-grid {
      display: flex; }
      .bb-grid .one-quarter {
        flex: none;
        width: 25%; }
      .bb-grid .half {
        flex: none;
        width: 50%; }
      .bb-grid .three-quarters {
        flex: none;
        width: 75%; }
      .bb-grid .one-third {
        flex: none;
        width: 33.3333%; }
      .bb-grid .two-thirds {
        flex: none;
        width: 66.6666%; }
      .bb-grid .offset-one-quarter {
        margin-left: 25%; }
      .bb-grid .offset-half {
        margin-left: 50%; }
      .bb-grid .offset-three-quarters {
        margin-left: 75%; }
      .bb-grid .offset-one-third {
        margin-left: 33.3333%; }
      .bb-grid .offset-two-thirds {
        margin-left: 66.6666%; }
      .bb-grid .column-1 {
        flex: none;
        width: 6.25%; }
      .bb-grid .column-offset-1 {
        margin-left: 6.25%; }
      .bb-grid .column-2 {
        flex: none;
        width: 12.5%; }
      .bb-grid .column-offset-2 {
        margin-left: 12.5%; }
      .bb-grid .column-3 {
        flex: none;
        width: 18.75%; }
      .bb-grid .column-offset-3 {
        margin-left: 18.75%; }
      .bb-grid .column-4 {
        flex: none;
        width: 25%; }
      .bb-grid .column-offset-4 {
        margin-left: 25%; }
      .bb-grid .column-5 {
        flex: none;
        width: 31.25%; }
      .bb-grid .column-offset-5 {
        margin-left: 31.25%; }
      .bb-grid .column-6 {
        flex: none;
        width: 37.5%; }
      .bb-grid .column-offset-6 {
        margin-left: 37.5%; }
      .bb-grid .column-7 {
        flex: none;
        width: 43.75%; }
      .bb-grid .column-offset-7 {
        margin-left: 43.75%; }
      .bb-grid .column-8 {
        flex: none;
        width: 50%; }
      .bb-grid .column-offset-8 {
        margin-left: 50%; }
      .bb-grid .column-9 {
        flex: none;
        width: 56.25%; }
      .bb-grid .column-offset-9 {
        margin-left: 56.25%; }
      .bb-grid .column-10 {
        flex: none;
        width: 62.5%; }
      .bb-grid .column-offset-10 {
        margin-left: 62.5%; }
      .bb-grid .column-11 {
        flex: none;
        width: 68.75%; }
      .bb-grid .column-offset-11 {
        margin-left: 68.75%; }
      .bb-grid .column-12 {
        flex: none;
        width: 75%; }
      .bb-grid .column-offset-12 {
        margin-left: 75%; }
      .bb-grid .column-13 {
        flex: none;
        width: 81.25%; }
      .bb-grid .column-offset-13 {
        margin-left: 81.25%; }
      .bb-grid .column-14 {
        flex: none;
        width: 87.5%; }
      .bb-grid .column-offset-14 {
        margin-left: 87.5%; }
      .bb-grid .column-15 {
        flex: none;
        width: 93.75%; }
      .bb-grid .column-offset-15 {
        margin-left: 93.75%; }
      .bb-grid .column-16 {
        flex: none;
        width: 100%; }
      .bb-grid .column-offset-16 {
        margin-left: 100%; } }
  .bb-grid:last-child {
    margin-bottom: -0.5rem; }
  .bb-grid.center {
    justify-content: center; }
  .bb-grid.connected {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0; }
    .bb-grid.connected:last-child {
      margin-bottom: 0; }
    .bb-grid.connected > .column {
      margin: 0;
      padding: 0; }
  .bb-grid .column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
    .bb-grid .column:last-child {
      padding-bottom: 1rem; }
    .bb-grid .column.full-y {
      flex: 1 1; }
      .bb-grid .column.full-y > div {
        height: 100%; }
  @media (min-width: 28rem) {
    .bb-grid .one-quarter-sm {
      flex: none;
      width: 25%; }
    .bb-grid .half-sm {
      flex: none;
      width: 50%; }
    .bb-grid .three-quarters-sm {
      flex: none;
      width: 75%; }
    .bb-grid .one-third-sm {
      flex: none;
      width: 33.3333%; }
    .bb-grid .two-thirds-sm {
      flex: none;
      width: 66.6666%; }
    .bb-grid .offset-one-quarter-sm {
      margin-left: 25%; }
    .bb-grid .offset-half-sm {
      margin-left: 50%; }
    .bb-grid .offset-three-quarters-sm {
      margin-left: 75%; }
    .bb-grid .offset-one-third-sm {
      margin-left: 33.3333%; }
    .bb-grid .offset-two-thirds-sm {
      margin-left: 66.6666%; }
    .bb-grid .column-1-sm {
      flex: none;
      width: 6.25%; }
    .bb-grid .column-offset-1-sm {
      margin-left: 6.25%; }
    .bb-grid .column-2-sm {
      flex: none;
      width: 12.5%; }
    .bb-grid .column-offset-2-sm {
      margin-left: 12.5%; }
    .bb-grid .column-3-sm {
      flex: none;
      width: 18.75%; }
    .bb-grid .column-offset-3-sm {
      margin-left: 18.75%; }
    .bb-grid .column-4-sm {
      flex: none;
      width: 25%; }
    .bb-grid .column-offset-4-sm {
      margin-left: 25%; }
    .bb-grid .column-5-sm {
      flex: none;
      width: 31.25%; }
    .bb-grid .column-offset-5-sm {
      margin-left: 31.25%; }
    .bb-grid .column-6-sm {
      flex: none;
      width: 37.5%; }
    .bb-grid .column-offset-6-sm {
      margin-left: 37.5%; }
    .bb-grid .column-7-sm {
      flex: none;
      width: 43.75%; }
    .bb-grid .column-offset-7-sm {
      margin-left: 43.75%; }
    .bb-grid .column-8-sm {
      flex: none;
      width: 50%; }
    .bb-grid .column-offset-8-sm {
      margin-left: 50%; }
    .bb-grid .column-9-sm {
      flex: none;
      width: 56.25%; }
    .bb-grid .column-offset-9-sm {
      margin-left: 56.25%; }
    .bb-grid .column-10-sm {
      flex: none;
      width: 62.5%; }
    .bb-grid .column-offset-10-sm {
      margin-left: 62.5%; }
    .bb-grid .column-11-sm {
      flex: none;
      width: 68.75%; }
    .bb-grid .column-offset-11-sm {
      margin-left: 68.75%; }
    .bb-grid .column-12-sm {
      flex: none;
      width: 75%; }
    .bb-grid .column-offset-12-sm {
      margin-left: 75%; }
    .bb-grid .column-13-sm {
      flex: none;
      width: 81.25%; }
    .bb-grid .column-offset-13-sm {
      margin-left: 81.25%; }
    .bb-grid .column-14-sm {
      flex: none;
      width: 87.5%; }
    .bb-grid .column-offset-14-sm {
      margin-left: 87.5%; }
    .bb-grid .column-15-sm {
      flex: none;
      width: 93.75%; }
    .bb-grid .column-offset-15-sm {
      margin-left: 93.75%; }
    .bb-grid .column-16-sm {
      flex: none;
      width: 100%; }
    .bb-grid .column-offset-16-sm {
      margin-left: 100%; }
    .bb-grid.sm {
      display: flex; }
      .bb-grid.sm .one-quarter {
        flex: none;
        width: 25%; }
      .bb-grid.sm .half {
        flex: none;
        width: 50%; }
      .bb-grid.sm .three-quarters {
        flex: none;
        width: 75%; }
      .bb-grid.sm .one-third {
        flex: none;
        width: 33.3333%; }
      .bb-grid.sm .two-thirds {
        flex: none;
        width: 66.6666%; }
      .bb-grid.sm .offset-one-quarter {
        margin-left: 25%; }
      .bb-grid.sm .offset-half {
        margin-left: 50%; }
      .bb-grid.sm .offset-three-quarters {
        margin-left: 75%; }
      .bb-grid.sm .offset-one-third {
        margin-left: 33.3333%; }
      .bb-grid.sm .offset-two-thirds {
        margin-left: 66.6666%; }
      .bb-grid.sm .column-1 {
        flex: none;
        width: 6.25%; }
      .bb-grid.sm .column-offset-1 {
        margin-left: 6.25%; }
      .bb-grid.sm .column-2 {
        flex: none;
        width: 12.5%; }
      .bb-grid.sm .column-offset-2 {
        margin-left: 12.5%; }
      .bb-grid.sm .column-3 {
        flex: none;
        width: 18.75%; }
      .bb-grid.sm .column-offset-3 {
        margin-left: 18.75%; }
      .bb-grid.sm .column-4 {
        flex: none;
        width: 25%; }
      .bb-grid.sm .column-offset-4 {
        margin-left: 25%; }
      .bb-grid.sm .column-5 {
        flex: none;
        width: 31.25%; }
      .bb-grid.sm .column-offset-5 {
        margin-left: 31.25%; }
      .bb-grid.sm .column-6 {
        flex: none;
        width: 37.5%; }
      .bb-grid.sm .column-offset-6 {
        margin-left: 37.5%; }
      .bb-grid.sm .column-7 {
        flex: none;
        width: 43.75%; }
      .bb-grid.sm .column-offset-7 {
        margin-left: 43.75%; }
      .bb-grid.sm .column-8 {
        flex: none;
        width: 50%; }
      .bb-grid.sm .column-offset-8 {
        margin-left: 50%; }
      .bb-grid.sm .column-9 {
        flex: none;
        width: 56.25%; }
      .bb-grid.sm .column-offset-9 {
        margin-left: 56.25%; }
      .bb-grid.sm .column-10 {
        flex: none;
        width: 62.5%; }
      .bb-grid.sm .column-offset-10 {
        margin-left: 62.5%; }
      .bb-grid.sm .column-11 {
        flex: none;
        width: 68.75%; }
      .bb-grid.sm .column-offset-11 {
        margin-left: 68.75%; }
      .bb-grid.sm .column-12 {
        flex: none;
        width: 75%; }
      .bb-grid.sm .column-offset-12 {
        margin-left: 75%; }
      .bb-grid.sm .column-13 {
        flex: none;
        width: 81.25%; }
      .bb-grid.sm .column-offset-13 {
        margin-left: 81.25%; }
      .bb-grid.sm .column-14 {
        flex: none;
        width: 87.5%; }
      .bb-grid.sm .column-offset-14 {
        margin-left: 87.5%; }
      .bb-grid.sm .column-15 {
        flex: none;
        width: 93.75%; }
      .bb-grid.sm .column-offset-15 {
        margin-left: 93.75%; }
      .bb-grid.sm .column-16 {
        flex: none;
        width: 100%; }
      .bb-grid.sm .column-offset-16 {
        margin-left: 100%; } }
  @media (min-width: 28rem) and (min-width: 28rem) {
    .bb-grid.sm.reverse {
      flex-direction: row-reverse; } }
  @media (min-width: 40rem) {
    .bb-grid .one-quarter-md {
      flex: none;
      width: 25%; }
    .bb-grid .half-md {
      flex: none;
      width: 50%; }
    .bb-grid .three-quarters-md {
      flex: none;
      width: 75%; }
    .bb-grid .one-third-md {
      flex: none;
      width: 33.3333%; }
    .bb-grid .two-thirds-md {
      flex: none;
      width: 66.6666%; }
    .bb-grid .offset-one-quarter-md {
      margin-left: 25%; }
    .bb-grid .offset-half-md {
      margin-left: 50%; }
    .bb-grid .offset-three-quarters-md {
      margin-left: 75%; }
    .bb-grid .offset-one-third-md {
      margin-left: 33.3333%; }
    .bb-grid .offset-two-thirds-md {
      margin-left: 66.6666%; }
    .bb-grid .column-1-md {
      flex: none;
      width: 6.25%; }
    .bb-grid .column-offset-1-md {
      margin-left: 6.25%; }
    .bb-grid .column-2-md {
      flex: none;
      width: 12.5%; }
    .bb-grid .column-offset-2-md {
      margin-left: 12.5%; }
    .bb-grid .column-3-md {
      flex: none;
      width: 18.75%; }
    .bb-grid .column-offset-3-md {
      margin-left: 18.75%; }
    .bb-grid .column-4-md {
      flex: none;
      width: 25%; }
    .bb-grid .column-offset-4-md {
      margin-left: 25%; }
    .bb-grid .column-5-md {
      flex: none;
      width: 31.25%; }
    .bb-grid .column-offset-5-md {
      margin-left: 31.25%; }
    .bb-grid .column-6-md {
      flex: none;
      width: 37.5%; }
    .bb-grid .column-offset-6-md {
      margin-left: 37.5%; }
    .bb-grid .column-7-md {
      flex: none;
      width: 43.75%; }
    .bb-grid .column-offset-7-md {
      margin-left: 43.75%; }
    .bb-grid .column-8-md {
      flex: none;
      width: 50%; }
    .bb-grid .column-offset-8-md {
      margin-left: 50%; }
    .bb-grid .column-9-md {
      flex: none;
      width: 56.25%; }
    .bb-grid .column-offset-9-md {
      margin-left: 56.25%; }
    .bb-grid .column-10-md {
      flex: none;
      width: 62.5%; }
    .bb-grid .column-offset-10-md {
      margin-left: 62.5%; }
    .bb-grid .column-11-md {
      flex: none;
      width: 68.75%; }
    .bb-grid .column-offset-11-md {
      margin-left: 68.75%; }
    .bb-grid .column-12-md {
      flex: none;
      width: 75%; }
    .bb-grid .column-offset-12-md {
      margin-left: 75%; }
    .bb-grid .column-13-md {
      flex: none;
      width: 81.25%; }
    .bb-grid .column-offset-13-md {
      margin-left: 81.25%; }
    .bb-grid .column-14-md {
      flex: none;
      width: 87.5%; }
    .bb-grid .column-offset-14-md {
      margin-left: 87.5%; }
    .bb-grid .column-15-md {
      flex: none;
      width: 93.75%; }
    .bb-grid .column-offset-15-md {
      margin-left: 93.75%; }
    .bb-grid .column-16-md {
      flex: none;
      width: 100%; }
    .bb-grid .column-offset-16-md {
      margin-left: 100%; }
    .bb-grid.md {
      display: flex; }
      .bb-grid.md .one-quarter {
        flex: none;
        width: 25%; }
      .bb-grid.md .half {
        flex: none;
        width: 50%; }
      .bb-grid.md .three-quarters {
        flex: none;
        width: 75%; }
      .bb-grid.md .one-third {
        flex: none;
        width: 33.3333%; }
      .bb-grid.md .two-thirds {
        flex: none;
        width: 66.6666%; }
      .bb-grid.md .offset-one-quarter {
        margin-left: 25%; }
      .bb-grid.md .offset-half {
        margin-left: 50%; }
      .bb-grid.md .offset-three-quarters {
        margin-left: 75%; }
      .bb-grid.md .offset-one-third {
        margin-left: 33.3333%; }
      .bb-grid.md .offset-two-thirds {
        margin-left: 66.6666%; }
      .bb-grid.md .column-1 {
        flex: none;
        width: 6.25%; }
      .bb-grid.md .column-offset-1 {
        margin-left: 6.25%; }
      .bb-grid.md .column-2 {
        flex: none;
        width: 12.5%; }
      .bb-grid.md .column-offset-2 {
        margin-left: 12.5%; }
      .bb-grid.md .column-3 {
        flex: none;
        width: 18.75%; }
      .bb-grid.md .column-offset-3 {
        margin-left: 18.75%; }
      .bb-grid.md .column-4 {
        flex: none;
        width: 25%; }
      .bb-grid.md .column-offset-4 {
        margin-left: 25%; }
      .bb-grid.md .column-5 {
        flex: none;
        width: 31.25%; }
      .bb-grid.md .column-offset-5 {
        margin-left: 31.25%; }
      .bb-grid.md .column-6 {
        flex: none;
        width: 37.5%; }
      .bb-grid.md .column-offset-6 {
        margin-left: 37.5%; }
      .bb-grid.md .column-7 {
        flex: none;
        width: 43.75%; }
      .bb-grid.md .column-offset-7 {
        margin-left: 43.75%; }
      .bb-grid.md .column-8 {
        flex: none;
        width: 50%; }
      .bb-grid.md .column-offset-8 {
        margin-left: 50%; }
      .bb-grid.md .column-9 {
        flex: none;
        width: 56.25%; }
      .bb-grid.md .column-offset-9 {
        margin-left: 56.25%; }
      .bb-grid.md .column-10 {
        flex: none;
        width: 62.5%; }
      .bb-grid.md .column-offset-10 {
        margin-left: 62.5%; }
      .bb-grid.md .column-11 {
        flex: none;
        width: 68.75%; }
      .bb-grid.md .column-offset-11 {
        margin-left: 68.75%; }
      .bb-grid.md .column-12 {
        flex: none;
        width: 75%; }
      .bb-grid.md .column-offset-12 {
        margin-left: 75%; }
      .bb-grid.md .column-13 {
        flex: none;
        width: 81.25%; }
      .bb-grid.md .column-offset-13 {
        margin-left: 81.25%; }
      .bb-grid.md .column-14 {
        flex: none;
        width: 87.5%; }
      .bb-grid.md .column-offset-14 {
        margin-left: 87.5%; }
      .bb-grid.md .column-15 {
        flex: none;
        width: 93.75%; }
      .bb-grid.md .column-offset-15 {
        margin-left: 93.75%; }
      .bb-grid.md .column-16 {
        flex: none;
        width: 100%; }
      .bb-grid.md .column-offset-16 {
        margin-left: 100%; } }
  @media (min-width: 40rem) and (min-width: 40rem) {
    .bb-grid.md.reverse {
      flex-direction: row-reverse; } }
  @media (min-width: 57.5rem) {
    .bb-grid .one-quarter-lg {
      flex: none;
      width: 25%; }
    .bb-grid .half-lg {
      flex: none;
      width: 50%; }
    .bb-grid .three-quarters-lg {
      flex: none;
      width: 75%; }
    .bb-grid .one-third-lg {
      flex: none;
      width: 33.3333%; }
    .bb-grid .two-thirds-lg {
      flex: none;
      width: 66.6666%; }
    .bb-grid .offset-one-quarter-lg {
      margin-left: 25%; }
    .bb-grid .offset-half-lg {
      margin-left: 50%; }
    .bb-grid .offset-three-quarters-lg {
      margin-left: 75%; }
    .bb-grid .offset-one-third-lg {
      margin-left: 33.3333%; }
    .bb-grid .offset-two-thirds-lg {
      margin-left: 66.6666%; }
    .bb-grid .column-1-lg {
      flex: none;
      width: 6.25%; }
    .bb-grid .column-offset-1-lg {
      margin-left: 6.25%; }
    .bb-grid .column-2-lg {
      flex: none;
      width: 12.5%; }
    .bb-grid .column-offset-2-lg {
      margin-left: 12.5%; }
    .bb-grid .column-3-lg {
      flex: none;
      width: 18.75%; }
    .bb-grid .column-offset-3-lg {
      margin-left: 18.75%; }
    .bb-grid .column-4-lg {
      flex: none;
      width: 25%; }
    .bb-grid .column-offset-4-lg {
      margin-left: 25%; }
    .bb-grid .column-5-lg {
      flex: none;
      width: 31.25%; }
    .bb-grid .column-offset-5-lg {
      margin-left: 31.25%; }
    .bb-grid .column-6-lg {
      flex: none;
      width: 37.5%; }
    .bb-grid .column-offset-6-lg {
      margin-left: 37.5%; }
    .bb-grid .column-7-lg {
      flex: none;
      width: 43.75%; }
    .bb-grid .column-offset-7-lg {
      margin-left: 43.75%; }
    .bb-grid .column-8-lg {
      flex: none;
      width: 50%; }
    .bb-grid .column-offset-8-lg {
      margin-left: 50%; }
    .bb-grid .column-9-lg {
      flex: none;
      width: 56.25%; }
    .bb-grid .column-offset-9-lg {
      margin-left: 56.25%; }
    .bb-grid .column-10-lg {
      flex: none;
      width: 62.5%; }
    .bb-grid .column-offset-10-lg {
      margin-left: 62.5%; }
    .bb-grid .column-11-lg {
      flex: none;
      width: 68.75%; }
    .bb-grid .column-offset-11-lg {
      margin-left: 68.75%; }
    .bb-grid .column-12-lg {
      flex: none;
      width: 75%; }
    .bb-grid .column-offset-12-lg {
      margin-left: 75%; }
    .bb-grid .column-13-lg {
      flex: none;
      width: 81.25%; }
    .bb-grid .column-offset-13-lg {
      margin-left: 81.25%; }
    .bb-grid .column-14-lg {
      flex: none;
      width: 87.5%; }
    .bb-grid .column-offset-14-lg {
      margin-left: 87.5%; }
    .bb-grid .column-15-lg {
      flex: none;
      width: 93.75%; }
    .bb-grid .column-offset-15-lg {
      margin-left: 93.75%; }
    .bb-grid .column-16-lg {
      flex: none;
      width: 100%; }
    .bb-grid .column-offset-16-lg {
      margin-left: 100%; }
    .bb-grid.lg {
      display: flex; }
      .bb-grid.lg .one-quarter {
        flex: none;
        width: 25%; }
      .bb-grid.lg .half {
        flex: none;
        width: 50%; }
      .bb-grid.lg .three-quarters {
        flex: none;
        width: 75%; }
      .bb-grid.lg .one-third {
        flex: none;
        width: 33.3333%; }
      .bb-grid.lg .two-thirds {
        flex: none;
        width: 66.6666%; }
      .bb-grid.lg .offset-one-quarter {
        margin-left: 25%; }
      .bb-grid.lg .offset-half {
        margin-left: 50%; }
      .bb-grid.lg .offset-three-quarters {
        margin-left: 75%; }
      .bb-grid.lg .offset-one-third {
        margin-left: 33.3333%; }
      .bb-grid.lg .offset-two-thirds {
        margin-left: 66.6666%; }
      .bb-grid.lg .column-1 {
        flex: none;
        width: 6.25%; }
      .bb-grid.lg .column-offset-1 {
        margin-left: 6.25%; }
      .bb-grid.lg .column-2 {
        flex: none;
        width: 12.5%; }
      .bb-grid.lg .column-offset-2 {
        margin-left: 12.5%; }
      .bb-grid.lg .column-3 {
        flex: none;
        width: 18.75%; }
      .bb-grid.lg .column-offset-3 {
        margin-left: 18.75%; }
      .bb-grid.lg .column-4 {
        flex: none;
        width: 25%; }
      .bb-grid.lg .column-offset-4 {
        margin-left: 25%; }
      .bb-grid.lg .column-5 {
        flex: none;
        width: 31.25%; }
      .bb-grid.lg .column-offset-5 {
        margin-left: 31.25%; }
      .bb-grid.lg .column-6 {
        flex: none;
        width: 37.5%; }
      .bb-grid.lg .column-offset-6 {
        margin-left: 37.5%; }
      .bb-grid.lg .column-7 {
        flex: none;
        width: 43.75%; }
      .bb-grid.lg .column-offset-7 {
        margin-left: 43.75%; }
      .bb-grid.lg .column-8 {
        flex: none;
        width: 50%; }
      .bb-grid.lg .column-offset-8 {
        margin-left: 50%; }
      .bb-grid.lg .column-9 {
        flex: none;
        width: 56.25%; }
      .bb-grid.lg .column-offset-9 {
        margin-left: 56.25%; }
      .bb-grid.lg .column-10 {
        flex: none;
        width: 62.5%; }
      .bb-grid.lg .column-offset-10 {
        margin-left: 62.5%; }
      .bb-grid.lg .column-11 {
        flex: none;
        width: 68.75%; }
      .bb-grid.lg .column-offset-11 {
        margin-left: 68.75%; }
      .bb-grid.lg .column-12 {
        flex: none;
        width: 75%; }
      .bb-grid.lg .column-offset-12 {
        margin-left: 75%; }
      .bb-grid.lg .column-13 {
        flex: none;
        width: 81.25%; }
      .bb-grid.lg .column-offset-13 {
        margin-left: 81.25%; }
      .bb-grid.lg .column-14 {
        flex: none;
        width: 87.5%; }
      .bb-grid.lg .column-offset-14 {
        margin-left: 87.5%; }
      .bb-grid.lg .column-15 {
        flex: none;
        width: 93.75%; }
      .bb-grid.lg .column-offset-15 {
        margin-left: 93.75%; }
      .bb-grid.lg .column-16 {
        flex: none;
        width: 100%; }
      .bb-grid.lg .column-offset-16 {
        margin-left: 100%; } }
  @media (min-width: 57.5rem) and (min-width: 57.5rem) {
    .bb-grid.lg.reverse {
      flex-direction: row-reverse; } }
  @media (min-width: 75rem) {
    .bb-grid .one-quarter-xl {
      flex: none;
      width: 25%; }
    .bb-grid .half-xl {
      flex: none;
      width: 50%; }
    .bb-grid .three-quarters-xl {
      flex: none;
      width: 75%; }
    .bb-grid .one-third-xl {
      flex: none;
      width: 33.3333%; }
    .bb-grid .two-thirds-xl {
      flex: none;
      width: 66.6666%; }
    .bb-grid .offset-one-quarter-xl {
      margin-left: 25%; }
    .bb-grid .offset-half-xl {
      margin-left: 50%; }
    .bb-grid .offset-three-quarters-xl {
      margin-left: 75%; }
    .bb-grid .offset-one-third-xl {
      margin-left: 33.3333%; }
    .bb-grid .offset-two-thirds-xl {
      margin-left: 66.6666%; }
    .bb-grid .column-1-xl {
      flex: none;
      width: 6.25%; }
    .bb-grid .column-offset-1-xl {
      margin-left: 6.25%; }
    .bb-grid .column-2-xl {
      flex: none;
      width: 12.5%; }
    .bb-grid .column-offset-2-xl {
      margin-left: 12.5%; }
    .bb-grid .column-3-xl {
      flex: none;
      width: 18.75%; }
    .bb-grid .column-offset-3-xl {
      margin-left: 18.75%; }
    .bb-grid .column-4-xl {
      flex: none;
      width: 25%; }
    .bb-grid .column-offset-4-xl {
      margin-left: 25%; }
    .bb-grid .column-5-xl {
      flex: none;
      width: 31.25%; }
    .bb-grid .column-offset-5-xl {
      margin-left: 31.25%; }
    .bb-grid .column-6-xl {
      flex: none;
      width: 37.5%; }
    .bb-grid .column-offset-6-xl {
      margin-left: 37.5%; }
    .bb-grid .column-7-xl {
      flex: none;
      width: 43.75%; }
    .bb-grid .column-offset-7-xl {
      margin-left: 43.75%; }
    .bb-grid .column-8-xl {
      flex: none;
      width: 50%; }
    .bb-grid .column-offset-8-xl {
      margin-left: 50%; }
    .bb-grid .column-9-xl {
      flex: none;
      width: 56.25%; }
    .bb-grid .column-offset-9-xl {
      margin-left: 56.25%; }
    .bb-grid .column-10-xl {
      flex: none;
      width: 62.5%; }
    .bb-grid .column-offset-10-xl {
      margin-left: 62.5%; }
    .bb-grid .column-11-xl {
      flex: none;
      width: 68.75%; }
    .bb-grid .column-offset-11-xl {
      margin-left: 68.75%; }
    .bb-grid .column-12-xl {
      flex: none;
      width: 75%; }
    .bb-grid .column-offset-12-xl {
      margin-left: 75%; }
    .bb-grid .column-13-xl {
      flex: none;
      width: 81.25%; }
    .bb-grid .column-offset-13-xl {
      margin-left: 81.25%; }
    .bb-grid .column-14-xl {
      flex: none;
      width: 87.5%; }
    .bb-grid .column-offset-14-xl {
      margin-left: 87.5%; }
    .bb-grid .column-15-xl {
      flex: none;
      width: 93.75%; }
    .bb-grid .column-offset-15-xl {
      margin-left: 93.75%; }
    .bb-grid .column-16-xl {
      flex: none;
      width: 100%; }
    .bb-grid .column-offset-16-xl {
      margin-left: 100%; }
    .bb-grid.xl {
      display: flex; }
      .bb-grid.xl .one-quarter {
        flex: none;
        width: 25%; }
      .bb-grid.xl .half {
        flex: none;
        width: 50%; }
      .bb-grid.xl .three-quarters {
        flex: none;
        width: 75%; }
      .bb-grid.xl .one-third {
        flex: none;
        width: 33.3333%; }
      .bb-grid.xl .two-thirds {
        flex: none;
        width: 66.6666%; }
      .bb-grid.xl .offset-one-quarter {
        margin-left: 25%; }
      .bb-grid.xl .offset-half {
        margin-left: 50%; }
      .bb-grid.xl .offset-three-quarters {
        margin-left: 75%; }
      .bb-grid.xl .offset-one-third {
        margin-left: 33.3333%; }
      .bb-grid.xl .offset-two-thirds {
        margin-left: 66.6666%; }
      .bb-grid.xl .column-1 {
        flex: none;
        width: 6.25%; }
      .bb-grid.xl .column-offset-1 {
        margin-left: 6.25%; }
      .bb-grid.xl .column-2 {
        flex: none;
        width: 12.5%; }
      .bb-grid.xl .column-offset-2 {
        margin-left: 12.5%; }
      .bb-grid.xl .column-3 {
        flex: none;
        width: 18.75%; }
      .bb-grid.xl .column-offset-3 {
        margin-left: 18.75%; }
      .bb-grid.xl .column-4 {
        flex: none;
        width: 25%; }
      .bb-grid.xl .column-offset-4 {
        margin-left: 25%; }
      .bb-grid.xl .column-5 {
        flex: none;
        width: 31.25%; }
      .bb-grid.xl .column-offset-5 {
        margin-left: 31.25%; }
      .bb-grid.xl .column-6 {
        flex: none;
        width: 37.5%; }
      .bb-grid.xl .column-offset-6 {
        margin-left: 37.5%; }
      .bb-grid.xl .column-7 {
        flex: none;
        width: 43.75%; }
      .bb-grid.xl .column-offset-7 {
        margin-left: 43.75%; }
      .bb-grid.xl .column-8 {
        flex: none;
        width: 50%; }
      .bb-grid.xl .column-offset-8 {
        margin-left: 50%; }
      .bb-grid.xl .column-9 {
        flex: none;
        width: 56.25%; }
      .bb-grid.xl .column-offset-9 {
        margin-left: 56.25%; }
      .bb-grid.xl .column-10 {
        flex: none;
        width: 62.5%; }
      .bb-grid.xl .column-offset-10 {
        margin-left: 62.5%; }
      .bb-grid.xl .column-11 {
        flex: none;
        width: 68.75%; }
      .bb-grid.xl .column-offset-11 {
        margin-left: 68.75%; }
      .bb-grid.xl .column-12 {
        flex: none;
        width: 75%; }
      .bb-grid.xl .column-offset-12 {
        margin-left: 75%; }
      .bb-grid.xl .column-13 {
        flex: none;
        width: 81.25%; }
      .bb-grid.xl .column-offset-13 {
        margin-left: 81.25%; }
      .bb-grid.xl .column-14 {
        flex: none;
        width: 87.5%; }
      .bb-grid.xl .column-offset-14 {
        margin-left: 87.5%; }
      .bb-grid.xl .column-15 {
        flex: none;
        width: 93.75%; }
      .bb-grid.xl .column-offset-15 {
        margin-left: 93.75%; }
      .bb-grid.xl .column-16 {
        flex: none;
        width: 100%; }
      .bb-grid.xl .column-offset-16 {
        margin-left: 100%; } }
  @media (min-width: 75rem) and (min-width: 75rem) {
    .bb-grid.xl.reverse {
      flex-direction: row-reverse; } }

.bb-family-grid {
  display: block;
  align-items: stretch;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content; }
  @media (min-width: 57.5rem) {
    .bb-family-grid:not(.child) {
      display: flex; }
    .bb-family-grid .one-quarter {
      flex: none;
      width: 25%; }
    .bb-family-grid .half {
      flex: none;
      width: 50%; }
    .bb-family-grid .three-quarters {
      flex: none;
      width: 75%; }
    .bb-family-grid .one-third {
      flex: none;
      width: 33.3333%; }
    .bb-family-grid .two-thirds {
      flex: none;
      width: 66.6666%; }
    .bb-family-grid .column-1 {
      flex: none;
      width: 6.25%; }
    .bb-family-grid .column-2 {
      flex: none;
      width: 12.5%; }
    .bb-family-grid .column-3 {
      flex: none;
      width: 18.75%; }
    .bb-family-grid .column-4 {
      flex: none;
      width: 25%; }
    .bb-family-grid .column-5 {
      flex: none;
      width: 31.25%; }
    .bb-family-grid .column-6 {
      flex: none;
      width: 37.5%; }
    .bb-family-grid .column-7 {
      flex: none;
      width: 43.75%; }
    .bb-family-grid .column-8 {
      flex: none;
      width: 50%; }
    .bb-family-grid .column-9 {
      flex: none;
      width: 56.25%; }
    .bb-family-grid .column-10 {
      flex: none;
      width: 62.5%; }
    .bb-family-grid .column-11 {
      flex: none;
      width: 68.75%; }
    .bb-family-grid .column-12 {
      flex: none;
      width: 75%; }
    .bb-family-grid .column-13 {
      flex: none;
      width: 81.25%; }
    .bb-family-grid .column-14 {
      flex: none;
      width: 87.5%; }
    .bb-family-grid .column-15 {
      flex: none;
      width: 93.75%; }
    .bb-family-grid .column-16 {
      flex: none;
      width: 100%; } }
  .bb-family-grid.grand-parent {
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    .bb-family-grid.grand-parent:last-child {
      margin-bottom: -0.5rem; }
    .bb-family-grid.grand-parent:not(:last-child) {
      margin-bottom: 0.5rem; }
  .bb-family-grid.child {
    margin: 0; }
  .bb-family-grid.parent {
    padding: 0.5rem 0.5rem; }
  .bb-family-grid.vertical {
    flex-direction: column; }
    .bb-family-grid.vertical > .bb-family-grid.child:not(:last-child) {
      margin-bottom: 1rem; }

.bb-accordion {
  width: 100%;
  border-bottom: 0.125rem solid #E0E0E0; }
  .bb-accordion.invert .header {
    color: #FFFFFF; }
    .bb-accordion.invert .header .toggle {
      position: relative;
      display: flex;
      align-items: center;
      width: 0.5rem;
      height: 0.5rem;
      cursor: pointer; }
      .bb-accordion.invert .header .toggle:before, .bb-accordion.invert .header .toggle:after {
        width: 100%;
        height: 0;
        content: '';
        border: 0.0625rem solid #FFFFFF; }
      .bb-accordion.invert .header .toggle:after {
        position: absolute;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
    .bb-accordion.invert .header .chevron {
      z-index: 5;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #FFFFFF;
      border-left: 0.125rem solid #FFFFFF; }
  .bb-accordion.invert .active .chevron {
    z-index: 5;
    display: block;
    margin-top: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 0;
    border-right: 0;
    content: ' ';
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    pointer-events: none; }
  .bb-accordion.invert .active .toggle {
    position: relative;
    display: flex;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer; }
    .bb-accordion.invert .active .toggle:before, .bb-accordion.invert .active .toggle:after {
      width: 100%;
      height: 0;
      content: '';
      -webkit-transform: rotate(0);
              transform: rotate(0);
      border: 0.0625rem solid #FFFFFF; }
  .bb-accordion.primary .header {
    color: #0A344D; }
    .bb-accordion.primary .header .toggle {
      position: relative;
      display: flex;
      align-items: center;
      width: 0.5rem;
      height: 0.5rem;
      cursor: pointer; }
      .bb-accordion.primary .header .toggle:before, .bb-accordion.primary .header .toggle:after {
        width: 100%;
        height: 0;
        content: '';
        border: 0.0625rem solid #0A344D; }
      .bb-accordion.primary .header .toggle:after {
        position: absolute;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
    .bb-accordion.primary .header .chevron {
      z-index: 5;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #0A344D;
      border-left: 0.125rem solid #0A344D; }
  .bb-accordion.primary .active .chevron {
    z-index: 5;
    display: block;
    margin-top: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 0;
    border-right: 0;
    content: ' ';
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    pointer-events: none; }
  .bb-accordion.primary .active .toggle {
    position: relative;
    display: flex;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer; }
    .bb-accordion.primary .active .toggle:before, .bb-accordion.primary .active .toggle:after {
      width: 100%;
      height: 0;
      content: '';
      -webkit-transform: rotate(0);
              transform: rotate(0);
      border: 0.0625rem solid #0A344D; }
  .bb-accordion.secondary .header {
    color: #FA7268; }
    .bb-accordion.secondary .header .toggle {
      position: relative;
      display: flex;
      align-items: center;
      width: 0.5rem;
      height: 0.5rem;
      cursor: pointer; }
      .bb-accordion.secondary .header .toggle:before, .bb-accordion.secondary .header .toggle:after {
        width: 100%;
        height: 0;
        content: '';
        border: 0.0625rem solid #FA7268; }
      .bb-accordion.secondary .header .toggle:after {
        position: absolute;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
    .bb-accordion.secondary .header .chevron {
      z-index: 5;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #FA7268;
      border-left: 0.125rem solid #FA7268; }
  .bb-accordion.secondary .active .chevron {
    z-index: 5;
    display: block;
    margin-top: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 0;
    border-right: 0;
    content: ' ';
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    pointer-events: none; }
  .bb-accordion.secondary .active .toggle {
    position: relative;
    display: flex;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer; }
    .bb-accordion.secondary .active .toggle:before, .bb-accordion.secondary .active .toggle:after {
      width: 100%;
      height: 0;
      content: '';
      -webkit-transform: rotate(0);
              transform: rotate(0);
      border: 0.0625rem solid #FA7268; }
  .bb-accordion.positive .header {
    color: #50BF63; }
    .bb-accordion.positive .header .toggle {
      position: relative;
      display: flex;
      align-items: center;
      width: 0.5rem;
      height: 0.5rem;
      cursor: pointer; }
      .bb-accordion.positive .header .toggle:before, .bb-accordion.positive .header .toggle:after {
        width: 100%;
        height: 0;
        content: '';
        border: 0.0625rem solid #50BF63; }
      .bb-accordion.positive .header .toggle:after {
        position: absolute;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
    .bb-accordion.positive .header .chevron {
      z-index: 5;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #50BF63;
      border-left: 0.125rem solid #50BF63; }
  .bb-accordion.positive .active .chevron {
    z-index: 5;
    display: block;
    margin-top: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 0;
    border-right: 0;
    content: ' ';
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    pointer-events: none; }
  .bb-accordion.positive .active .toggle {
    position: relative;
    display: flex;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer; }
    .bb-accordion.positive .active .toggle:before, .bb-accordion.positive .active .toggle:after {
      width: 100%;
      height: 0;
      content: '';
      -webkit-transform: rotate(0);
              transform: rotate(0);
      border: 0.0625rem solid #50BF63; }
  .bb-accordion.negative .header {
    color: #DC4C41; }
    .bb-accordion.negative .header .toggle {
      position: relative;
      display: flex;
      align-items: center;
      width: 0.5rem;
      height: 0.5rem;
      cursor: pointer; }
      .bb-accordion.negative .header .toggle:before, .bb-accordion.negative .header .toggle:after {
        width: 100%;
        height: 0;
        content: '';
        border: 0.0625rem solid #DC4C41; }
      .bb-accordion.negative .header .toggle:after {
        position: absolute;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
    .bb-accordion.negative .header .chevron {
      z-index: 5;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #DC4C41;
      border-left: 0.125rem solid #DC4C41; }
  .bb-accordion.negative .active .chevron {
    z-index: 5;
    display: block;
    margin-top: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 0;
    border-right: 0;
    content: ' ';
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    pointer-events: none; }
  .bb-accordion.negative .active .toggle {
    position: relative;
    display: flex;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer; }
    .bb-accordion.negative .active .toggle:before, .bb-accordion.negative .active .toggle:after {
      width: 100%;
      height: 0;
      content: '';
      -webkit-transform: rotate(0);
              transform: rotate(0);
      border: 0.0625rem solid #DC4C41; }
  .bb-accordion .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    background: transparent;
    font-weight: 700;
    font-size: 1.5rem;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    cursor: pointer;
    border-top: 0.125rem solid #E0E0E0; }
    .bb-accordion .header span,
    .bb-accordion .header :before,
    .bb-accordion .header :after {
      -webkit-transition: all ease-in-out 0.25s;
      transition: all ease-in-out 0.25s; }
    .bb-accordion .header .toggle {
      margin: 0 0.5rem;
      min-width: 1rem;
      position: relative;
      display: flex;
      align-items: center;
      width: 0.5rem;
      height: 0.5rem;
      cursor: pointer; }
      .bb-accordion .header .toggle:before, .bb-accordion .header .toggle:after {
        width: 100%;
        height: 0;
        content: '';
        border: 0.0625rem solid #A0A0A0; }
      .bb-accordion .header .toggle:after {
        position: absolute;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
    .bb-accordion .header .chevron {
      margin: 0 1rem;
      z-index: 5;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #A0A0A0;
      border-left: 0.125rem solid #A0A0A0; }
  .bb-accordion .content {
    display: none;
    margin-top: 0;
    padding-bottom: 1rem; }
    .bb-accordion .content.active {
      display: block; }
    .bb-accordion .content .header {
      justify-content: flex-start;
      padding: 0.5rem 0;
      font-weight: 700;
      font-size: 1.25rem;
      font-family: "Inter", "Helvetica", "Arial", sans-serif; }
      .bb-accordion .content .header:first-child {
        border-top: 0.125rem solid #E0E0E0; }
    .bb-accordion .content .content {
      padding: 0 0 1rem 0; }
  .bb-accordion .header.active .chevron {
    z-index: 5;
    display: block;
    margin-top: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 0;
    border-right: 0;
    content: ' ';
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    pointer-events: none; }
  .bb-accordion .header.active .toggle {
    position: relative;
    display: flex;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer; }
    .bb-accordion .header.active .toggle:before, .bb-accordion .header.active .toggle:after {
      width: 100%;
      height: 0;
      content: '';
      -webkit-transform: rotate(0);
              transform: rotate(0);
      border: 0.0625rem solid #A0A0A0; }

.bb-alert {
  position: relative;
  padding: 1rem 1rem;
  border: 0.125rem solid black;
  background: #030303;
  color: #FFFFFF;
  border-color: black;
  border-radius: 0.2rem; }
  .bb-alert h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-alert h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-alert h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-alert h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-alert h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-alert p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
    color: #FFFFFF; }
  .bb-alert a:not(.bb-btn):not(.link) {
    color: #FFFFFF; }
  .bb-alert.invert {
    background: #FFFFFF;
    color: #030303;
    border-color: #e6e6e6; }
    .bb-alert.invert h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.invert h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.invert h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.invert h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.invert h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.invert p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #030303; }
    .bb-alert.invert a:not(.bb-btn):not(.link) {
      color: #030303; }
  .bb-alert.primary {
    background: #0A344D;
    color: #FFFFFF;
    border-color: #041620; }
    .bb-alert.primary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.primary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.primary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.primary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.primary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.primary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .bb-alert.primary a:not(.bb-btn):not(.link) {
      color: #FFFFFF; }
  .bb-alert.secondary {
    background: #FA7268;
    color: #FFFFFF;
    border-color: #f84437; }
    .bb-alert.secondary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.secondary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.secondary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.secondary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.secondary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.secondary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .bb-alert.secondary a:not(.bb-btn):not(.link) {
      color: #FFFFFF; }
  .bb-alert.positive {
    background: #50BF63;
    color: #FFFFFF;
    border-color: #3ba14c; }
    .bb-alert.positive h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.positive h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.positive h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.positive h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.positive h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.positive p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .bb-alert.positive a:not(.bb-btn):not(.link) {
      color: #FFFFFF; }
  .bb-alert.negative {
    background: #DC4C41;
    color: #FFFFFF;
    border-color: #c63024; }
    .bb-alert.negative h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.negative h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.negative h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.negative h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.negative h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-alert.negative p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .bb-alert.negative a:not(.bb-btn):not(.link) {
      color: #FFFFFF; }
  .bb-alert .close {
    position: absolute;
    top: 1rem;
    right: 1rem; }

.bb-animation.up {
  -webkit-transform: translateY(100vh);
          transform: translateY(100vh);
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.bb-animation.down {
  -webkit-transform: translateY(-100vh);
          transform: translateY(-100vh);
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.bb-animation.right {
  -webkit-transform: translateX(100vw);
          transform: translateX(100vw);
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.bb-animation.left {
  -webkit-transform: translateX(-100vw);
          transform: translateX(-100vw);
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.bb-animation.up.slide, .bb-animation.down.slide, .bb-animation.right.slide, .bb-animation.left.slide {
  -webkit-transform: translate(0);
          transform: translate(0); }

.bb-animation.spinner {
  position: relative;
  display: block;
  width: 4rem;
  height: 4rem;
  border-width: 0.5rem;
  border-style: solid;
  color: transparent !important;
  cursor: default;
  -webkit-animation: loader-spin 1s linear;
          animation: loader-spin 1s linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  pointer-events: none;
  border-radius: 50%;
  border-color: #030303 transparent transparent; }
  .bb-animation.spinner.invert {
    border-color: #FFFFFF transparent transparent; }
  .bb-animation.spinner.primary {
    border-color: #0A344D transparent transparent; }
  .bb-animation.spinner.secondary {
    border-color: #FA7268 transparent transparent; }
  .bb-animation.spinner.positive {
    border-color: #50BF63 transparent transparent; }
  .bb-animation.spinner.negative {
    border-color: #DC4C41 transparent transparent; }

.bb-animation.pulse {
  position: relative;
  width: 2rem;
  height: 2rem;
  color: transparent !important;
  cursor: default;
  -webkit-animation: loader-pulse 1.75s infinite ease-in-out;
          animation: loader-pulse 1.75s infinite ease-in-out;
  pointer-events: none;
  border-radius: 50%;
  background: #030303; }
  .bb-animation.pulse.invert {
    background: #FFFFFF; }
  .bb-animation.pulse.primary {
    background: #0A344D; }
  .bb-animation.pulse.secondary {
    background: #FA7268; }
  .bb-animation.pulse.positive {
    background: #50BF63; }
  .bb-animation.pulse.negative {
    background: #DC4C41; }

.bb-animation.bar {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 0.5rem;
  border-radius: 50%;
  background: #E0E0E0;
  color: transparent !important;
  cursor: default;
  pointer-events: none; }
  .bb-animation.bar span {
    background: #030303; }
  .bb-animation.bar.invert span {
    background: #FFFFFF; }
  .bb-animation.bar.primary span {
    background: #0A344D; }
  .bb-animation.bar.secondary span {
    background: #FA7268; }
  .bb-animation.bar.positive span {
    background: #50BF63; }
  .bb-animation.bar.negative span {
    background: #DC4C41; }
  .bb-animation.bar span {
    display: flex;
    width: 0;
    -webkit-animation: animation-bar 5s forwards ease-in-out;
            animation: animation-bar 5s forwards ease-in-out; }

.bb-animation.audio {
  position: absolute;
  display: inline-flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 4rem;
  height: 4rem;
  list-style: none;
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1); }
  .bb-animation.audio li {
    width: 0.25rem;
    border-radius: 0.2rem;
    background-color: #FFFFFF; }
  .bb-animation.audio li:nth-child(1) {
    -webkit-animation: animation-audio-1 1.5s ease infinite 0s;
            animation: animation-audio-1 1.5s ease infinite 0s; }
  .bb-animation.audio li:nth-child(2) {
    -webkit-animation: animation-audio-2 1.5s ease-in-out infinite 0.2s;
            animation: animation-audio-2 1.5s ease-in-out infinite 0.2s; }
  .bb-animation.audio li:nth-child(3) {
    -webkit-animation: animation-audio-1 1.5s ease infinite 0.1s;
            animation: animation-audio-1 1.5s ease infinite 0.1s; }
  .bb-animation.audio li:nth-child(4) {
    -webkit-animation: animation-audio-2 1.5s ease-in infinite 0.3s;
            animation: animation-audio-2 1.5s ease-in infinite 0.3s; }

.bb-animation.flash {
  -webkit-animation: animation-flash 1.75s infinite ease-in-out !important;
          animation: animation-flash 1.75s infinite ease-in-out !important; }

@-webkit-keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loader-pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes loader-pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes animation-bar {
  100% {
    width: 100%; } }

@keyframes animation-bar {
  100% {
    width: 100%; } }

@-webkit-keyframes animation-audio-1 {
  0% {
    height: 0; }
  50% {
    height: 4rem; }
  100% {
    height: 0; } }

@keyframes animation-audio-1 {
  0% {
    height: 0; }
  50% {
    height: 4rem; }
  100% {
    height: 0; } }

@-webkit-keyframes animation-audio-2 {
  0% {
    height: 0; }
  50% {
    height: 3rem; }
  100% {
    height: 0; } }

@keyframes animation-audio-2 {
  0% {
    height: 0; }
  50% {
    height: 3rem; }
  100% {
    height: 0; } }

@-webkit-keyframes animation-flash {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes animation-flash {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.bb-box {
  position: relative;
  padding: 1.5rem 1rem;
  border: 0.125rem solid black;
  border-radius: 0.2rem;
  background: #030303;
  color: #FFFFFF;
  border: 0.125rem solid black; }
  .bb-box h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
    color: #FFFFFF; }
  .bb-box a:not(.bb-btn):not(.link):not(.item) {
    color: #FFFFFF; }
  .bb-box.center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
  .bb-box.invert {
    background: #FFFFFF;
    color: #030303;
    border: 0.125rem solid #e6e6e6; }
    .bb-box.invert h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.invert h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.invert h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.invert h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.invert h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.invert p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #030303; }
    .bb-box.invert a:not(.bb-btn):not(.link):not(.item) {
      color: #030303; }
  .bb-box.primary {
    background: #0A344D;
    color: #FFFFFF;
    border: 0.125rem solid #041620; }
    .bb-box.primary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.primary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.primary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.primary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.primary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.primary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .bb-box.primary a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .bb-box.secondary {
    background: #FA7268;
    color: #FFFFFF;
    border: 0.125rem solid #f84437; }
    .bb-box.secondary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.secondary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.secondary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.secondary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.secondary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.secondary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .bb-box.secondary a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .bb-box.positive {
    background: #50BF63;
    color: #FFFFFF;
    border: 0.125rem solid #3ba14c; }
    .bb-box.positive h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.positive h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.positive h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.positive h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.positive h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.positive p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .bb-box.positive a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .bb-box.negative {
    background: #DC4C41;
    color: #FFFFFF;
    border: 0.125rem solid #c63024; }
    .bb-box.negative h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.negative h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.negative h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.negative h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.negative h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.negative p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
    .bb-box.negative a:not(.bb-btn):not(.link):not(.item) {
      color: #FFFFFF; }
  .bb-box.outline {
    background: transparent;
    color: #030303;
    border: 0.125rem solid black; }
    .bb-box.outline h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.outline h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.outline h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.outline h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.outline h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-box.outline p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #030303; }
    .bb-box.outline a:not(.bb-btn):not(.link):not(.item) {
      color: #030303; }
    .bb-box.outline.invert {
      background: transparent;
      color: #FFFFFF;
      border: 0.125rem solid #e6e6e6; }
      .bb-box.outline.invert h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.invert h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.invert h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.invert h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.invert h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.invert p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
        color: #FFFFFF; }
      .bb-box.outline.invert a:not(.bb-btn):not(.link):not(.item) {
        color: #FFFFFF; }
    .bb-box.outline.primary {
      background: transparent;
      color: #0A344D;
      border: 0.125rem solid #041620; }
      .bb-box.outline.primary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.primary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.primary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.primary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.primary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.primary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
        color: #0A344D; }
      .bb-box.outline.primary a:not(.bb-btn):not(.link):not(.item) {
        color: #0A344D; }
    .bb-box.outline.secondary {
      background: transparent;
      color: #FA7268;
      border: 0.125rem solid #f84437; }
      .bb-box.outline.secondary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.secondary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.secondary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.secondary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.secondary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.secondary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
        color: #FA7268; }
      .bb-box.outline.secondary a:not(.bb-btn):not(.link):not(.item) {
        color: #FA7268; }
    .bb-box.outline.positive {
      background: transparent;
      color: #50BF63;
      border: 0.125rem solid #3ba14c; }
      .bb-box.outline.positive h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.positive h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.positive h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.positive h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.positive h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.positive p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
        color: #50BF63; }
      .bb-box.outline.positive a:not(.bb-btn):not(.link):not(.item) {
        color: #50BF63; }
    .bb-box.outline.negative {
      background: transparent;
      color: #DC4C41;
      border: 0.125rem solid #c63024; }
      .bb-box.outline.negative h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.negative h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.negative h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.negative h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.negative h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
      .bb-box.outline.negative p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
        color: #DC4C41; }
      .bb-box.outline.negative a:not(.bb-btn):not(.link):not(.item) {
        color: #DC4C41; }

.bb-breadcrumb {
  margin-bottom: 1rem;
  padding: 0;
  text-transform: capitalize; }
  .bb-breadcrumb ul {
    padding: 0;
    background: transparent;
    list-style: none; }
    .bb-breadcrumb ul::after {
      display: block;
      clear: both;
      content: ''; }
    .bb-breadcrumb ul .breadcrumb-item {
      float: left; }
      .bb-breadcrumb ul .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 1rem;
        padding-left: 1rem;
        color: #030303;
        content: "|"; }
      .bb-breadcrumb ul .breadcrumb-item.active {
        color: #FA7268; }
      .bb-breadcrumb ul .breadcrumb-item a {
        color: #030303; }
  .bb-breadcrumb.xs .breadcrumb-item {
    font-size: 0.5rem; }
  .bb-breadcrumb.sm .breadcrumb-item {
    font-size: 0.75rem; }
  .bb-breadcrumb.lg .breadcrumb-item {
    font-size: 1.25rem; }
  .bb-breadcrumb.xl .breadcrumb-item {
    font-size: 1.5rem; }
  .bb-breadcrumb.scroll {
    overflow: hidden;
    overflow-x: scroll;
    width: 100%;
    white-space: nowrap; }
    .bb-breadcrumb.scroll ul {
      margin-bottom: 0;
      padding-left: 0; }
    .bb-breadcrumb.scroll .breadcrumb-item {
      display: inline;
      float: none; }

.bb-btn, a.bb-btn {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  box-shadow: none;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
  font-family: "Inter", "Helvetica", "Arial", sans-serif;
  line-height: 1.3;
  cursor: pointer;
  -webkit-appearance: none;
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.2rem;
  border: 0.125rem solid black;
  color: #FFFFFF;
  background: #030303;
  border-color: black;
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bb-btn:hover, a.bb-btn:hover {
    box-shadow: none; }
  .bb-btn:hover, .bb-btn:focus, a.bb-btn:hover, a.bb-btn:focus {
    background: black;
    box-shadow: none;
    border-color: black; }
  .bb-btn:active, .bb-btn.active, a.bb-btn:active, a.bb-btn.active {
    background: black;
    box-shadow: none;
    border-color: black; }
  .bb-btn::-moz-focus-inner, a.bb-btn::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .bb-btn.xs, a.bb-btn.xs {
    font-size: 0.5rem;
    padding: 0rem 0rem; }
  .bb-btn.sm, a.bb-btn.sm {
    font-size: 0.75rem;
    padding: 0.25rem 0.25rem; }
  .bb-btn.lg, a.bb-btn.lg {
    font-size: 1.25rem;
    padding: 0.75rem 0.75rem; }
  .bb-btn.xl, a.bb-btn.xl {
    font-size: 1.5rem;
    padding: 1rem 1rem; }
  .bb-btn.full-x, a.bb-btn.full-x {
    display: block;
    width: 100%; }
  .bb-btn.no-border-radius, a.bb-btn.no-border-radius {
    border-radius: 0rem; }
  .bb-btn.disabled, .bb-btn:disabled, a.bb-btn.disabled, a.bb-btn:disabled {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none; }
  .bb-btn.invert, a.bb-btn.invert {
    color: #030303;
    background: #FFFFFF;
    border-color: #e6e6e6; }
    .bb-btn.invert:hover, .bb-btn.invert:focus, a.bb-btn.invert:hover, a.bb-btn.invert:focus {
      background: #e6e6e6;
      box-shadow: none;
      border-color: #e6e6e6; }
    .bb-btn.invert:active, .bb-btn.invert.active, a.bb-btn.invert:active, a.bb-btn.invert.active {
      background: #e0e0e0;
      box-shadow: none;
      border-color: #e0e0e0; }
  .bb-btn.primary, a.bb-btn.primary {
    color: #FFFFFF;
    background: #0A344D;
    border-color: #041620; }
    .bb-btn.primary:hover, .bb-btn.primary:focus, a.bb-btn.primary:hover, a.bb-btn.primary:focus {
      background: #041620;
      box-shadow: none;
      border-color: #041620; }
    .bb-btn.primary:active, .bb-btn.primary.active, a.bb-btn.primary:active, a.bb-btn.primary.active {
      background: #030f17;
      box-shadow: none;
      border-color: #030f17; }
  .bb-btn.secondary, a.bb-btn.secondary {
    color: #FFFFFF;
    background: #FA7268;
    border-color: #f84437; }
    .bb-btn.secondary:hover, .bb-btn.secondary:focus, a.bb-btn.secondary:hover, a.bb-btn.secondary:focus {
      background: #f84437;
      box-shadow: none;
      border-color: #f84437; }
    .bb-btn.secondary:active, .bb-btn.secondary.active, a.bb-btn.secondary:active, a.bb-btn.secondary.active {
      background: #f83b2d;
      box-shadow: none;
      border-color: #f83b2d; }
  .bb-btn.positive, a.bb-btn.positive {
    color: #FFFFFF;
    background: #50BF63;
    border-color: #3ba14c; }
    .bb-btn.positive:hover, .bb-btn.positive:focus, a.bb-btn.positive:hover, a.bb-btn.positive:focus {
      background: #3ba14c;
      box-shadow: none;
      border-color: #3ba14c; }
    .bb-btn.positive:active, .bb-btn.positive.active, a.bb-btn.positive:active, a.bb-btn.positive.active {
      background: #389a49;
      box-shadow: none;
      border-color: #389a49; }
  .bb-btn.negative, a.bb-btn.negative {
    color: #FFFFFF;
    background: #DC4C41;
    border-color: #c63024; }
    .bb-btn.negative:hover, .bb-btn.negative:focus, a.bb-btn.negative:hover, a.bb-btn.negative:focus {
      background: #c63024;
      box-shadow: none;
      border-color: #c63024; }
    .bb-btn.negative:active, .bb-btn.negative.active, a.bb-btn.negative:active, a.bb-btn.negative.active {
      background: #bd2e23;
      box-shadow: none;
      border-color: #bd2e23; }
  .bb-btn.outline, a.bb-btn.outline {
    color: #030303;
    background: transparent;
    background-image: none;
    border-color: black; }
    .bb-btn.outline:hover, .bb-btn.outline:focus, a.bb-btn.outline:hover, a.bb-btn.outline:focus {
      color: black;
      border-color: black; }
    .bb-btn.outline:active, .bb-btn.outline.active, a.bb-btn.outline:active, a.bb-btn.outline.active {
      color: black;
      border-color: black; }
    .bb-btn.outline.invert, a.bb-btn.outline.invert {
      color: #FFFFFF;
      background: transparent;
      background-image: none;
      border-color: #e6e6e6; }
      .bb-btn.outline.invert:hover, .bb-btn.outline.invert:focus, a.bb-btn.outline.invert:hover, a.bb-btn.outline.invert:focus {
        color: #b3b3b3;
        border-color: #b3b3b3; }
      .bb-btn.outline.invert:active, .bb-btn.outline.invert.active, a.bb-btn.outline.invert:active, a.bb-btn.outline.invert.active {
        color: #b3b3b3;
        border-color: #b3b3b3; }
    .bb-btn.outline.primary, a.bb-btn.outline.primary {
      color: #0A344D;
      background: transparent;
      background-image: none;
      border-color: #041620; }
      .bb-btn.outline.primary:hover, .bb-btn.outline.primary:focus, a.bb-btn.outline.primary:hover, a.bb-btn.outline.primary:focus {
        color: black;
        border-color: black; }
      .bb-btn.outline.primary:active, .bb-btn.outline.primary.active, a.bb-btn.outline.primary:active, a.bb-btn.outline.primary.active {
        color: black;
        border-color: black; }
    .bb-btn.outline.secondary, a.bb-btn.outline.secondary {
      color: #FA7268;
      background: transparent;
      background-image: none;
      border-color: #f84437; }
      .bb-btn.outline.secondary:hover, .bb-btn.outline.secondary:focus, a.bb-btn.outline.secondary:hover, a.bb-btn.outline.secondary:focus {
        color: #c31306;
        border-color: #c31306; }
      .bb-btn.outline.secondary:active, .bb-btn.outline.secondary.active, a.bb-btn.outline.secondary:active, a.bb-btn.outline.secondary.active {
        color: #c31306;
        border-color: #c31306; }
    .bb-btn.outline.positive, a.bb-btn.outline.positive {
      color: #50BF63;
      background: transparent;
      background-image: none;
      border-color: #3ba14c; }
      .bb-btn.outline.positive:hover, .bb-btn.outline.positive:focus, a.bb-btn.outline.positive:hover, a.bb-btn.outline.positive:focus {
        color: #205629;
        border-color: #205629; }
      .bb-btn.outline.positive:active, .bb-btn.outline.positive.active, a.bb-btn.outline.positive:active, a.bb-btn.outline.positive.active {
        color: #205629;
        border-color: #205629; }
    .bb-btn.outline.negative, a.bb-btn.outline.negative {
      color: #DC4C41;
      background: transparent;
      background-image: none;
      border-color: #c63024; }
      .bb-btn.outline.negative:hover, .bb-btn.outline.negative:focus, a.bb-btn.outline.negative:hover, a.bb-btn.outline.negative:focus {
        color: #6f1b15;
        border-color: #6f1b15; }
      .bb-btn.outline.negative:active, .bb-btn.outline.negative.active, a.bb-btn.outline.negative:active, a.bb-btn.outline.negative.active {
        color: #6f1b15;
        border-color: #6f1b15; }
  .bb-btn.inverted, a.bb-btn.inverted {
    color: #FFFFFF;
    background: #030303;
    border-color: black; }
    .bb-btn.inverted:hover, .bb-btn.inverted:focus, a.bb-btn.inverted:hover, a.bb-btn.inverted:focus {
      background: #FFFFFF;
      color: #030303; }
    .bb-btn.inverted:active, .bb-btn.inverted.active, a.bb-btn.inverted:active, a.bb-btn.inverted.active {
      background: #030303;
      color: #FFFFFF; }
    .bb-btn.inverted.invert, a.bb-btn.inverted.invert {
      color: #030303;
      background: #FFFFFF;
      border-color: #e6e6e6; }
      .bb-btn.inverted.invert:hover, .bb-btn.inverted.invert:focus, a.bb-btn.inverted.invert:hover, a.bb-btn.inverted.invert:focus {
        background: #030303;
        color: #FFFFFF; }
      .bb-btn.inverted.invert:active, .bb-btn.inverted.invert.active, a.bb-btn.inverted.invert:active, a.bb-btn.inverted.invert.active {
        background: #FFFFFF;
        color: #030303; }
    .bb-btn.inverted.primary, a.bb-btn.inverted.primary {
      color: #FFFFFF;
      background: #0A344D;
      border-color: #041620; }
      .bb-btn.inverted.primary:hover, .bb-btn.inverted.primary:focus, a.bb-btn.inverted.primary:hover, a.bb-btn.inverted.primary:focus {
        background: #FFFFFF;
        color: #0A344D; }
      .bb-btn.inverted.primary:active, .bb-btn.inverted.primary.active, a.bb-btn.inverted.primary:active, a.bb-btn.inverted.primary.active {
        background: #0A344D;
        color: #FFFFFF; }
    .bb-btn.inverted.secondary, a.bb-btn.inverted.secondary {
      color: #FFFFFF;
      background: #FA7268;
      border-color: #f84437; }
      .bb-btn.inverted.secondary:hover, .bb-btn.inverted.secondary:focus, a.bb-btn.inverted.secondary:hover, a.bb-btn.inverted.secondary:focus {
        background: #FFFFFF;
        color: #FA7268; }
      .bb-btn.inverted.secondary:active, .bb-btn.inverted.secondary.active, a.bb-btn.inverted.secondary:active, a.bb-btn.inverted.secondary.active {
        background: #FA7268;
        color: #FFFFFF; }
    .bb-btn.inverted.positive, a.bb-btn.inverted.positive {
      color: #FFFFFF;
      background: #50BF63;
      border-color: #3ba14c; }
      .bb-btn.inverted.positive:hover, .bb-btn.inverted.positive:focus, a.bb-btn.inverted.positive:hover, a.bb-btn.inverted.positive:focus {
        background: #FFFFFF;
        color: #50BF63; }
      .bb-btn.inverted.positive:active, .bb-btn.inverted.positive.active, a.bb-btn.inverted.positive:active, a.bb-btn.inverted.positive.active {
        background: #50BF63;
        color: #FFFFFF; }
    .bb-btn.inverted.negative, a.bb-btn.inverted.negative {
      color: #FFFFFF;
      background: #DC4C41;
      border-color: #c63024; }
      .bb-btn.inverted.negative:hover, .bb-btn.inverted.negative:focus, a.bb-btn.inverted.negative:hover, a.bb-btn.inverted.negative:focus {
        background: #FFFFFF;
        color: #DC4C41; }
      .bb-btn.inverted.negative:active, .bb-btn.inverted.negative.active, a.bb-btn.inverted.negative:active, a.bb-btn.inverted.negative.active {
        background: #DC4C41;
        color: #FFFFFF; }
  .bb-btn.loading, a.bb-btn.loading {
    position: relative;
    color: transparent !important;
    cursor: default;
    pointer-events: none;
    background: black;
    border-color: black; }
    .bb-btn.loading:after, a.bb-btn.loading:after {
      border-color: #FFFFFF transparent transparent; }
    .bb-btn.loading.outline, a.bb-btn.loading.outline {
      color: #FFFFFF;
      background: transparent;
      border-color: black; }
      .bb-btn.loading.outline:after, a.bb-btn.loading.outline:after {
        border-color: black transparent transparent; }
    .bb-btn.loading:after, a.bb-btn.loading:after {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -0.375rem 0 0 -0.375rem;
      width: 0.75rem;
      height: 0.75rem;
      border-width: 0.1rem;
      border-style: solid;
      content: '';
      -webkit-animation: button-spin 0.75s linear;
              animation: button-spin 0.75s linear;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      border-radius: 50%; }
    .bb-btn.loading.invert, a.bb-btn.loading.invert {
      background: #e0e0e0;
      border-color: #e0e0e0; }
      .bb-btn.loading.invert:after, a.bb-btn.loading.invert:after {
        border-color: #030303 transparent transparent; }
      .bb-btn.loading.invert.outline, a.bb-btn.loading.invert.outline {
        color: #030303;
        background: transparent;
        border-color: #adadad; }
        .bb-btn.loading.invert.outline:after, a.bb-btn.loading.invert.outline:after {
          border-color: #e0e0e0 transparent transparent; }
    .bb-btn.loading.primary, a.bb-btn.loading.primary {
      background: #030f17;
      border-color: #030f17; }
      .bb-btn.loading.primary:after, a.bb-btn.loading.primary:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.primary.outline, a.bb-btn.loading.primary.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: black; }
        .bb-btn.loading.primary.outline:after, a.bb-btn.loading.primary.outline:after {
          border-color: #030f17 transparent transparent; }
    .bb-btn.loading.secondary, a.bb-btn.loading.secondary {
      background: #f83b2d;
      border-color: #f83b2d; }
      .bb-btn.loading.secondary:after, a.bb-btn.loading.secondary:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.secondary.outline, a.bb-btn.loading.secondary.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: #b91206; }
        .bb-btn.loading.secondary.outline:after, a.bb-btn.loading.secondary.outline:after {
          border-color: #f83b2d transparent transparent; }
    .bb-btn.loading.positive, a.bb-btn.loading.positive {
      background: #389a49;
      border-color: #389a49; }
      .bb-btn.loading.positive:after, a.bb-btn.loading.positive:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.positive.outline, a.bb-btn.loading.positive.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: #1d4f25; }
        .bb-btn.loading.positive.outline:after, a.bb-btn.loading.positive.outline:after {
          border-color: #389a49 transparent transparent; }
    .bb-btn.loading.negative, a.bb-btn.loading.negative {
      background: #bd2e23;
      border-color: #bd2e23; }
      .bb-btn.loading.negative:after, a.bb-btn.loading.negative:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.negative.outline, a.bb-btn.loading.negative.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: #671913; }
        .bb-btn.loading.negative.outline:after, a.bb-btn.loading.negative.outline:after {
          border-color: #bd2e23 transparent transparent; }
  .bb-btn.icon, a.bb-btn.icon {
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }
    .bb-btn.icon span, a.bb-btn.icon span {
      display: inline-flex;
      margin: 0;
      width: auto;
      height: auto; }

.link {
  border: 0;
  text-decoration: none;
  border-radius: 0rem;
  color: #030303;
  background: transparent; }
  .link:hover, .link:focus, .link:active {
    color: black;
    background: transparent;
    text-decoration: underline; }
  .link.invert {
    color: #FFFFFF;
    background: transparent; }
    .link.invert:hover, .link.invert:focus, .link.invert:active {
      color: #e6e6e6;
      background: transparent;
      text-decoration: underline; }
  .link.primary {
    color: #0A344D;
    background: transparent; }
    .link.primary:hover, .link.primary:focus, .link.primary:active {
      color: #041620;
      background: transparent;
      text-decoration: underline; }
  .link.secondary {
    color: #FA7268;
    background: transparent; }
    .link.secondary:hover, .link.secondary:focus, .link.secondary:active {
      color: #f84437;
      background: transparent;
      text-decoration: underline; }
  .link.positive {
    color: #50BF63;
    background: transparent; }
    .link.positive:hover, .link.positive:focus, .link.positive:active {
      color: #3ba14c;
      background: transparent;
      text-decoration: underline; }
  .link.negative {
    color: #DC4C41;
    background: transparent; }
    .link.negative:hover, .link.negative:focus, .link.negative:active {
      color: #c63024;
      background: transparent;
      text-decoration: underline; }

.close {
  position: relative;
  display: inline-block;
  outline: none;
  border: 0;
  vertical-align: top;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background: black; }
  .close:before, .close:after {
    background: #FFFFFF; }
  .close:hover, .close:focus, .close:active {
    background: black; }
  .close.invert {
    background: #e6e6e6; }
    .close.invert:before, .close.invert:after {
      background: #030303; }
    .close.invert:hover, .close.invert:focus, .close.invert:active {
      background: #cccccc; }
  .close.primary {
    background: #041620; }
    .close.primary:before, .close.primary:after {
      background: #FFFFFF; }
    .close.primary:hover, .close.primary:focus, .close.primary:active {
      background: black; }
  .close.secondary {
    background: #f84437; }
    .close.secondary:before, .close.secondary:after {
      background: #FFFFFF; }
    .close.secondary:hover, .close.secondary:focus, .close.secondary:active {
      background: #f41808; }
  .close.positive {
    background: #3ba14c; }
    .close.positive:before, .close.positive:after {
      background: #FFFFFF; }
    .close.positive:hover, .close.positive:focus, .close.positive:active {
      background: #2d7c3b; }
  .close.negative {
    background: #c63024; }
    .close.negative:before, .close.negative:after {
      background: #FFFFFF; }
    .close.negative:hover, .close.negative:focus, .close.negative:active {
      background: #9b251c; }
  .close.negative {
    background: #c63024; }
    .close.negative:before, .close.negative:after {
      background: #FFFFFF; }
    .close.negative:hover, .close.negative:focus, .close.negative:active {
      background: #9b251c; }
  .close:before, .close:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .close:before {
    width: 50%;
    height: 0.125rem; }
  .close:after {
    width: 0.125rem;
    height: 50%; }
  .close.xs {
    width: 0.85rem;
    height: 0.85rem; }
  .close.sm {
    width: 1.05rem;
    height: 1.05rem; }
  .close.lg {
    width: 1.45rem;
    height: 1.45rem; }
  .close.xl {
    width: 1.65rem;
    height: 1.65rem; }

@-webkit-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.bb-btn, a.bb-btn {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  box-shadow: none;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
  font-family: "Inter", "Helvetica", "Arial", sans-serif;
  line-height: 1.3;
  cursor: pointer;
  -webkit-appearance: none;
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.2rem;
  border: 0.125rem solid black;
  color: #FFFFFF;
  background: #030303;
  border-color: black;
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bb-btn:hover, a.bb-btn:hover {
    box-shadow: none; }
  .bb-btn:hover, .bb-btn:focus, a.bb-btn:hover, a.bb-btn:focus {
    background: black;
    box-shadow: none;
    border-color: black; }
  .bb-btn:active, .bb-btn.active, a.bb-btn:active, a.bb-btn.active {
    background: black;
    box-shadow: none;
    border-color: black; }
  .bb-btn::-moz-focus-inner, a.bb-btn::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .bb-btn.xs, a.bb-btn.xs {
    font-size: 0.5rem;
    padding: 0rem 0rem; }
  .bb-btn.sm, a.bb-btn.sm {
    font-size: 0.75rem;
    padding: 0.25rem 0.25rem; }
  .bb-btn.lg, a.bb-btn.lg {
    font-size: 1.25rem;
    padding: 0.75rem 0.75rem; }
  .bb-btn.xl, a.bb-btn.xl {
    font-size: 1.5rem;
    padding: 1rem 1rem; }
  .bb-btn.full-x, a.bb-btn.full-x {
    display: block;
    width: 100%; }
  .bb-btn.no-border-radius, a.bb-btn.no-border-radius {
    border-radius: 0rem; }
  .bb-btn.disabled, .bb-btn:disabled, a.bb-btn.disabled, a.bb-btn:disabled {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none; }
  .bb-btn.invert, a.bb-btn.invert {
    color: #030303;
    background: #FFFFFF;
    border-color: #e6e6e6; }
    .bb-btn.invert:hover, .bb-btn.invert:focus, a.bb-btn.invert:hover, a.bb-btn.invert:focus {
      background: #e6e6e6;
      box-shadow: none;
      border-color: #e6e6e6; }
    .bb-btn.invert:active, .bb-btn.invert.active, a.bb-btn.invert:active, a.bb-btn.invert.active {
      background: #e0e0e0;
      box-shadow: none;
      border-color: #e0e0e0; }
  .bb-btn.primary, a.bb-btn.primary {
    color: #FFFFFF;
    background: #0A344D;
    border-color: #041620; }
    .bb-btn.primary:hover, .bb-btn.primary:focus, a.bb-btn.primary:hover, a.bb-btn.primary:focus {
      background: #041620;
      box-shadow: none;
      border-color: #041620; }
    .bb-btn.primary:active, .bb-btn.primary.active, a.bb-btn.primary:active, a.bb-btn.primary.active {
      background: #030f17;
      box-shadow: none;
      border-color: #030f17; }
  .bb-btn.secondary, a.bb-btn.secondary {
    color: #FFFFFF;
    background: #FA7268;
    border-color: #f84437; }
    .bb-btn.secondary:hover, .bb-btn.secondary:focus, a.bb-btn.secondary:hover, a.bb-btn.secondary:focus {
      background: #f84437;
      box-shadow: none;
      border-color: #f84437; }
    .bb-btn.secondary:active, .bb-btn.secondary.active, a.bb-btn.secondary:active, a.bb-btn.secondary.active {
      background: #f83b2d;
      box-shadow: none;
      border-color: #f83b2d; }
  .bb-btn.positive, a.bb-btn.positive {
    color: #FFFFFF;
    background: #50BF63;
    border-color: #3ba14c; }
    .bb-btn.positive:hover, .bb-btn.positive:focus, a.bb-btn.positive:hover, a.bb-btn.positive:focus {
      background: #3ba14c;
      box-shadow: none;
      border-color: #3ba14c; }
    .bb-btn.positive:active, .bb-btn.positive.active, a.bb-btn.positive:active, a.bb-btn.positive.active {
      background: #389a49;
      box-shadow: none;
      border-color: #389a49; }
  .bb-btn.negative, a.bb-btn.negative {
    color: #FFFFFF;
    background: #DC4C41;
    border-color: #c63024; }
    .bb-btn.negative:hover, .bb-btn.negative:focus, a.bb-btn.negative:hover, a.bb-btn.negative:focus {
      background: #c63024;
      box-shadow: none;
      border-color: #c63024; }
    .bb-btn.negative:active, .bb-btn.negative.active, a.bb-btn.negative:active, a.bb-btn.negative.active {
      background: #bd2e23;
      box-shadow: none;
      border-color: #bd2e23; }
  .bb-btn.outline, a.bb-btn.outline {
    color: #030303;
    background: transparent;
    background-image: none;
    border-color: black; }
    .bb-btn.outline:hover, .bb-btn.outline:focus, a.bb-btn.outline:hover, a.bb-btn.outline:focus {
      color: black;
      border-color: black; }
    .bb-btn.outline:active, .bb-btn.outline.active, a.bb-btn.outline:active, a.bb-btn.outline.active {
      color: black;
      border-color: black; }
    .bb-btn.outline.invert, a.bb-btn.outline.invert {
      color: #FFFFFF;
      background: transparent;
      background-image: none;
      border-color: #e6e6e6; }
      .bb-btn.outline.invert:hover, .bb-btn.outline.invert:focus, a.bb-btn.outline.invert:hover, a.bb-btn.outline.invert:focus {
        color: #b3b3b3;
        border-color: #b3b3b3; }
      .bb-btn.outline.invert:active, .bb-btn.outline.invert.active, a.bb-btn.outline.invert:active, a.bb-btn.outline.invert.active {
        color: #b3b3b3;
        border-color: #b3b3b3; }
    .bb-btn.outline.primary, a.bb-btn.outline.primary {
      color: #0A344D;
      background: transparent;
      background-image: none;
      border-color: #041620; }
      .bb-btn.outline.primary:hover, .bb-btn.outline.primary:focus, a.bb-btn.outline.primary:hover, a.bb-btn.outline.primary:focus {
        color: black;
        border-color: black; }
      .bb-btn.outline.primary:active, .bb-btn.outline.primary.active, a.bb-btn.outline.primary:active, a.bb-btn.outline.primary.active {
        color: black;
        border-color: black; }
    .bb-btn.outline.secondary, a.bb-btn.outline.secondary {
      color: #FA7268;
      background: transparent;
      background-image: none;
      border-color: #f84437; }
      .bb-btn.outline.secondary:hover, .bb-btn.outline.secondary:focus, a.bb-btn.outline.secondary:hover, a.bb-btn.outline.secondary:focus {
        color: #c31306;
        border-color: #c31306; }
      .bb-btn.outline.secondary:active, .bb-btn.outline.secondary.active, a.bb-btn.outline.secondary:active, a.bb-btn.outline.secondary.active {
        color: #c31306;
        border-color: #c31306; }
    .bb-btn.outline.positive, a.bb-btn.outline.positive {
      color: #50BF63;
      background: transparent;
      background-image: none;
      border-color: #3ba14c; }
      .bb-btn.outline.positive:hover, .bb-btn.outline.positive:focus, a.bb-btn.outline.positive:hover, a.bb-btn.outline.positive:focus {
        color: #205629;
        border-color: #205629; }
      .bb-btn.outline.positive:active, .bb-btn.outline.positive.active, a.bb-btn.outline.positive:active, a.bb-btn.outline.positive.active {
        color: #205629;
        border-color: #205629; }
    .bb-btn.outline.negative, a.bb-btn.outline.negative {
      color: #DC4C41;
      background: transparent;
      background-image: none;
      border-color: #c63024; }
      .bb-btn.outline.negative:hover, .bb-btn.outline.negative:focus, a.bb-btn.outline.negative:hover, a.bb-btn.outline.negative:focus {
        color: #6f1b15;
        border-color: #6f1b15; }
      .bb-btn.outline.negative:active, .bb-btn.outline.negative.active, a.bb-btn.outline.negative:active, a.bb-btn.outline.negative.active {
        color: #6f1b15;
        border-color: #6f1b15; }
  .bb-btn.inverted, a.bb-btn.inverted {
    color: #FFFFFF;
    background: #030303;
    border-color: black; }
    .bb-btn.inverted:hover, .bb-btn.inverted:focus, a.bb-btn.inverted:hover, a.bb-btn.inverted:focus {
      background: #FFFFFF;
      color: #030303; }
    .bb-btn.inverted:active, .bb-btn.inverted.active, a.bb-btn.inverted:active, a.bb-btn.inverted.active {
      background: #030303;
      color: #FFFFFF; }
    .bb-btn.inverted.invert, a.bb-btn.inverted.invert {
      color: #030303;
      background: #FFFFFF;
      border-color: #e6e6e6; }
      .bb-btn.inverted.invert:hover, .bb-btn.inverted.invert:focus, a.bb-btn.inverted.invert:hover, a.bb-btn.inverted.invert:focus {
        background: #030303;
        color: #FFFFFF; }
      .bb-btn.inverted.invert:active, .bb-btn.inverted.invert.active, a.bb-btn.inverted.invert:active, a.bb-btn.inverted.invert.active {
        background: #FFFFFF;
        color: #030303; }
    .bb-btn.inverted.primary, a.bb-btn.inverted.primary {
      color: #FFFFFF;
      background: #0A344D;
      border-color: #041620; }
      .bb-btn.inverted.primary:hover, .bb-btn.inverted.primary:focus, a.bb-btn.inverted.primary:hover, a.bb-btn.inverted.primary:focus {
        background: #FFFFFF;
        color: #0A344D; }
      .bb-btn.inverted.primary:active, .bb-btn.inverted.primary.active, a.bb-btn.inverted.primary:active, a.bb-btn.inverted.primary.active {
        background: #0A344D;
        color: #FFFFFF; }
    .bb-btn.inverted.secondary, a.bb-btn.inverted.secondary {
      color: #FFFFFF;
      background: #FA7268;
      border-color: #f84437; }
      .bb-btn.inverted.secondary:hover, .bb-btn.inverted.secondary:focus, a.bb-btn.inverted.secondary:hover, a.bb-btn.inverted.secondary:focus {
        background: #FFFFFF;
        color: #FA7268; }
      .bb-btn.inverted.secondary:active, .bb-btn.inverted.secondary.active, a.bb-btn.inverted.secondary:active, a.bb-btn.inverted.secondary.active {
        background: #FA7268;
        color: #FFFFFF; }
    .bb-btn.inverted.positive, a.bb-btn.inverted.positive {
      color: #FFFFFF;
      background: #50BF63;
      border-color: #3ba14c; }
      .bb-btn.inverted.positive:hover, .bb-btn.inverted.positive:focus, a.bb-btn.inverted.positive:hover, a.bb-btn.inverted.positive:focus {
        background: #FFFFFF;
        color: #50BF63; }
      .bb-btn.inverted.positive:active, .bb-btn.inverted.positive.active, a.bb-btn.inverted.positive:active, a.bb-btn.inverted.positive.active {
        background: #50BF63;
        color: #FFFFFF; }
    .bb-btn.inverted.negative, a.bb-btn.inverted.negative {
      color: #FFFFFF;
      background: #DC4C41;
      border-color: #c63024; }
      .bb-btn.inverted.negative:hover, .bb-btn.inverted.negative:focus, a.bb-btn.inverted.negative:hover, a.bb-btn.inverted.negative:focus {
        background: #FFFFFF;
        color: #DC4C41; }
      .bb-btn.inverted.negative:active, .bb-btn.inverted.negative.active, a.bb-btn.inverted.negative:active, a.bb-btn.inverted.negative.active {
        background: #DC4C41;
        color: #FFFFFF; }
  .bb-btn.loading, a.bb-btn.loading {
    position: relative;
    color: transparent !important;
    cursor: default;
    pointer-events: none;
    background: black;
    border-color: black; }
    .bb-btn.loading:after, a.bb-btn.loading:after {
      border-color: #FFFFFF transparent transparent; }
    .bb-btn.loading.outline, a.bb-btn.loading.outline {
      color: #FFFFFF;
      background: transparent;
      border-color: black; }
      .bb-btn.loading.outline:after, a.bb-btn.loading.outline:after {
        border-color: black transparent transparent; }
    .bb-btn.loading:after, a.bb-btn.loading:after {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -0.375rem 0 0 -0.375rem;
      width: 0.75rem;
      height: 0.75rem;
      border-width: 0.1rem;
      border-style: solid;
      content: '';
      -webkit-animation: button-spin 0.75s linear;
              animation: button-spin 0.75s linear;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      border-radius: 50%; }
    .bb-btn.loading.invert, a.bb-btn.loading.invert {
      background: #e0e0e0;
      border-color: #e0e0e0; }
      .bb-btn.loading.invert:after, a.bb-btn.loading.invert:after {
        border-color: #030303 transparent transparent; }
      .bb-btn.loading.invert.outline, a.bb-btn.loading.invert.outline {
        color: #030303;
        background: transparent;
        border-color: #adadad; }
        .bb-btn.loading.invert.outline:after, a.bb-btn.loading.invert.outline:after {
          border-color: #e0e0e0 transparent transparent; }
    .bb-btn.loading.primary, a.bb-btn.loading.primary {
      background: #030f17;
      border-color: #030f17; }
      .bb-btn.loading.primary:after, a.bb-btn.loading.primary:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.primary.outline, a.bb-btn.loading.primary.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: black; }
        .bb-btn.loading.primary.outline:after, a.bb-btn.loading.primary.outline:after {
          border-color: #030f17 transparent transparent; }
    .bb-btn.loading.secondary, a.bb-btn.loading.secondary {
      background: #f83b2d;
      border-color: #f83b2d; }
      .bb-btn.loading.secondary:after, a.bb-btn.loading.secondary:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.secondary.outline, a.bb-btn.loading.secondary.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: #b91206; }
        .bb-btn.loading.secondary.outline:after, a.bb-btn.loading.secondary.outline:after {
          border-color: #f83b2d transparent transparent; }
    .bb-btn.loading.positive, a.bb-btn.loading.positive {
      background: #389a49;
      border-color: #389a49; }
      .bb-btn.loading.positive:after, a.bb-btn.loading.positive:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.positive.outline, a.bb-btn.loading.positive.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: #1d4f25; }
        .bb-btn.loading.positive.outline:after, a.bb-btn.loading.positive.outline:after {
          border-color: #389a49 transparent transparent; }
    .bb-btn.loading.negative, a.bb-btn.loading.negative {
      background: #bd2e23;
      border-color: #bd2e23; }
      .bb-btn.loading.negative:after, a.bb-btn.loading.negative:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.negative.outline, a.bb-btn.loading.negative.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: #671913; }
        .bb-btn.loading.negative.outline:after, a.bb-btn.loading.negative.outline:after {
          border-color: #bd2e23 transparent transparent; }
  .bb-btn.icon, a.bb-btn.icon {
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }
    .bb-btn.icon span, a.bb-btn.icon span {
      display: inline-flex;
      margin: 0;
      width: auto;
      height: auto; }

.link {
  border: 0;
  text-decoration: none;
  border-radius: 0rem;
  color: #030303;
  background: transparent; }
  .link:hover, .link:focus, .link:active {
    color: black;
    background: transparent;
    text-decoration: underline; }
  .link.invert {
    color: #FFFFFF;
    background: transparent; }
    .link.invert:hover, .link.invert:focus, .link.invert:active {
      color: #e6e6e6;
      background: transparent;
      text-decoration: underline; }
  .link.primary {
    color: #0A344D;
    background: transparent; }
    .link.primary:hover, .link.primary:focus, .link.primary:active {
      color: #041620;
      background: transparent;
      text-decoration: underline; }
  .link.secondary {
    color: #FA7268;
    background: transparent; }
    .link.secondary:hover, .link.secondary:focus, .link.secondary:active {
      color: #f84437;
      background: transparent;
      text-decoration: underline; }
  .link.positive {
    color: #50BF63;
    background: transparent; }
    .link.positive:hover, .link.positive:focus, .link.positive:active {
      color: #3ba14c;
      background: transparent;
      text-decoration: underline; }
  .link.negative {
    color: #DC4C41;
    background: transparent; }
    .link.negative:hover, .link.negative:focus, .link.negative:active {
      color: #c63024;
      background: transparent;
      text-decoration: underline; }

.close {
  position: relative;
  display: inline-block;
  outline: none;
  border: 0;
  vertical-align: top;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background: black; }
  .close:before, .close:after {
    background: #FFFFFF; }
  .close:hover, .close:focus, .close:active {
    background: black; }
  .close.invert {
    background: #e6e6e6; }
    .close.invert:before, .close.invert:after {
      background: #030303; }
    .close.invert:hover, .close.invert:focus, .close.invert:active {
      background: #cccccc; }
  .close.primary {
    background: #041620; }
    .close.primary:before, .close.primary:after {
      background: #FFFFFF; }
    .close.primary:hover, .close.primary:focus, .close.primary:active {
      background: black; }
  .close.secondary {
    background: #f84437; }
    .close.secondary:before, .close.secondary:after {
      background: #FFFFFF; }
    .close.secondary:hover, .close.secondary:focus, .close.secondary:active {
      background: #f41808; }
  .close.positive {
    background: #3ba14c; }
    .close.positive:before, .close.positive:after {
      background: #FFFFFF; }
    .close.positive:hover, .close.positive:focus, .close.positive:active {
      background: #2d7c3b; }
  .close.negative {
    background: #c63024; }
    .close.negative:before, .close.negative:after {
      background: #FFFFFF; }
    .close.negative:hover, .close.negative:focus, .close.negative:active {
      background: #9b251c; }
  .close.negative {
    background: #c63024; }
    .close.negative:before, .close.negative:after {
      background: #FFFFFF; }
    .close.negative:hover, .close.negative:focus, .close.negative:active {
      background: #9b251c; }
  .close:before, .close:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .close:before {
    width: 50%;
    height: 0.125rem; }
  .close:after {
    width: 0.125rem;
    height: 50%; }
  .close.xs {
    width: 0.85rem;
    height: 0.85rem; }
  .close.sm {
    width: 1.05rem;
    height: 1.05rem; }
  .close.lg {
    width: 1.45rem;
    height: 1.45rem; }
  .close.xl {
    width: 1.65rem;
    height: 1.65rem; }

@keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.bb-btns {
  display: inline-flex;
  flex-direction: row;
  margin: 0;
  width: 100%;
  vertical-align: baseline; }
  @media (min-width: 40rem) {
    .bb-btns {
      width: auto; } }
  .bb-btns.full-x {
    width: 100%; }
  .bb-btns.no-border-radius {
    border-radius: 0rem; }
    .bb-btns.no-border-radius .bb-btn:first-child {
      border-top-left-radius: 0rem;
      border-bottom-left-radius: 0rem; }
    .bb-btns.no-border-radius .bb-btn:last-child {
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem; }
  .bb-btns.vertical {
    display: flex;
    flex-direction: column; }
    .bb-btns.vertical .bb-btn {
      margin: 0;
      border-radius: 0rem; }
      .bb-btns.vertical .bb-btn:first-child {
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0rem;
        border-bottom-left-radius: 0rem; }
      .bb-btns.vertical .bb-btn:last-child {
        border-top-left-radius: 0rem;
        border-top-right-radius: 0rem;
        border-bottom-right-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem; }
  .bb-btns .bb-btn {
    flex: 1 1;
    padding: 0.5rem 0.5rem;
    border-radius: 0rem; }
    .bb-btns .bb-btn:first-child {
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem; }
    .bb-btns .bb-btn:last-child {
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem; }

.bb-card {
  position: relative;
  max-width: 100%;
  background: #FFFFFF;
  box-shadow: 0 1.25rem 1.5rem -1.25rem rgba(10, 10, 10, 0.1);
  color: #030303;
  font-family: "Inter", "Helvetica", "Arial", sans-serif;
  border: 0.125rem solid #E0E0E0;
  border-radius: 0.4rem; }
  .bb-card.primary {
    background: #0A344D;
    color: #FFFFFF;
    border: 0.125rem solid #0A344D; }
  .bb-card .header {
    display: flex;
    align-items: stretch;
    border-bottom: 0.0625rem solid #E0E0E0; }
    .bb-card .header .header-title {
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 1rem 1rem;
      color: #0A344D;
      font-weight: 700;
      font-family: "Ubuntu", "Helvetica", "Arial", sans-serif; }
  .bb-card .content {
    padding: 1rem 1rem; }
    .bb-card .content + .content {
      padding-top: 0; }
    .bb-card .content .media:not(:last-child) {
      margin-bottom: 0.5rem; }
    .bb-card .content:last-child {
      padding-bottom: 1rem; }
  .bb-card .image {
    position: relative;
    display: block; }
    .bb-card .image img {
      width: 100%; }
    .bb-card .image:first-child {
      border-bottom: 0.125rem solid #E0E0E0; }
      .bb-card .image:first-child img {
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem; }
    .bb-card .image:last-child {
      border-top: 0.125rem solid #E0E0E0; }
      .bb-card .image:last-child img {
        border-bottom-right-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem; }
    .bb-card .image:not(:first-child):not(:last-child) {
      border-top: 0.125rem solid #E0E0E0;
      border-bottom: 0.125rem solid #E0E0E0; }
  .bb-card .footer {
    display: flex;
    align-items: stretch;
    border-top: 0.0625rem solid #E0E0E0; }
    .bb-card .footer .item {
      display: flex;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: center;
      padding: 0.5rem 0.5rem; }
      .bb-card .footer .item:hover, .bb-card .footer .item:focus, .bb-card .footer .item:active {
        background: #E0E0E0;
        text-decoration: none; }
      .bb-card .footer .item:first-child {
        border-bottom-left-radius: 0.2rem; }
      .bb-card .footer .item:last-child {
        border-bottom-right-radius: 0.2rem; }
      .bb-card .footer .item:not(:last-child) {
        border-right: 0.0625rem solid #E0E0E0; }
  .bb-card.no-shadow {
    box-shadow: none; }
  .bb-card.no-border-radius {
    border-radius: 0rem; }
    .bb-card.no-border-radius .image img {
      border-radius: 0rem; }
    .bb-card.no-border-radius .footer .item {
      border-radius: 0rem; }
  .bb-card.right {
    text-align: right; }
    .bb-card.right .header-title {
      justify-content: flex-end; }
  .bb-card.center {
    text-align: center; }
    .bb-card.center .header-title {
      justify-content: center; }

.bb-divider {
  margin: 1.5rem 0;
  height: 0;
  font-weight: normal;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bb-divider + p:not(:first-child) {
    padding-top: 0; }
  .bb-divider:not(.divider-content) {
    border-width: 0.0625rem;
    border-style: solid;
    border-radius: 0.125rem;
    border-color: #A0A0A0; }
  .bb-divider.invert {
    border-color: #FFFFFF; }
  .bb-divider.primary {
    border-color: #0A344D; }
  .bb-divider.secondary {
    border-color: #FA7268; }
  .bb-divider.positive {
    border-color: #50BF63; }
  .bb-divider.negative {
    border-color: #DC4C41; }
  .bb-divider.divider-content {
    display: table;
    height: auto;
    text-align: center;
    white-space: nowrap;
    line-height: 1; }
    .bb-divider.divider-content:before, .bb-divider.divider-content:after {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 50%;
      background-repeat: no-repeat;
      content: '';
      background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(#A0A0A0), to(transparent));
      background-image: -webkit-linear-gradient(top, transparent calc(50% - 0.09375rem), #A0A0A0 calc(50%), transparent calc(50% + 0.09375rem));
      background-image: linear-gradient(180deg, transparent calc(50% - 0.09375rem), #A0A0A0 calc(50%), transparent calc(50% + 0.09375rem)); }
    .bb-divider.divider-content:before {
      background-position: right 1rem top 50%; }
    .bb-divider.divider-content:after {
      background-position: left 1rem top 50%; }
  .bb-divider.hidden {
    border-color: transparent; }

.bb-dropdown.active .dropdown-menu, .bb-dropdown.hover:hover .dropdown-menu {
  display: block;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  pointer-events: auto; }

.bb-dropdown .dropdown-menu {
  position: absolute;
  display: block; }
  @media (min-width: 57.5rem) {
    .bb-dropdown .dropdown-menu.active .dropdown-menu, .bb-dropdown .dropdown-menu.hover:hover .dropdown-menu, .bb-dropdown .dropdown-menu.hover:focus .dropdown-menu {
      display: block;
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      pointer-events: auto; } }
  .bb-dropdown .dropdown-menu .dropdown-item {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 0.5rem 1.5rem;
    -webkit-transition: color 0s, opacity ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s;
    transition: color 0s, opacity ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s;
    transition: color 0s, opacity ease-in-out 0.25s, transform ease-in-out 0.25s;
    transition: color 0s, opacity ease-in-out 0.25s, transform ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s; }
    .bb-dropdown .dropdown-menu .dropdown-item:hover, .bb-dropdown .dropdown-menu .dropdown-item:focus {
      background: rgba(10, 52, 77, 0.05);
      color: #030303;
      text-decoration: none; }
    @media (min-width: 57.5rem) {
      .bb-dropdown .dropdown-menu .dropdown-item {
        padding: 0.5rem 1.5rem;
        white-space: nowrap; } }
    .bb-dropdown .dropdown-menu .dropdown-item.active {
      background: rgba(10, 52, 77, 0.05);
      color: #030303; }

.bb-dropdown .bb-divider {
  margin: 0.5rem 0; }

.bb-field {
  margin-bottom: 0.5rem;
  width: 100%;
  height: auto; }
  .bb-field input,
  .bb-field select,
  .bb-field textarea,
  .bb-field .field-addon {
    padding: 0.5rem 0.5rem;
    font-size: 1rem; }
  .bb-field label {
    padding-bottom: 0.5rem;
    font-size: 1rem; }
  .bb-field.invert input,
  .bb-field.invert select,
  .bb-field.invert textarea {
    border-color: #FFFFFF; }
    .bb-field.invert input:hover, .bb-field.invert input:focus,
    .bb-field.invert select:hover,
    .bb-field.invert select:focus,
    .bb-field.invert textarea:hover,
    .bb-field.invert textarea:focus {
      border-color: #e6e6e6; }
    .bb-field.invert input:focus, .bb-field.invert input:active,
    .bb-field.invert select:focus,
    .bb-field.invert select:active,
    .bb-field.invert textarea:focus,
    .bb-field.invert textarea:active {
      border-color: #e0e0e0; }
      .bb-field.invert input:focus + .field-addon, .bb-field.invert input:active + .field-addon,
      .bb-field.invert select:focus + .field-addon,
      .bb-field.invert select:active + .field-addon,
      .bb-field.invert textarea:focus + .field-addon,
      .bb-field.invert textarea:active + .field-addon {
        background: #FFFFFF;
        color: #030303;
        border-color: #e6e6e6; }
  .bb-field.invert .bb-select:hover:after {
    border-color: #FFFFFF; }
  .bb-field.primary input,
  .bb-field.primary select,
  .bb-field.primary textarea {
    border-color: #0A344D; }
    .bb-field.primary input:hover, .bb-field.primary input:focus,
    .bb-field.primary select:hover,
    .bb-field.primary select:focus,
    .bb-field.primary textarea:hover,
    .bb-field.primary textarea:focus {
      border-color: #041620; }
    .bb-field.primary input:focus, .bb-field.primary input:active,
    .bb-field.primary select:focus,
    .bb-field.primary select:active,
    .bb-field.primary textarea:focus,
    .bb-field.primary textarea:active {
      border-color: #030f17; }
      .bb-field.primary input:focus + .field-addon, .bb-field.primary input:active + .field-addon,
      .bb-field.primary select:focus + .field-addon,
      .bb-field.primary select:active + .field-addon,
      .bb-field.primary textarea:focus + .field-addon,
      .bb-field.primary textarea:active + .field-addon {
        background: #0A344D;
        color: #FFFFFF;
        border-color: #041620; }
  .bb-field.primary .bb-select:hover:after {
    border-color: #0A344D; }
  .bb-field.secondary input,
  .bb-field.secondary select,
  .bb-field.secondary textarea {
    border-color: #FA7268; }
    .bb-field.secondary input:hover, .bb-field.secondary input:focus,
    .bb-field.secondary select:hover,
    .bb-field.secondary select:focus,
    .bb-field.secondary textarea:hover,
    .bb-field.secondary textarea:focus {
      border-color: #f84437; }
    .bb-field.secondary input:focus, .bb-field.secondary input:active,
    .bb-field.secondary select:focus,
    .bb-field.secondary select:active,
    .bb-field.secondary textarea:focus,
    .bb-field.secondary textarea:active {
      border-color: #f83b2d; }
      .bb-field.secondary input:focus + .field-addon, .bb-field.secondary input:active + .field-addon,
      .bb-field.secondary select:focus + .field-addon,
      .bb-field.secondary select:active + .field-addon,
      .bb-field.secondary textarea:focus + .field-addon,
      .bb-field.secondary textarea:active + .field-addon {
        background: #FA7268;
        color: #FFFFFF;
        border-color: #f84437; }
  .bb-field.secondary .bb-select:hover:after {
    border-color: #FA7268; }
  .bb-field.positive input,
  .bb-field.positive select,
  .bb-field.positive textarea {
    border-color: #50BF63; }
    .bb-field.positive input:hover, .bb-field.positive input:focus,
    .bb-field.positive select:hover,
    .bb-field.positive select:focus,
    .bb-field.positive textarea:hover,
    .bb-field.positive textarea:focus {
      border-color: #3ba14c; }
    .bb-field.positive input:focus, .bb-field.positive input:active,
    .bb-field.positive select:focus,
    .bb-field.positive select:active,
    .bb-field.positive textarea:focus,
    .bb-field.positive textarea:active {
      border-color: #389a49; }
      .bb-field.positive input:focus + .field-addon, .bb-field.positive input:active + .field-addon,
      .bb-field.positive select:focus + .field-addon,
      .bb-field.positive select:active + .field-addon,
      .bb-field.positive textarea:focus + .field-addon,
      .bb-field.positive textarea:active + .field-addon {
        background: #50BF63;
        color: #FFFFFF;
        border-color: #3ba14c; }
  .bb-field.positive .bb-select:hover:after {
    border-color: #50BF63; }
  .bb-field.negative input,
  .bb-field.negative select,
  .bb-field.negative textarea {
    border-color: #DC4C41; }
    .bb-field.negative input:hover, .bb-field.negative input:focus,
    .bb-field.negative select:hover,
    .bb-field.negative select:focus,
    .bb-field.negative textarea:hover,
    .bb-field.negative textarea:focus {
      border-color: #c63024; }
    .bb-field.negative input:focus, .bb-field.negative input:active,
    .bb-field.negative select:focus,
    .bb-field.negative select:active,
    .bb-field.negative textarea:focus,
    .bb-field.negative textarea:active {
      border-color: #bd2e23; }
      .bb-field.negative input:focus + .field-addon, .bb-field.negative input:active + .field-addon,
      .bb-field.negative select:focus + .field-addon,
      .bb-field.negative select:active + .field-addon,
      .bb-field.negative textarea:focus + .field-addon,
      .bb-field.negative textarea:active + .field-addon {
        background: #DC4C41;
        color: #FFFFFF;
        border-color: #c63024; }
  .bb-field.negative .bb-select:hover:after {
    border-color: #DC4C41; }
  .bb-field.success input,
  .bb-field.success select,
  .bb-field.success textarea,
  .bb-field.success .text {
    color: #50BF63;
    border-color: #50BF63; }
    .bb-field.success input::-webkit-input-placeholder,
    .bb-field.success select::-webkit-input-placeholder,
    .bb-field.success textarea::-webkit-input-placeholder,
    .bb-field.success .text::-webkit-input-placeholder {
      color: #50BF63; }
    .bb-field.success input::-moz-placeholder,
    .bb-field.success select::-moz-placeholder,
    .bb-field.success textarea::-moz-placeholder,
    .bb-field.success .text::-moz-placeholder {
      color: #50BF63; }
    .bb-field.success input::-ms-input-placeholder,
    .bb-field.success select::-ms-input-placeholder,
    .bb-field.success textarea::-ms-input-placeholder,
    .bb-field.success .text::-ms-input-placeholder {
      color: #50BF63; }
    .bb-field.success input::placeholder,
    .bb-field.success select::placeholder,
    .bb-field.success textarea::placeholder,
    .bb-field.success .text::placeholder {
      color: #50BF63; }
  .bb-field.error input,
  .bb-field.error select,
  .bb-field.error textarea,
  .bb-field.error .text {
    color: #DC4C41;
    border-color: #DC4C41; }
    .bb-field.error input::-webkit-input-placeholder,
    .bb-field.error select::-webkit-input-placeholder,
    .bb-field.error textarea::-webkit-input-placeholder,
    .bb-field.error .text::-webkit-input-placeholder {
      color: #DC4C41; }
    .bb-field.error input::-moz-placeholder,
    .bb-field.error select::-moz-placeholder,
    .bb-field.error textarea::-moz-placeholder,
    .bb-field.error .text::-moz-placeholder {
      color: #DC4C41; }
    .bb-field.error input::-ms-input-placeholder,
    .bb-field.error select::-ms-input-placeholder,
    .bb-field.error textarea::-ms-input-placeholder,
    .bb-field.error .text::-ms-input-placeholder {
      color: #DC4C41; }
    .bb-field.error input::placeholder,
    .bb-field.error select::placeholder,
    .bb-field.error textarea::placeholder,
    .bb-field.error .text::placeholder {
      color: #DC4C41; }
  .bb-field.disabled input,
  .bb-field.disabled select,
  .bb-field.disabled textarea, .bb-field:disabled input,
  .bb-field:disabled select,
  .bb-field:disabled textarea {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none; }
  .bb-field.disabled label, .bb-field:disabled label {
    cursor: not-allowed;
    pointer-events: none; }
  .bb-field.xs input,
  .bb-field.xs select,
  .bb-field.xs textarea,
  .bb-field.xs .field-addon {
    padding: 0rem 0rem;
    font-size: 0.5rem; }
  .bb-field.xs label {
    padding-bottom: 0rem;
    font-size: 0.5rem; }
  .bb-field.sm input,
  .bb-field.sm select,
  .bb-field.sm textarea,
  .bb-field.sm .field-addon {
    padding: 0.25rem 0.25rem;
    font-size: 0.75rem; }
  .bb-field.sm label {
    padding-bottom: 0.25rem;
    font-size: 0.75rem; }
  .bb-field.lg input,
  .bb-field.lg select,
  .bb-field.lg textarea,
  .bb-field.lg .field-addon {
    padding: 0.75rem 0.75rem;
    font-size: 1.25rem; }
  .bb-field.lg label {
    padding-bottom: 0.75rem;
    font-size: 1.25rem; }
  .bb-field.xl input,
  .bb-field.xl select,
  .bb-field.xl textarea,
  .bb-field.xl .field-addon {
    padding: 1rem 1rem;
    font-size: 1.5rem; }
  .bb-field.xl label {
    padding-bottom: 1rem;
    font-size: 1.5rem; }
  .bb-field:not(.xs):not(.sm):not(.lg):not(.xl) input,
  .bb-field:not(.xs):not(.sm):not(.lg):not(.xl) select,
  .bb-field:not(.xs):not(.sm):not(.lg):not(.xl) textarea {
    min-height: 2.625rem; }
  .bb-field input,
  .bb-field select,
  .bb-field textarea {
    position: relative;
    z-index: 1;
    display: block;
    flex: 1 1 auto;
    margin-bottom: 0;
    width: 100%;
    background: #FFFFFF;
    color: #030303;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0.125rem solid #E0E0E0;
    border-radius: 0.2rem;
    -webkit-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s; }
    .bb-field input:hover, .bb-field input:focus, .bb-field input:active,
    .bb-field select:hover,
    .bb-field select:focus,
    .bb-field select:active,
    .bb-field textarea:hover,
    .bb-field textarea:focus,
    .bb-field textarea:active {
      z-index: 3;
      outline: none;
      box-shadow: none;
      border-color: black; }
      .bb-field input:hover::-webkit-input-placeholder, .bb-field input:focus::-webkit-input-placeholder, .bb-field input:active::-webkit-input-placeholder,
      .bb-field select:hover::-webkit-input-placeholder,
      .bb-field select:focus::-webkit-input-placeholder,
      .bb-field select:active::-webkit-input-placeholder,
      .bb-field textarea:hover::-webkit-input-placeholder,
      .bb-field textarea:focus::-webkit-input-placeholder,
      .bb-field textarea:active::-webkit-input-placeholder {
        color: black; }
      .bb-field input:hover::-moz-placeholder, .bb-field input:focus::-moz-placeholder, .bb-field input:active::-moz-placeholder,
      .bb-field select:hover::-moz-placeholder,
      .bb-field select:focus::-moz-placeholder,
      .bb-field select:active::-moz-placeholder,
      .bb-field textarea:hover::-moz-placeholder,
      .bb-field textarea:focus::-moz-placeholder,
      .bb-field textarea:active::-moz-placeholder {
        color: black; }
      .bb-field input:hover::-ms-input-placeholder, .bb-field input:focus::-ms-input-placeholder, .bb-field input:active::-ms-input-placeholder,
      .bb-field select:hover::-ms-input-placeholder,
      .bb-field select:focus::-ms-input-placeholder,
      .bb-field select:active::-ms-input-placeholder,
      .bb-field textarea:hover::-ms-input-placeholder,
      .bb-field textarea:focus::-ms-input-placeholder,
      .bb-field textarea:active::-ms-input-placeholder {
        color: black; }
      .bb-field input:hover::placeholder, .bb-field input:focus::placeholder, .bb-field input:active::placeholder,
      .bb-field select:hover::placeholder,
      .bb-field select:focus::placeholder,
      .bb-field select:active::placeholder,
      .bb-field textarea:hover::placeholder,
      .bb-field textarea:focus::placeholder,
      .bb-field textarea:active::placeholder {
        color: black; }
    .bb-field input::-webkit-input-placeholder,
    .bb-field select::-webkit-input-placeholder,
    .bb-field textarea::-webkit-input-placeholder {
      color: #E0E0E0; }
    .bb-field input::-moz-placeholder,
    .bb-field select::-moz-placeholder,
    .bb-field textarea::-moz-placeholder {
      color: #E0E0E0; }
    .bb-field input::-ms-input-placeholder,
    .bb-field select::-ms-input-placeholder,
    .bb-field textarea::-ms-input-placeholder {
      color: #E0E0E0; }
    .bb-field input::placeholder,
    .bb-field select::placeholder,
    .bb-field textarea::placeholder {
      color: #E0E0E0; }
  .bb-field .bb-select {
    position: relative;
    display: inline-block;
    width: 100%; }
    .bb-field .bb-select:hover:after {
      border-color: #A0A0A0; }
    .bb-field .bb-select select {
      overflow-x: hidden;
      padding-right: calc( 0.5rem * 2 + 0.75rem); }
    .bb-field .bb-select:after {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      margin-top: calc(-0.5rem - -0.125rem);
      z-index: 5;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #A0A0A0;
      border-left: 0.125rem solid #A0A0A0; }
  .bb-field label {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    width: 100%;
    color: #030303;
    font-weight: normal;
    font-size: 1rem;
    font-family: "Ubuntu", "Helvetica", "Arial", sans-serif; }
  .bb-field .text {
    display: block;
    margin-top: 0.25rem;
    padding: 0;
    font-size: 0.75rem; }
  .bb-field .input-group {
    display: inline-flex;
    width: 100%; }

.form-inline {
  display: flex;
  justify-content: space-between; }
  .form-inline div:not(:last-child) {
    padding-right: 0.5rem; }

.bb-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2.25rem;
  cursor: pointer; }
  .bb-checkboxlabel {
    font-size: 0.75rem; }
  .bb-checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
    .bb-checkbox input:hover ~ .check, .bb-checkbox input:focus ~ .check, .bb-checkbox input:active ~ .check {
      border-color: black; }
    .bb-checkbox input:disabled:hover ~ .check, .bb-checkbox input:disabled:focus ~ .check, .bb-checkbox input:disabled:active ~ .check {
      border-color: black; }
    .bb-checkbox input:disabled:hover:checked ~ .check, .bb-checkbox input:disabled:focus:checked ~ .check, .bb-checkbox input:disabled:active:checked ~ .check {
      border-color: black; }
    .bb-checkbox input:disabled ~ .check {
      opacity: .6;
      pointer-events: none; }
    .bb-checkbox input:checked ~ .check {
      background: #FFFFFF; }
      .bb-checkbox input:checked ~ .check:after {
        display: block;
        z-index: 1;
        display: block;
        width: 0.5rem;
        height: 0.25rem;
        border-top: 0;
        border-right: 0;
        content: ' ';
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        pointer-events: none;
        border-bottom: 0.125rem solid #0A344D;
        border-left: 0.125rem solid #0A344D; }
  .bb-checkbox .check {
    position: absolute;
    top: calc(50% - 0.75rem);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.2rem;
    background: #FFFFFF;
    border: 0.125rem solid #E0E0E0;
    -webkit-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s; }
    .bb-checkbox .check:after {
      position: absolute;
      display: none;
      margin-top: -0.0625rem;
      content: '';
      z-index: 1;
      display: block;
      width: 0.5rem;
      height: 0.25rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #FFFFFF;
      border-left: 0.125rem solid #FFFFFF; }

.bb-btn, a.bb-btn {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  box-shadow: none;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
  font-family: "Inter", "Helvetica", "Arial", sans-serif;
  line-height: 1.3;
  cursor: pointer;
  -webkit-appearance: none;
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.2rem;
  border: 0.125rem solid black;
  color: #FFFFFF;
  background: #030303;
  border-color: black;
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bb-btn:hover, a.bb-btn:hover {
    box-shadow: none; }
  .bb-btn:hover, .bb-btn:focus, a.bb-btn:hover, a.bb-btn:focus {
    background: black;
    box-shadow: none;
    border-color: black; }
  .bb-btn:active, .bb-btn.active, a.bb-btn:active, a.bb-btn.active {
    background: black;
    box-shadow: none;
    border-color: black; }
  .bb-btn::-moz-focus-inner, a.bb-btn::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .bb-btn.xs, a.bb-btn.xs {
    font-size: 0.5rem;
    padding: 0rem 0rem; }
  .bb-btn.sm, a.bb-btn.sm {
    font-size: 0.75rem;
    padding: 0.25rem 0.25rem; }
  .bb-btn.lg, a.bb-btn.lg {
    font-size: 1.25rem;
    padding: 0.75rem 0.75rem; }
  .bb-btn.xl, a.bb-btn.xl {
    font-size: 1.5rem;
    padding: 1rem 1rem; }
  .bb-btn.full-x, a.bb-btn.full-x {
    display: block;
    width: 100%; }
  .bb-btn.no-border-radius, a.bb-btn.no-border-radius {
    border-radius: 0rem; }
  .bb-btn.disabled, .bb-btn:disabled, a.bb-btn.disabled, a.bb-btn:disabled {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none; }
  .bb-btn.invert, a.bb-btn.invert {
    color: #030303;
    background: #FFFFFF;
    border-color: #e6e6e6; }
    .bb-btn.invert:hover, .bb-btn.invert:focus, a.bb-btn.invert:hover, a.bb-btn.invert:focus {
      background: #e6e6e6;
      box-shadow: none;
      border-color: #e6e6e6; }
    .bb-btn.invert:active, .bb-btn.invert.active, a.bb-btn.invert:active, a.bb-btn.invert.active {
      background: #e0e0e0;
      box-shadow: none;
      border-color: #e0e0e0; }
  .bb-btn.primary, a.bb-btn.primary {
    color: #FFFFFF;
    background: #0A344D;
    border-color: #041620; }
    .bb-btn.primary:hover, .bb-btn.primary:focus, a.bb-btn.primary:hover, a.bb-btn.primary:focus {
      background: #041620;
      box-shadow: none;
      border-color: #041620; }
    .bb-btn.primary:active, .bb-btn.primary.active, a.bb-btn.primary:active, a.bb-btn.primary.active {
      background: #030f17;
      box-shadow: none;
      border-color: #030f17; }
  .bb-btn.secondary, a.bb-btn.secondary {
    color: #FFFFFF;
    background: #FA7268;
    border-color: #f84437; }
    .bb-btn.secondary:hover, .bb-btn.secondary:focus, a.bb-btn.secondary:hover, a.bb-btn.secondary:focus {
      background: #f84437;
      box-shadow: none;
      border-color: #f84437; }
    .bb-btn.secondary:active, .bb-btn.secondary.active, a.bb-btn.secondary:active, a.bb-btn.secondary.active {
      background: #f83b2d;
      box-shadow: none;
      border-color: #f83b2d; }
  .bb-btn.positive, a.bb-btn.positive {
    color: #FFFFFF;
    background: #50BF63;
    border-color: #3ba14c; }
    .bb-btn.positive:hover, .bb-btn.positive:focus, a.bb-btn.positive:hover, a.bb-btn.positive:focus {
      background: #3ba14c;
      box-shadow: none;
      border-color: #3ba14c; }
    .bb-btn.positive:active, .bb-btn.positive.active, a.bb-btn.positive:active, a.bb-btn.positive.active {
      background: #389a49;
      box-shadow: none;
      border-color: #389a49; }
  .bb-btn.negative, a.bb-btn.negative {
    color: #FFFFFF;
    background: #DC4C41;
    border-color: #c63024; }
    .bb-btn.negative:hover, .bb-btn.negative:focus, a.bb-btn.negative:hover, a.bb-btn.negative:focus {
      background: #c63024;
      box-shadow: none;
      border-color: #c63024; }
    .bb-btn.negative:active, .bb-btn.negative.active, a.bb-btn.negative:active, a.bb-btn.negative.active {
      background: #bd2e23;
      box-shadow: none;
      border-color: #bd2e23; }
  .bb-btn.outline, a.bb-btn.outline {
    color: #030303;
    background: transparent;
    background-image: none;
    border-color: black; }
    .bb-btn.outline:hover, .bb-btn.outline:focus, a.bb-btn.outline:hover, a.bb-btn.outline:focus {
      color: black;
      border-color: black; }
    .bb-btn.outline:active, .bb-btn.outline.active, a.bb-btn.outline:active, a.bb-btn.outline.active {
      color: black;
      border-color: black; }
    .bb-btn.outline.invert, a.bb-btn.outline.invert {
      color: #FFFFFF;
      background: transparent;
      background-image: none;
      border-color: #e6e6e6; }
      .bb-btn.outline.invert:hover, .bb-btn.outline.invert:focus, a.bb-btn.outline.invert:hover, a.bb-btn.outline.invert:focus {
        color: #b3b3b3;
        border-color: #b3b3b3; }
      .bb-btn.outline.invert:active, .bb-btn.outline.invert.active, a.bb-btn.outline.invert:active, a.bb-btn.outline.invert.active {
        color: #b3b3b3;
        border-color: #b3b3b3; }
    .bb-btn.outline.primary, a.bb-btn.outline.primary {
      color: #0A344D;
      background: transparent;
      background-image: none;
      border-color: #041620; }
      .bb-btn.outline.primary:hover, .bb-btn.outline.primary:focus, a.bb-btn.outline.primary:hover, a.bb-btn.outline.primary:focus {
        color: black;
        border-color: black; }
      .bb-btn.outline.primary:active, .bb-btn.outline.primary.active, a.bb-btn.outline.primary:active, a.bb-btn.outline.primary.active {
        color: black;
        border-color: black; }
    .bb-btn.outline.secondary, a.bb-btn.outline.secondary {
      color: #FA7268;
      background: transparent;
      background-image: none;
      border-color: #f84437; }
      .bb-btn.outline.secondary:hover, .bb-btn.outline.secondary:focus, a.bb-btn.outline.secondary:hover, a.bb-btn.outline.secondary:focus {
        color: #c31306;
        border-color: #c31306; }
      .bb-btn.outline.secondary:active, .bb-btn.outline.secondary.active, a.bb-btn.outline.secondary:active, a.bb-btn.outline.secondary.active {
        color: #c31306;
        border-color: #c31306; }
    .bb-btn.outline.positive, a.bb-btn.outline.positive {
      color: #50BF63;
      background: transparent;
      background-image: none;
      border-color: #3ba14c; }
      .bb-btn.outline.positive:hover, .bb-btn.outline.positive:focus, a.bb-btn.outline.positive:hover, a.bb-btn.outline.positive:focus {
        color: #205629;
        border-color: #205629; }
      .bb-btn.outline.positive:active, .bb-btn.outline.positive.active, a.bb-btn.outline.positive:active, a.bb-btn.outline.positive.active {
        color: #205629;
        border-color: #205629; }
    .bb-btn.outline.negative, a.bb-btn.outline.negative {
      color: #DC4C41;
      background: transparent;
      background-image: none;
      border-color: #c63024; }
      .bb-btn.outline.negative:hover, .bb-btn.outline.negative:focus, a.bb-btn.outline.negative:hover, a.bb-btn.outline.negative:focus {
        color: #6f1b15;
        border-color: #6f1b15; }
      .bb-btn.outline.negative:active, .bb-btn.outline.negative.active, a.bb-btn.outline.negative:active, a.bb-btn.outline.negative.active {
        color: #6f1b15;
        border-color: #6f1b15; }
  .bb-btn.inverted, a.bb-btn.inverted {
    color: #FFFFFF;
    background: #030303;
    border-color: black; }
    .bb-btn.inverted:hover, .bb-btn.inverted:focus, a.bb-btn.inverted:hover, a.bb-btn.inverted:focus {
      background: #FFFFFF;
      color: #030303; }
    .bb-btn.inverted:active, .bb-btn.inverted.active, a.bb-btn.inverted:active, a.bb-btn.inverted.active {
      background: #030303;
      color: #FFFFFF; }
    .bb-btn.inverted.invert, a.bb-btn.inverted.invert {
      color: #030303;
      background: #FFFFFF;
      border-color: #e6e6e6; }
      .bb-btn.inverted.invert:hover, .bb-btn.inverted.invert:focus, a.bb-btn.inverted.invert:hover, a.bb-btn.inverted.invert:focus {
        background: #030303;
        color: #FFFFFF; }
      .bb-btn.inverted.invert:active, .bb-btn.inverted.invert.active, a.bb-btn.inverted.invert:active, a.bb-btn.inverted.invert.active {
        background: #FFFFFF;
        color: #030303; }
    .bb-btn.inverted.primary, a.bb-btn.inverted.primary {
      color: #FFFFFF;
      background: #0A344D;
      border-color: #041620; }
      .bb-btn.inverted.primary:hover, .bb-btn.inverted.primary:focus, a.bb-btn.inverted.primary:hover, a.bb-btn.inverted.primary:focus {
        background: #FFFFFF;
        color: #0A344D; }
      .bb-btn.inverted.primary:active, .bb-btn.inverted.primary.active, a.bb-btn.inverted.primary:active, a.bb-btn.inverted.primary.active {
        background: #0A344D;
        color: #FFFFFF; }
    .bb-btn.inverted.secondary, a.bb-btn.inverted.secondary {
      color: #FFFFFF;
      background: #FA7268;
      border-color: #f84437; }
      .bb-btn.inverted.secondary:hover, .bb-btn.inverted.secondary:focus, a.bb-btn.inverted.secondary:hover, a.bb-btn.inverted.secondary:focus {
        background: #FFFFFF;
        color: #FA7268; }
      .bb-btn.inverted.secondary:active, .bb-btn.inverted.secondary.active, a.bb-btn.inverted.secondary:active, a.bb-btn.inverted.secondary.active {
        background: #FA7268;
        color: #FFFFFF; }
    .bb-btn.inverted.positive, a.bb-btn.inverted.positive {
      color: #FFFFFF;
      background: #50BF63;
      border-color: #3ba14c; }
      .bb-btn.inverted.positive:hover, .bb-btn.inverted.positive:focus, a.bb-btn.inverted.positive:hover, a.bb-btn.inverted.positive:focus {
        background: #FFFFFF;
        color: #50BF63; }
      .bb-btn.inverted.positive:active, .bb-btn.inverted.positive.active, a.bb-btn.inverted.positive:active, a.bb-btn.inverted.positive.active {
        background: #50BF63;
        color: #FFFFFF; }
    .bb-btn.inverted.negative, a.bb-btn.inverted.negative {
      color: #FFFFFF;
      background: #DC4C41;
      border-color: #c63024; }
      .bb-btn.inverted.negative:hover, .bb-btn.inverted.negative:focus, a.bb-btn.inverted.negative:hover, a.bb-btn.inverted.negative:focus {
        background: #FFFFFF;
        color: #DC4C41; }
      .bb-btn.inverted.negative:active, .bb-btn.inverted.negative.active, a.bb-btn.inverted.negative:active, a.bb-btn.inverted.negative.active {
        background: #DC4C41;
        color: #FFFFFF; }
  .bb-btn.loading, a.bb-btn.loading {
    position: relative;
    color: transparent !important;
    cursor: default;
    pointer-events: none;
    background: black;
    border-color: black; }
    .bb-btn.loading:after, a.bb-btn.loading:after {
      border-color: #FFFFFF transparent transparent; }
    .bb-btn.loading.outline, a.bb-btn.loading.outline {
      color: #FFFFFF;
      background: transparent;
      border-color: black; }
      .bb-btn.loading.outline:after, a.bb-btn.loading.outline:after {
        border-color: black transparent transparent; }
    .bb-btn.loading:after, a.bb-btn.loading:after {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -0.375rem 0 0 -0.375rem;
      width: 0.75rem;
      height: 0.75rem;
      border-width: 0.1rem;
      border-style: solid;
      content: '';
      -webkit-animation: button-spin 0.75s linear;
              animation: button-spin 0.75s linear;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      border-radius: 50%; }
    .bb-btn.loading.invert, a.bb-btn.loading.invert {
      background: #e0e0e0;
      border-color: #e0e0e0; }
      .bb-btn.loading.invert:after, a.bb-btn.loading.invert:after {
        border-color: #030303 transparent transparent; }
      .bb-btn.loading.invert.outline, a.bb-btn.loading.invert.outline {
        color: #030303;
        background: transparent;
        border-color: #adadad; }
        .bb-btn.loading.invert.outline:after, a.bb-btn.loading.invert.outline:after {
          border-color: #e0e0e0 transparent transparent; }
    .bb-btn.loading.primary, a.bb-btn.loading.primary {
      background: #030f17;
      border-color: #030f17; }
      .bb-btn.loading.primary:after, a.bb-btn.loading.primary:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.primary.outline, a.bb-btn.loading.primary.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: black; }
        .bb-btn.loading.primary.outline:after, a.bb-btn.loading.primary.outline:after {
          border-color: #030f17 transparent transparent; }
    .bb-btn.loading.secondary, a.bb-btn.loading.secondary {
      background: #f83b2d;
      border-color: #f83b2d; }
      .bb-btn.loading.secondary:after, a.bb-btn.loading.secondary:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.secondary.outline, a.bb-btn.loading.secondary.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: #b91206; }
        .bb-btn.loading.secondary.outline:after, a.bb-btn.loading.secondary.outline:after {
          border-color: #f83b2d transparent transparent; }
    .bb-btn.loading.positive, a.bb-btn.loading.positive {
      background: #389a49;
      border-color: #389a49; }
      .bb-btn.loading.positive:after, a.bb-btn.loading.positive:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.positive.outline, a.bb-btn.loading.positive.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: #1d4f25; }
        .bb-btn.loading.positive.outline:after, a.bb-btn.loading.positive.outline:after {
          border-color: #389a49 transparent transparent; }
    .bb-btn.loading.negative, a.bb-btn.loading.negative {
      background: #bd2e23;
      border-color: #bd2e23; }
      .bb-btn.loading.negative:after, a.bb-btn.loading.negative:after {
        border-color: #FFFFFF transparent transparent; }
      .bb-btn.loading.negative.outline, a.bb-btn.loading.negative.outline {
        color: #FFFFFF;
        background: transparent;
        border-color: #671913; }
        .bb-btn.loading.negative.outline:after, a.bb-btn.loading.negative.outline:after {
          border-color: #bd2e23 transparent transparent; }
  .bb-btn.icon, a.bb-btn.icon {
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }
    .bb-btn.icon span, a.bb-btn.icon span {
      display: inline-flex;
      margin: 0;
      width: auto;
      height: auto; }

.link {
  border: 0;
  text-decoration: none;
  border-radius: 0rem;
  color: #030303;
  background: transparent; }
  .link:hover, .link:focus, .link:active {
    color: black;
    background: transparent;
    text-decoration: underline; }
  .link.invert {
    color: #FFFFFF;
    background: transparent; }
    .link.invert:hover, .link.invert:focus, .link.invert:active {
      color: #e6e6e6;
      background: transparent;
      text-decoration: underline; }
  .link.primary {
    color: #0A344D;
    background: transparent; }
    .link.primary:hover, .link.primary:focus, .link.primary:active {
      color: #041620;
      background: transparent;
      text-decoration: underline; }
  .link.secondary {
    color: #FA7268;
    background: transparent; }
    .link.secondary:hover, .link.secondary:focus, .link.secondary:active {
      color: #f84437;
      background: transparent;
      text-decoration: underline; }
  .link.positive {
    color: #50BF63;
    background: transparent; }
    .link.positive:hover, .link.positive:focus, .link.positive:active {
      color: #3ba14c;
      background: transparent;
      text-decoration: underline; }
  .link.negative {
    color: #DC4C41;
    background: transparent; }
    .link.negative:hover, .link.negative:focus, .link.negative:active {
      color: #c63024;
      background: transparent;
      text-decoration: underline; }

.close {
  position: relative;
  display: inline-block;
  outline: none;
  border: 0;
  vertical-align: top;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background: black; }
  .close:before, .close:after {
    background: #FFFFFF; }
  .close:hover, .close:focus, .close:active {
    background: black; }
  .close.invert {
    background: #e6e6e6; }
    .close.invert:before, .close.invert:after {
      background: #030303; }
    .close.invert:hover, .close.invert:focus, .close.invert:active {
      background: #cccccc; }
  .close.primary {
    background: #041620; }
    .close.primary:before, .close.primary:after {
      background: #FFFFFF; }
    .close.primary:hover, .close.primary:focus, .close.primary:active {
      background: black; }
  .close.secondary {
    background: #f84437; }
    .close.secondary:before, .close.secondary:after {
      background: #FFFFFF; }
    .close.secondary:hover, .close.secondary:focus, .close.secondary:active {
      background: #f41808; }
  .close.positive {
    background: #3ba14c; }
    .close.positive:before, .close.positive:after {
      background: #FFFFFF; }
    .close.positive:hover, .close.positive:focus, .close.positive:active {
      background: #2d7c3b; }
  .close.negative {
    background: #c63024; }
    .close.negative:before, .close.negative:after {
      background: #FFFFFF; }
    .close.negative:hover, .close.negative:focus, .close.negative:active {
      background: #9b251c; }
  .close.negative {
    background: #c63024; }
    .close.negative:before, .close.negative:after {
      background: #FFFFFF; }
    .close.negative:hover, .close.negative:focus, .close.negative:active {
      background: #9b251c; }
  .close:before, .close:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .close:before {
    width: 50%;
    height: 0.125rem; }
  .close:after {
    width: 0.125rem;
    height: 50%; }
  .close.xs {
    width: 0.85rem;
    height: 0.85rem; }
  .close.sm {
    width: 1.05rem;
    height: 1.05rem; }
  .close.lg {
    width: 1.45rem;
    height: 1.45rem; }
  .close.xl {
    width: 1.65rem;
    height: 1.65rem; }

@keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.bb-field {
  margin-bottom: 0.5rem;
  width: 100%;
  height: auto; }
  .bb-field input,
  .bb-field select,
  .bb-field textarea,
  .bb-field .field-addon {
    padding: 0.5rem 0.5rem;
    font-size: 1rem; }
  .bb-field label {
    padding-bottom: 0.5rem;
    font-size: 1rem; }
  .bb-field.invert input,
  .bb-field.invert select,
  .bb-field.invert textarea {
    border-color: #FFFFFF; }
    .bb-field.invert input:hover, .bb-field.invert input:focus,
    .bb-field.invert select:hover,
    .bb-field.invert select:focus,
    .bb-field.invert textarea:hover,
    .bb-field.invert textarea:focus {
      border-color: #e6e6e6; }
    .bb-field.invert input:focus, .bb-field.invert input:active,
    .bb-field.invert select:focus,
    .bb-field.invert select:active,
    .bb-field.invert textarea:focus,
    .bb-field.invert textarea:active {
      border-color: #e0e0e0; }
      .bb-field.invert input:focus + .field-addon, .bb-field.invert input:active + .field-addon,
      .bb-field.invert select:focus + .field-addon,
      .bb-field.invert select:active + .field-addon,
      .bb-field.invert textarea:focus + .field-addon,
      .bb-field.invert textarea:active + .field-addon {
        background: #FFFFFF;
        color: #030303;
        border-color: #e6e6e6; }
  .bb-field.invert .bb-select:hover:after {
    border-color: #FFFFFF; }
  .bb-field.primary input,
  .bb-field.primary select,
  .bb-field.primary textarea {
    border-color: #0A344D; }
    .bb-field.primary input:hover, .bb-field.primary input:focus,
    .bb-field.primary select:hover,
    .bb-field.primary select:focus,
    .bb-field.primary textarea:hover,
    .bb-field.primary textarea:focus {
      border-color: #041620; }
    .bb-field.primary input:focus, .bb-field.primary input:active,
    .bb-field.primary select:focus,
    .bb-field.primary select:active,
    .bb-field.primary textarea:focus,
    .bb-field.primary textarea:active {
      border-color: #030f17; }
      .bb-field.primary input:focus + .field-addon, .bb-field.primary input:active + .field-addon,
      .bb-field.primary select:focus + .field-addon,
      .bb-field.primary select:active + .field-addon,
      .bb-field.primary textarea:focus + .field-addon,
      .bb-field.primary textarea:active + .field-addon {
        background: #0A344D;
        color: #FFFFFF;
        border-color: #041620; }
  .bb-field.primary .bb-select:hover:after {
    border-color: #0A344D; }
  .bb-field.secondary input,
  .bb-field.secondary select,
  .bb-field.secondary textarea {
    border-color: #FA7268; }
    .bb-field.secondary input:hover, .bb-field.secondary input:focus,
    .bb-field.secondary select:hover,
    .bb-field.secondary select:focus,
    .bb-field.secondary textarea:hover,
    .bb-field.secondary textarea:focus {
      border-color: #f84437; }
    .bb-field.secondary input:focus, .bb-field.secondary input:active,
    .bb-field.secondary select:focus,
    .bb-field.secondary select:active,
    .bb-field.secondary textarea:focus,
    .bb-field.secondary textarea:active {
      border-color: #f83b2d; }
      .bb-field.secondary input:focus + .field-addon, .bb-field.secondary input:active + .field-addon,
      .bb-field.secondary select:focus + .field-addon,
      .bb-field.secondary select:active + .field-addon,
      .bb-field.secondary textarea:focus + .field-addon,
      .bb-field.secondary textarea:active + .field-addon {
        background: #FA7268;
        color: #FFFFFF;
        border-color: #f84437; }
  .bb-field.secondary .bb-select:hover:after {
    border-color: #FA7268; }
  .bb-field.positive input,
  .bb-field.positive select,
  .bb-field.positive textarea {
    border-color: #50BF63; }
    .bb-field.positive input:hover, .bb-field.positive input:focus,
    .bb-field.positive select:hover,
    .bb-field.positive select:focus,
    .bb-field.positive textarea:hover,
    .bb-field.positive textarea:focus {
      border-color: #3ba14c; }
    .bb-field.positive input:focus, .bb-field.positive input:active,
    .bb-field.positive select:focus,
    .bb-field.positive select:active,
    .bb-field.positive textarea:focus,
    .bb-field.positive textarea:active {
      border-color: #389a49; }
      .bb-field.positive input:focus + .field-addon, .bb-field.positive input:active + .field-addon,
      .bb-field.positive select:focus + .field-addon,
      .bb-field.positive select:active + .field-addon,
      .bb-field.positive textarea:focus + .field-addon,
      .bb-field.positive textarea:active + .field-addon {
        background: #50BF63;
        color: #FFFFFF;
        border-color: #3ba14c; }
  .bb-field.positive .bb-select:hover:after {
    border-color: #50BF63; }
  .bb-field.negative input,
  .bb-field.negative select,
  .bb-field.negative textarea {
    border-color: #DC4C41; }
    .bb-field.negative input:hover, .bb-field.negative input:focus,
    .bb-field.negative select:hover,
    .bb-field.negative select:focus,
    .bb-field.negative textarea:hover,
    .bb-field.negative textarea:focus {
      border-color: #c63024; }
    .bb-field.negative input:focus, .bb-field.negative input:active,
    .bb-field.negative select:focus,
    .bb-field.negative select:active,
    .bb-field.negative textarea:focus,
    .bb-field.negative textarea:active {
      border-color: #bd2e23; }
      .bb-field.negative input:focus + .field-addon, .bb-field.negative input:active + .field-addon,
      .bb-field.negative select:focus + .field-addon,
      .bb-field.negative select:active + .field-addon,
      .bb-field.negative textarea:focus + .field-addon,
      .bb-field.negative textarea:active + .field-addon {
        background: #DC4C41;
        color: #FFFFFF;
        border-color: #c63024; }
  .bb-field.negative .bb-select:hover:after {
    border-color: #DC4C41; }
  .bb-field.success input,
  .bb-field.success select,
  .bb-field.success textarea,
  .bb-field.success .text {
    color: #50BF63;
    border-color: #50BF63; }
    .bb-field.success input::-webkit-input-placeholder,
    .bb-field.success select::-webkit-input-placeholder,
    .bb-field.success textarea::-webkit-input-placeholder,
    .bb-field.success .text::-webkit-input-placeholder {
      color: #50BF63; }
    .bb-field.success input::-moz-placeholder,
    .bb-field.success select::-moz-placeholder,
    .bb-field.success textarea::-moz-placeholder,
    .bb-field.success .text::-moz-placeholder {
      color: #50BF63; }
    .bb-field.success input::-ms-input-placeholder,
    .bb-field.success select::-ms-input-placeholder,
    .bb-field.success textarea::-ms-input-placeholder,
    .bb-field.success .text::-ms-input-placeholder {
      color: #50BF63; }
    .bb-field.success input::placeholder,
    .bb-field.success select::placeholder,
    .bb-field.success textarea::placeholder,
    .bb-field.success .text::placeholder {
      color: #50BF63; }
  .bb-field.error input,
  .bb-field.error select,
  .bb-field.error textarea,
  .bb-field.error .text {
    color: #DC4C41;
    border-color: #DC4C41; }
    .bb-field.error input::-webkit-input-placeholder,
    .bb-field.error select::-webkit-input-placeholder,
    .bb-field.error textarea::-webkit-input-placeholder,
    .bb-field.error .text::-webkit-input-placeholder {
      color: #DC4C41; }
    .bb-field.error input::-moz-placeholder,
    .bb-field.error select::-moz-placeholder,
    .bb-field.error textarea::-moz-placeholder,
    .bb-field.error .text::-moz-placeholder {
      color: #DC4C41; }
    .bb-field.error input::-ms-input-placeholder,
    .bb-field.error select::-ms-input-placeholder,
    .bb-field.error textarea::-ms-input-placeholder,
    .bb-field.error .text::-ms-input-placeholder {
      color: #DC4C41; }
    .bb-field.error input::placeholder,
    .bb-field.error select::placeholder,
    .bb-field.error textarea::placeholder,
    .bb-field.error .text::placeholder {
      color: #DC4C41; }
  .bb-field.disabled input,
  .bb-field.disabled select,
  .bb-field.disabled textarea, .bb-field:disabled input,
  .bb-field:disabled select,
  .bb-field:disabled textarea {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none; }
  .bb-field.disabled label, .bb-field:disabled label {
    cursor: not-allowed;
    pointer-events: none; }
  .bb-field.xs input,
  .bb-field.xs select,
  .bb-field.xs textarea,
  .bb-field.xs .field-addon {
    padding: 0rem 0rem;
    font-size: 0.5rem; }
  .bb-field.xs label {
    padding-bottom: 0rem;
    font-size: 0.5rem; }
  .bb-field.sm input,
  .bb-field.sm select,
  .bb-field.sm textarea,
  .bb-field.sm .field-addon {
    padding: 0.25rem 0.25rem;
    font-size: 0.75rem; }
  .bb-field.sm label {
    padding-bottom: 0.25rem;
    font-size: 0.75rem; }
  .bb-field.lg input,
  .bb-field.lg select,
  .bb-field.lg textarea,
  .bb-field.lg .field-addon {
    padding: 0.75rem 0.75rem;
    font-size: 1.25rem; }
  .bb-field.lg label {
    padding-bottom: 0.75rem;
    font-size: 1.25rem; }
  .bb-field.xl input,
  .bb-field.xl select,
  .bb-field.xl textarea,
  .bb-field.xl .field-addon {
    padding: 1rem 1rem;
    font-size: 1.5rem; }
  .bb-field.xl label {
    padding-bottom: 1rem;
    font-size: 1.5rem; }
  .bb-field:not(.xs):not(.sm):not(.lg):not(.xl) input,
  .bb-field:not(.xs):not(.sm):not(.lg):not(.xl) select,
  .bb-field:not(.xs):not(.sm):not(.lg):not(.xl) textarea {
    min-height: 2.625rem; }
  .bb-field input,
  .bb-field select,
  .bb-field textarea {
    position: relative;
    z-index: 1;
    display: block;
    flex: 1 1 auto;
    margin-bottom: 0;
    width: 100%;
    background: #FFFFFF;
    color: #030303;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0.125rem solid #E0E0E0;
    border-radius: 0.2rem;
    -webkit-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s; }
    .bb-field input:hover, .bb-field input:focus, .bb-field input:active,
    .bb-field select:hover,
    .bb-field select:focus,
    .bb-field select:active,
    .bb-field textarea:hover,
    .bb-field textarea:focus,
    .bb-field textarea:active {
      z-index: 3;
      outline: none;
      box-shadow: none;
      border-color: black; }
      .bb-field input:hover::-webkit-input-placeholder, .bb-field input:focus::-webkit-input-placeholder, .bb-field input:active::-webkit-input-placeholder,
      .bb-field select:hover::-webkit-input-placeholder,
      .bb-field select:focus::-webkit-input-placeholder,
      .bb-field select:active::-webkit-input-placeholder,
      .bb-field textarea:hover::-webkit-input-placeholder,
      .bb-field textarea:focus::-webkit-input-placeholder,
      .bb-field textarea:active::-webkit-input-placeholder {
        color: black; }
      .bb-field input:hover::-moz-placeholder, .bb-field input:focus::-moz-placeholder, .bb-field input:active::-moz-placeholder,
      .bb-field select:hover::-moz-placeholder,
      .bb-field select:focus::-moz-placeholder,
      .bb-field select:active::-moz-placeholder,
      .bb-field textarea:hover::-moz-placeholder,
      .bb-field textarea:focus::-moz-placeholder,
      .bb-field textarea:active::-moz-placeholder {
        color: black; }
      .bb-field input:hover::-ms-input-placeholder, .bb-field input:focus::-ms-input-placeholder, .bb-field input:active::-ms-input-placeholder,
      .bb-field select:hover::-ms-input-placeholder,
      .bb-field select:focus::-ms-input-placeholder,
      .bb-field select:active::-ms-input-placeholder,
      .bb-field textarea:hover::-ms-input-placeholder,
      .bb-field textarea:focus::-ms-input-placeholder,
      .bb-field textarea:active::-ms-input-placeholder {
        color: black; }
      .bb-field input:hover::placeholder, .bb-field input:focus::placeholder, .bb-field input:active::placeholder,
      .bb-field select:hover::placeholder,
      .bb-field select:focus::placeholder,
      .bb-field select:active::placeholder,
      .bb-field textarea:hover::placeholder,
      .bb-field textarea:focus::placeholder,
      .bb-field textarea:active::placeholder {
        color: black; }
    .bb-field input::-webkit-input-placeholder,
    .bb-field select::-webkit-input-placeholder,
    .bb-field textarea::-webkit-input-placeholder {
      color: #E0E0E0; }
    .bb-field input::-moz-placeholder,
    .bb-field select::-moz-placeholder,
    .bb-field textarea::-moz-placeholder {
      color: #E0E0E0; }
    .bb-field input::-ms-input-placeholder,
    .bb-field select::-ms-input-placeholder,
    .bb-field textarea::-ms-input-placeholder {
      color: #E0E0E0; }
    .bb-field input::placeholder,
    .bb-field select::placeholder,
    .bb-field textarea::placeholder {
      color: #E0E0E0; }
  .bb-field .bb-select {
    position: relative;
    display: inline-block;
    width: 100%; }
    .bb-field .bb-select:hover:after {
      border-color: #A0A0A0; }
    .bb-field .bb-select select {
      overflow-x: hidden;
      padding-right: calc( 0.5rem * 2 + 0.75rem); }
    .bb-field .bb-select:after {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      margin-top: calc(-0.5rem - -0.125rem);
      z-index: 5;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #A0A0A0;
      border-left: 0.125rem solid #A0A0A0; }
  .bb-field label {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    width: 100%;
    color: #030303;
    font-weight: normal;
    font-size: 1rem;
    font-family: "Ubuntu", "Helvetica", "Arial", sans-serif; }
  .bb-field .text {
    display: block;
    margin-top: 0.25rem;
    padding: 0;
    font-size: 0.75rem; }
  .bb-field .input-group {
    display: inline-flex;
    width: 100%; }

.form-inline {
  display: flex;
  justify-content: space-between; }
  .form-inline div:not(:last-child) {
    padding-right: 0.5rem; }

.bb-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2.25rem;
  cursor: pointer; }
  .bb-checkboxlabel {
    font-size: 0.75rem; }
  .bb-checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
    .bb-checkbox input:hover ~ .check, .bb-checkbox input:focus ~ .check, .bb-checkbox input:active ~ .check {
      border-color: black; }
    .bb-checkbox input:disabled:hover ~ .check, .bb-checkbox input:disabled:focus ~ .check, .bb-checkbox input:disabled:active ~ .check {
      border-color: black; }
    .bb-checkbox input:disabled:hover:checked ~ .check, .bb-checkbox input:disabled:focus:checked ~ .check, .bb-checkbox input:disabled:active:checked ~ .check {
      border-color: black; }
    .bb-checkbox input:disabled ~ .check {
      opacity: .6;
      pointer-events: none; }
    .bb-checkbox input:checked ~ .check {
      background: #FFFFFF; }
      .bb-checkbox input:checked ~ .check:after {
        display: block;
        z-index: 1;
        display: block;
        width: 0.5rem;
        height: 0.25rem;
        border-top: 0;
        border-right: 0;
        content: ' ';
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        pointer-events: none;
        border-bottom: 0.125rem solid #0A344D;
        border-left: 0.125rem solid #0A344D; }
  .bb-checkbox .check {
    position: absolute;
    top: calc(50% - 0.75rem);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.2rem;
    background: #FFFFFF;
    border: 0.125rem solid #E0E0E0;
    -webkit-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s; }
    .bb-checkbox .check:after {
      position: absolute;
      display: none;
      margin-top: -0.0625rem;
      content: '';
      z-index: 1;
      display: block;
      width: 0.5rem;
      height: 0.25rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #FFFFFF;
      border-left: 0.125rem solid #FFFFFF; }

.bb-field.left input,
.bb-field.left select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.bb-field.left .field-addon,
.bb-field.left .bb-btn {
  order: -1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.bb-field.left .bb-btn {
  border-right: 0; }

.bb-field.right input,
.bb-field.right select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.bb-field.right .field-addon,
.bb-field.right .bb-btn {
  order: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.bb-field.right .bb-btn {
  border-left: 0; }

.bb-field.left-right input,
.bb-field.left-right select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.bb-field.left-right .field-addon:first-child,
.bb-field.left-right .bb-btn:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.bb-field.left-right .field-addon:last-child,
.bb-field.left-right .bb-btn:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.bb-field.left-right .bb-btn:first-child {
  border-right: 0; }

.bb-field.left-right .bb-btn:last-child {
  border-left: 0; }

.bb-field .field-addon,
.bb-field .bb-field input,
.bb-field .bb-field select {
  display: flex;
  align-items: center; }
  .bb-field .field-addon:not(:first-child):not(:last-child),
  .bb-field .bb-field input:not(:first-child):not(:last-child),
  .bb-field .bb-field select:not(:first-child):not(:last-child) {
    border-radius: 0; }

.bb-field .field-addon {
  vertical-align: middle;
  white-space: nowrap; }

.bb-field .field-addon {
  margin-bottom: 0;
  padding: 0.5rem 0.5rem;
  background: black;
  color: #FFFFFF;
  text-align: center;
  line-height: 1.6;
  border: 0.125rem solid black;
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
  border-radius: 0.2rem; }
  .bb-field .field-addon.invert {
    background: #FFFFFF;
    color: #030303;
    border-color: #e6e6e6; }
  .bb-field .field-addon.primary {
    background: #0A344D;
    color: #FFFFFF;
    border-color: #041620; }
  .bb-field .field-addon.secondary {
    background: #FA7268;
    color: #FFFFFF;
    border-color: #f84437; }
  .bb-field .field-addon.positive {
    background: #50BF63;
    color: #FFFFFF;
    border-color: #3ba14c; }
  .bb-field .field-addon.negative {
    background: #DC4C41;
    color: #FFFFFF;
    border-color: #c63024; }
  .bb-field .field-addon input[type='radio'],
  .bb-field .field-addon input[type='checkbox'] {
    margin-top: 0; }

.bb-field .bb-btn {
  padding: 0.5rem 0.5rem; }

.disabled .field-addon,
.disabled .bb-btn {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none; }

.bb-footer {
  z-index: 10;
  padding: 3rem 1.5rem 6rem 1.5rem;
  width: 100%;
  box-shadow: 0 0 1.5rem 0 rgba(10, 10, 10, 0.1);
  background: #0A344D;
  color: #FFFFFF; }
  .bb-footer h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-footer h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-footer h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-footer h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-footer h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-footer p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
    color: #FFFFFF; }
  .bb-footer.invert {
    background: #FFFFFF;
    color: #030303; }
    .bb-footer.invert h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.invert h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.invert h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.invert h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.invert h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.invert p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #030303; }
  .bb-footer.primary {
    background: #0A344D;
    color: #FFFFFF; }
    .bb-footer.primary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.primary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.primary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.primary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.primary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.primary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
  .bb-footer.secondary {
    background: #FA7268;
    color: #FFFFFF; }
    .bb-footer.secondary h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.secondary h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.secondary h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.secondary h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.secondary h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.secondary p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
  .bb-footer.positive {
    background: #50BF63;
    color: #FFFFFF; }
    .bb-footer.positive h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.positive h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.positive h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.positive h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.positive h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.positive p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }
  .bb-footer.negative {
    background: #DC4C41;
    color: #FFFFFF; }
    .bb-footer.negative h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.negative h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.negative h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.negative h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.negative h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
    .bb-footer.negative p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
      color: #FFFFFF; }

h1,
h2,
h3,
h4,
h5 {
  margin: 0; }

h1 {
  font-size: 2.44141rem; }
  h1 {
    font-size: 2.44141rem; }
    @media (min-width: 28rem) {
      h1 {
        font-size: calc(2.44141rem + 2.62109 * ((100vw - 20rem) / 55)); } }
    @media (min-width: 75rem) {
      h1 {
        font-size: 5.0625rem; } }

h2 {
  font-size: 1.95312rem; }
  h2 {
    font-size: 1.95312rem; }
    @media (min-width: 28rem) {
      h2 {
        font-size: calc(1.95312rem + 1.42188 * ((100vw - 20rem) / 55)); } }
    @media (min-width: 75rem) {
      h2 {
        font-size: 3.375rem; } }

h3 {
  font-size: 1.5625rem; }
  h3 {
    font-size: 1.5625rem; }
    @media (min-width: 28rem) {
      h3 {
        font-size: calc(1.5625rem + 0.6875 * ((100vw - 20rem) / 55)); } }
    @media (min-width: 75rem) {
      h3 {
        font-size: 2.25rem; } }

h4 {
  font-size: 1.25rem; }
  h4 {
    font-size: 1.25rem; }
    @media (min-width: 28rem) {
      h4 {
        font-size: calc(1.25rem + 0.25 * ((100vw - 20rem) / 55)); } }
    @media (min-width: 75rem) {
      h4 {
        font-size: 1.5rem; } }

h5 {
  font-size: 1.25rem; }

.title {
  line-height: 1.6; }

.title:not(:last-child),
.subtitle:not(:last-child) {
  margin: 0 0 1.5rem 0; }

.title + .subtitle {
  margin: -1.5rem 0 0 0; }

.subtitle {
  font-size: 1.25rem; }

.media {
  display: flex;
  align-items: flex-start;
  text-align: left; }
  .media.center {
    align-items: center; }
  .media.bottom {
    align-items: flex-end; }
  .media .media-container {
    flex: 1 1; }
  .media .media {
    display: flex;
    margin-top: 0.875rem;
    padding-top: 1rem;
    border-top: 0.125rem solid #A0A0A0; }
  .media + .media {
    margin-top: 1rem; }
  .media .media-left,
  .media .media-right {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0; }
  .media .media-left {
    margin-right: 1rem; }
  .media .media-right {
    margin-left: 1rem; }
  .media .media-content:not(:last-child) {
    padding-bottom: 1rem; }
  .media p {
    margin: 0; }

.bb-navbar {
  position: relative;
  z-index: 6;
  min-height: 4rem;
  background: #FFFFFF; }
  @media (min-width: 57.5rem) {
    .bb-navbar,
    .bb-navbar .left,
    .bb-navbar .right {
      display: flex;
      align-items: stretch; } }
  .bb-navbar.fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100; }
    .bb-navbar.fixed + div,
    .bb-navbar.fixed + section {
      margin-top: 4rem; }
  .bb-navbar.shadow {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
    .bb-navbar.shadow .items.active {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .bb-navbar.bottom.fixed {
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-bottom: env(safe-area-inset-bottom); }
    .bb-navbar.bottom.fixed.shadow {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
      .bb-navbar.bottom.fixed.shadow .items.active {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .bb-navbar.bottom .link {
    flex-direction: column;
    display: flex;
    align-items: center;
    line-height: 1.6; }
    .bb-navbar.bottom .link span {
      font-size: 1rem; }
    .bb-navbar.bottom .link.active {
      color: rgba(10, 52, 77, 0.05); }
  .bb-navbar .brand {
    display: flex;
    align-items: stretch; }
    .bb-navbar .brand .item {
      display: flex;
      align-items: center;
      padding-left: 0.75rem; }
    .bb-navbar .brand img,
    .bb-navbar .brand .logo {
      max-height: 3rem;
      width: 4rem;
      height: 4rem; }
  .bb-navbar .items {
    display: none; }
    @media (min-width: 57.5rem) {
      .bb-navbar .items {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0; }
        .bb-navbar .items > .left {
          justify-content: flex-start;
          margin-right: auto; }
        .bb-navbar .items > .right {
          justify-content: flex-end;
          margin-left: auto; } }
    @media (max-width: 57.4375rem) {
      .bb-navbar .items.active {
        display: block; } }
    .bb-navbar .items.bb-dropdown.mega {
      position: static; }
  .bb-navbar a.item,
  .bb-navbar .link {
    text-decoration: none; }
    .bb-navbar a.item:hover, .bb-navbar a.item:focus,
    .bb-navbar .link:hover,
    .bb-navbar .link:focus {
      text-decoration: none; }
    .bb-navbar a.item:hover:not(.active), .bb-navbar a.item:focus:not(.active),
    .bb-navbar .link:hover:not(.active),
    .bb-navbar .link:focus:not(.active) {
      background: rgba(10, 52, 77, 0.05); }
  @media (min-width: 57.5rem) {
    .bb-navbar .link::after {
      margin-left: 0.75rem;
      margin-top: -0.125rem;
      z-index: 5;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-top: 0;
      border-right: 0;
      content: ' ';
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      pointer-events: none;
      border-bottom: 0.125rem solid #FA7268;
      border-left: 0.125rem solid #FA7268; } }
  .bb-navbar .item,
  .bb-navbar .link {
    position: relative;
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    color: #030303;
    font-weight: normal;
    font-size: 1rem;
    font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
    cursor: pointer; }
    @media (min-width: 57.5rem) {
      .bb-navbar .item,
      .bb-navbar .link {
        display: flex;
        align-items: center;
        height: 100%; } }
    .bb-navbar .item img,
    .bb-navbar .link img {
      max-height: 4rem; }
  .bb-navbar .toggle {
    align-self: center;
    margin-left: auto;
    padding: 0.5rem 0.75rem;
    position: relative;
    display: flex;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    cursor: pointer; }
    @media (min-width: 57.5rem) {
      .bb-navbar .toggle {
        display: none; } }
    .bb-navbar .toggle span {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      display: block;
      margin-right: auto;
      margin-left: auto;
      width: 1.5rem;
      height: 2px;
      background: #FA7268;
      -webkit-transition: none 2s ease-in-out;
      transition: none 2s ease-in-out;
      -webkit-transition-property: background, left, opacity, -webkit-transform;
      transition-property: background, left, opacity, -webkit-transform;
      transition-property: background, left, opacity, transform;
      transition-property: background, left, opacity, transform, -webkit-transform; }
      .bb-navbar .toggle span:nth-child(1) {
        margin-top: -.45rem; }
      .bb-navbar .toggle span:nth-child(2) {
        margin-top: 0; }
      .bb-navbar .toggle span:nth-child(3) {
        margin-top: .45rem; }

.bb-dropdown.item {
  padding: 0; }

.bb-dropdown .dropdown-menu {
  position: relative;
  padding: 0.5rem 0;
  border-top: none;
  box-shadow: none;
  color: #FFFFFF;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  -webkit-transition: color 0s, opacity ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s;
  transition: color 0s, opacity ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s;
  transition: color 0s, opacity ease-in-out 0.25s, transform ease-in-out 0.25s;
  transition: color 0s, opacity ease-in-out 0.25s, transform ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s; }
  @media (min-width: 57.5rem) {
    .bb-dropdown .dropdown-menu {
      position: absolute;
      top: calc(100% + (-0.125rem));
      left: 0;
      z-index: 20;
      display: block;
      padding: 0.5rem 0;
      min-width: 100%;
      border-top: 0.125rem solid transparent;
      background: #FFFFFF;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      opacity: 0;
      -webkit-transform: translateY(-0.125rem);
              transform: translateY(-0.125rem);
      pointer-events: none;
      border-bottom-right-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
      -webkit-transition: color 0s, opacity ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s;
      transition: color 0s, opacity ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s;
      transition: color 0s, opacity ease-in-out 0.25s, transform ease-in-out 0.25s;
      transition: color 0s, opacity ease-in-out 0.25s, transform ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s; }
      .bb-dropdown .dropdown-menu.static {
        top: 100%; } }

.bb-pagination {
  display: inline-flex;
  margin: 0;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bb-pagination .item,
  .bb-pagination .dot {
    background: #030303;
    color: #FFFFFF;
    border-color: black; }
    .bb-pagination .item:hover, .bb-pagination .item:focus,
    .bb-pagination .dot:hover,
    .bb-pagination .dot:focus {
      background: black;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination .item:active, .bb-pagination .item.active,
    .bb-pagination .dot:active,
    .bb-pagination .dot.active {
      border-color: black;
      background: black;
      color: #FFFFFF; }
  .bb-pagination .item {
    padding: 0.5rem 0.75rem;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    -webkit-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s; }
    .bb-pagination .item.disabled, .bb-pagination .item:disabled {
      cursor: not-allowed;
      pointer-events: none; }
  .bb-pagination:not(.connected) .item {
    border: 0.125rem solid black;
    border-radius: 0.2rem; }
    .bb-pagination:not(.connected) .item:not(:last-child) {
      margin-right: 0.375rem; }
  .bb-pagination.connected .item {
    border-left: 0.125rem solid black;
    border-bottom: 0.125rem solid black;
    border-top: 0.125rem solid black;
    min-width: 3rem;
    font-size: 1rem; }
    .bb-pagination.connected .item:first-child {
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem; }
    .bb-pagination.connected .item:last-child {
      border-right-width: 0.125rem;
      border-right-style: solid;
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem; }
  .bb-pagination .dot {
    padding: 0.3rem;
    color: transparent;
    text-decoration: none;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    border: 0.125rem solid black;
    -webkit-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s;
    border-radius: 50%; }
    .bb-pagination .dot:not(:first-child), .bb-pagination .dot:not(:last-child) {
      margin: 0 0.1rem; }
  .bb-pagination.no-border .item {
    border: 0 !important; }
  .bb-pagination.invert .item,
  .bb-pagination.invert .dot {
    background: #FFFFFF;
    color: #030303;
    border-color: #e6e6e6; }
    .bb-pagination.invert .item:hover, .bb-pagination.invert .item:focus,
    .bb-pagination.invert .dot:hover,
    .bb-pagination.invert .dot:focus {
      background: #e6e6e6;
      color: #030303;
      text-decoration: none; }
    .bb-pagination.invert .item:active, .bb-pagination.invert .item.active,
    .bb-pagination.invert .dot:active,
    .bb-pagination.invert .dot.active {
      border-color: #e0e0e0;
      background: #e0e0e0;
      color: #030303; }
  .bb-pagination.primary .item,
  .bb-pagination.primary .dot {
    background: #0A344D;
    color: #FFFFFF;
    border-color: #041620; }
    .bb-pagination.primary .item:hover, .bb-pagination.primary .item:focus,
    .bb-pagination.primary .dot:hover,
    .bb-pagination.primary .dot:focus {
      background: #041620;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination.primary .item:active, .bb-pagination.primary .item.active,
    .bb-pagination.primary .dot:active,
    .bb-pagination.primary .dot.active {
      border-color: #030f17;
      background: #030f17;
      color: #FFFFFF; }
  .bb-pagination.secondary .item,
  .bb-pagination.secondary .dot {
    background: #FA7268;
    color: #FFFFFF;
    border-color: #f84437; }
    .bb-pagination.secondary .item:hover, .bb-pagination.secondary .item:focus,
    .bb-pagination.secondary .dot:hover,
    .bb-pagination.secondary .dot:focus {
      background: #f84437;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination.secondary .item:active, .bb-pagination.secondary .item.active,
    .bb-pagination.secondary .dot:active,
    .bb-pagination.secondary .dot.active {
      border-color: #f83b2d;
      background: #f83b2d;
      color: #FFFFFF; }
  .bb-pagination.positive .item,
  .bb-pagination.positive .dot {
    background: #50BF63;
    color: #FFFFFF;
    border-color: #3ba14c; }
    .bb-pagination.positive .item:hover, .bb-pagination.positive .item:focus,
    .bb-pagination.positive .dot:hover,
    .bb-pagination.positive .dot:focus {
      background: #3ba14c;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination.positive .item:active, .bb-pagination.positive .item.active,
    .bb-pagination.positive .dot:active,
    .bb-pagination.positive .dot.active {
      border-color: #389a49;
      background: #389a49;
      color: #FFFFFF; }
  .bb-pagination.negative .item,
  .bb-pagination.negative .dot {
    background: #DC4C41;
    color: #FFFFFF;
    border-color: #c63024; }
    .bb-pagination.negative .item:hover, .bb-pagination.negative .item:focus,
    .bb-pagination.negative .dot:hover,
    .bb-pagination.negative .dot:focus {
      background: #c63024;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination.negative .item:active, .bb-pagination.negative .item.active,
    .bb-pagination.negative .dot:active,
    .bb-pagination.negative .dot.active {
      border-color: #bd2e23;
      background: #bd2e23;
      color: #FFFFFF; }
  .bb-pagination.outline .item,
  .bb-pagination.outline .dot {
    background: #FFFFFF;
    color: #030303;
    border-color: black; }
    .bb-pagination.outline .item:hover, .bb-pagination.outline .item:focus,
    .bb-pagination.outline .dot:hover,
    .bb-pagination.outline .dot:focus {
      background: black;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination.outline .item:active, .bb-pagination.outline .item.active,
    .bb-pagination.outline .dot:active,
    .bb-pagination.outline .dot.active {
      border-color: black;
      background: black;
      color: #FFFFFF; }
  .bb-pagination.outline.invert .item,
  .bb-pagination.outline.invert .dot {
    background: #030303;
    color: #FFFFFF;
    border-color: #e6e6e6; }
    .bb-pagination.outline.invert .item:hover, .bb-pagination.outline.invert .item:focus,
    .bb-pagination.outline.invert .dot:hover,
    .bb-pagination.outline.invert .dot:focus {
      background: #e6e6e6;
      color: #030303;
      text-decoration: none; }
    .bb-pagination.outline.invert .item:active, .bb-pagination.outline.invert .item.active,
    .bb-pagination.outline.invert .dot:active,
    .bb-pagination.outline.invert .dot.active {
      border-color: #e0e0e0;
      background: #e0e0e0;
      color: #030303; }
  .bb-pagination.outline.primary .item,
  .bb-pagination.outline.primary .dot {
    background: #FFFFFF;
    color: #0A344D;
    border-color: #041620; }
    .bb-pagination.outline.primary .item:hover, .bb-pagination.outline.primary .item:focus,
    .bb-pagination.outline.primary .dot:hover,
    .bb-pagination.outline.primary .dot:focus {
      background: #041620;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination.outline.primary .item:active, .bb-pagination.outline.primary .item.active,
    .bb-pagination.outline.primary .dot:active,
    .bb-pagination.outline.primary .dot.active {
      border-color: #030f17;
      background: #030f17;
      color: #FFFFFF; }
  .bb-pagination.outline.secondary .item,
  .bb-pagination.outline.secondary .dot {
    background: #FFFFFF;
    color: #FA7268;
    border-color: #f84437; }
    .bb-pagination.outline.secondary .item:hover, .bb-pagination.outline.secondary .item:focus,
    .bb-pagination.outline.secondary .dot:hover,
    .bb-pagination.outline.secondary .dot:focus {
      background: #f84437;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination.outline.secondary .item:active, .bb-pagination.outline.secondary .item.active,
    .bb-pagination.outline.secondary .dot:active,
    .bb-pagination.outline.secondary .dot.active {
      border-color: #f83b2d;
      background: #f83b2d;
      color: #FFFFFF; }
  .bb-pagination.outline.positive .item,
  .bb-pagination.outline.positive .dot {
    background: #FFFFFF;
    color: #50BF63;
    border-color: #3ba14c; }
    .bb-pagination.outline.positive .item:hover, .bb-pagination.outline.positive .item:focus,
    .bb-pagination.outline.positive .dot:hover,
    .bb-pagination.outline.positive .dot:focus {
      background: #3ba14c;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination.outline.positive .item:active, .bb-pagination.outline.positive .item.active,
    .bb-pagination.outline.positive .dot:active,
    .bb-pagination.outline.positive .dot.active {
      border-color: #389a49;
      background: #389a49;
      color: #FFFFFF; }
  .bb-pagination.outline.negative .item,
  .bb-pagination.outline.negative .dot {
    background: #FFFFFF;
    color: #DC4C41;
    border-color: #c63024; }
    .bb-pagination.outline.negative .item:hover, .bb-pagination.outline.negative .item:focus,
    .bb-pagination.outline.negative .dot:hover,
    .bb-pagination.outline.negative .dot:focus {
      background: #c63024;
      color: #FFFFFF;
      text-decoration: none; }
    .bb-pagination.outline.negative .item:active, .bb-pagination.outline.negative .item.active,
    .bb-pagination.outline.negative .dot:active,
    .bb-pagination.outline.negative .dot.active {
      border-color: #bd2e23;
      background: #bd2e23;
      color: #FFFFFF; }
  .bb-pagination.xs .item {
    min-width: 1rem;
    font-size: 0.5rem; }
  .bb-pagination.sm .item {
    min-width: 2rem;
    font-size: 0.75rem; }
  .bb-pagination.lg .item {
    min-width: 4rem;
    font-size: 1.25rem; }
  .bb-pagination.xl .item {
    min-width: 5rem;
    font-size: 1.5rem; }

.bb-stat {
  display: inline-flex;
  flex-direction: column; }
  .bb-stat .value {
    display: flex;
    justify-content: center;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 3rem;
    font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
    line-height: 1em; }
    .bb-stat .value.text {
      min-height: 2rem;
      text-align: center;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1em; }
  .bb-stat .label {
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75rem;
    font-family: "Inter", "Helvetica", "Arial", sans-serif; }
  .bb-stat.horizontal {
    align-items: center;
    flex-direction: row;
    margin: 1rem 0; }
    .bb-stat.horizontal :last-child {
      margin-left: 0.66667rem; }
  .bb-stat.invert .value {
    color: #FFFFFF; }
  .bb-stat.primary .value {
    color: #0A344D; }
  .bb-stat.secondary .value {
    color: #FA7268; }
  .bb-stat.positive .value {
    color: #50BF63; }
  .bb-stat.negative .value {
    color: #DC4C41; }
  .bb-stat.xs .value {
    font-size: 2rem; }
    .bb-stat.xs .value.text {
      font-size: 1rem; }
  .bb-stat.xs .label {
    font-size: 0.5rem; }
  .bb-stat.sm .value {
    font-size: 2.5rem; }
    .bb-stat.sm .value.text {
      font-size: 1.25rem; }
  .bb-stat.sm .label {
    font-size: 0.625rem; }
  .bb-stat.lg .value {
    font-size: 3.5rem; }
    .bb-stat.lg .value.text {
      font-size: 1.75rem; }
  .bb-stat.lg .label {
    font-size: 0.875rem; }
  .bb-stat.xl .value {
    font-size: 4rem; }
    .bb-stat.xl .value.text {
      font-size: 2rem; }
  .bb-stat.xl .label {
    font-size: 1rem; }

.bb-stat {
  display: inline-flex;
  flex-direction: column; }
  .bb-stat .value {
    display: flex;
    justify-content: center;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 3rem;
    font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
    line-height: 1em; }
    .bb-stat .value.text {
      min-height: 2rem;
      text-align: center;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1em; }
  .bb-stat .label {
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75rem;
    font-family: "Inter", "Helvetica", "Arial", sans-serif; }
  .bb-stat.horizontal {
    align-items: center;
    flex-direction: row;
    margin: 1rem 0; }
    .bb-stat.horizontal :last-child {
      margin-left: 0.66667rem; }
  .bb-stat.invert .value {
    color: #FFFFFF; }
  .bb-stat.primary .value {
    color: #0A344D; }
  .bb-stat.secondary .value {
    color: #FA7268; }
  .bb-stat.positive .value {
    color: #50BF63; }
  .bb-stat.negative .value {
    color: #DC4C41; }
  .bb-stat.xs .value {
    font-size: 2rem; }
    .bb-stat.xs .value.text {
      font-size: 1rem; }
  .bb-stat.xs .label {
    font-size: 0.5rem; }
  .bb-stat.sm .value {
    font-size: 2.5rem; }
    .bb-stat.sm .value.text {
      font-size: 1.25rem; }
  .bb-stat.sm .label {
    font-size: 0.625rem; }
  .bb-stat.lg .value {
    font-size: 3.5rem; }
    .bb-stat.lg .value.text {
      font-size: 1.75rem; }
  .bb-stat.lg .label {
    font-size: 0.875rem; }
  .bb-stat.xl .value {
    font-size: 4rem; }
    .bb-stat.xl .value.text {
      font-size: 2rem; }
  .bb-stat.xl .label {
    font-size: 1rem; }

.bb-stats {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 1rem -1rem; }
  .bb-stats .bb-stat {
    margin: 1rem 1rem; }
  .bb-stats.vertical {
    align-items: center;
    flex-direction: column;
    margin: 1rem -1rem;
    max-width: none; }

.bb-tab {
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  justify-content: space-between;
  font-size: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bb-tab.invert ul li.active a {
    color: #FFFFFF;
    border-bottom: 0.125rem solid #FFFFFF; }
    .bb-tab.invert ul li.active a:hover {
      border-bottom-color: #FFFFFF; }
  .bb-tab.invert ul li .item {
    border-bottom-color: rgba(255, 255, 255, 0.2); }
    .bb-tab.invert ul li .item:hover {
      color: #FFFFFF;
      border-bottom-color: #FFFFFF; }
  .bb-tab.primary ul li.active a {
    color: #0A344D;
    border-bottom: 0.125rem solid #0A344D; }
    .bb-tab.primary ul li.active a:hover {
      border-bottom-color: #0A344D; }
  .bb-tab.primary ul li .item {
    border-bottom-color: rgba(10, 52, 77, 0.2); }
    .bb-tab.primary ul li .item:hover {
      color: #0A344D;
      border-bottom-color: #0A344D; }
  .bb-tab.secondary ul li.active a {
    color: #FA7268;
    border-bottom: 0.125rem solid #FA7268; }
    .bb-tab.secondary ul li.active a:hover {
      border-bottom-color: #FA7268; }
  .bb-tab.secondary ul li .item {
    border-bottom-color: rgba(250, 114, 104, 0.2); }
    .bb-tab.secondary ul li .item:hover {
      color: #FA7268;
      border-bottom-color: #FA7268; }
  .bb-tab.positive ul li.active a {
    color: #50BF63;
    border-bottom: 0.125rem solid #50BF63; }
    .bb-tab.positive ul li.active a:hover {
      border-bottom-color: #50BF63; }
  .bb-tab.positive ul li .item {
    border-bottom-color: rgba(80, 191, 99, 0.2); }
    .bb-tab.positive ul li .item:hover {
      color: #50BF63;
      border-bottom-color: #50BF63; }
  .bb-tab.negative ul li.active a {
    color: #DC4C41;
    border-bottom: 0.125rem solid #DC4C41; }
    .bb-tab.negative ul li.active a:hover {
      border-bottom-color: #DC4C41; }
  .bb-tab.negative ul li .item {
    border-bottom-color: rgba(220, 76, 65, 0.2); }
    .bb-tab.negative ul li .item:hover {
      color: #DC4C41;
      border-bottom-color: #DC4C41; }
  .bb-tab.xs {
    font-size: 0.5rem; }
  .bb-tab.sm {
    font-size: 0.75rem; }
  .bb-tab.lg {
    font-size: 1.25rem; }
  .bb-tab.xl {
    font-size: 1.5rem; }
  .bb-tab ul {
    display: flex;
    padding: 0; }
    .bb-tab ul li {
      display: block;
      padding: 0; }
      .bb-tab ul li.active a {
        color: rgba(3, 3, 3, 0.2);
        border-bottom: 0.125rem solid rgba(3, 3, 3, 0.2); }
        .bb-tab ul li.active a:hover {
          color: rgba(3, 3, 3, 0.2);
          border-bottom-color: rgba(3, 3, 3, 0.2); }
      .bb-tab ul li .item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem 1rem;
        color: #030303;
        cursor: pointer;
        border-bottom: 0.125rem solid rgba(3, 3, 3, 0.2);
        -webkit-transition: all ease-in-out 0.25s;
        transition: all ease-in-out 0.25s; }
        .bb-tab ul li .item:hover {
          color: rgba(3, 3, 3, 0.2);
          text-decoration: none;
          border-bottom-color: rgba(3, 3, 3, 0.2); }
  .bb-tab.center ul {
    flex-grow: 1;
    justify-content: center; }
  .bb-tab.right ul {
    flex-grow: 1;
    justify-content: flex-end; }
  .bb-tab.full-x ul {
    flex-grow: 1; }
    .bb-tab.full-x ul li {
      flex-grow: 1;
      flex-shrink: 0; }
  .bb-tab.naked.invert ul {
    border-bottom-color: transparent; }
    .bb-tab.naked.invert ul li.active a {
      border-bottom-color: #FFFFFF; }
    .bb-tab.naked.invert ul li .item {
      border-bottom-color: transparent; }
      .bb-tab.naked.invert ul li .item:hover {
        border-bottom-color: white; }
  .bb-tab.naked.primary ul {
    border-bottom-color: transparent; }
    .bb-tab.naked.primary ul li.active a {
      border-bottom-color: #0A344D; }
    .bb-tab.naked.primary ul li .item {
      border-bottom-color: transparent; }
      .bb-tab.naked.primary ul li .item:hover {
        border-bottom-color: #0a344d; }
  .bb-tab.naked.secondary ul {
    border-bottom-color: transparent; }
    .bb-tab.naked.secondary ul li.active a {
      border-bottom-color: #FA7268; }
    .bb-tab.naked.secondary ul li .item {
      border-bottom-color: transparent; }
      .bb-tab.naked.secondary ul li .item:hover {
        border-bottom-color: #fa7268; }
  .bb-tab.naked.positive ul {
    border-bottom-color: transparent; }
    .bb-tab.naked.positive ul li.active a {
      border-bottom-color: #50BF63; }
    .bb-tab.naked.positive ul li .item {
      border-bottom-color: transparent; }
      .bb-tab.naked.positive ul li .item:hover {
        border-bottom-color: #50bf63; }
  .bb-tab.naked.negative ul {
    border-bottom-color: transparent; }
    .bb-tab.naked.negative ul li.active a {
      border-bottom-color: #DC4C41; }
    .bb-tab.naked.negative ul li .item {
      border-bottom-color: transparent; }
      .bb-tab.naked.negative ul li .item:hover {
        border-bottom-color: #dc4c41; }
  .bb-tab.naked ul {
    border-bottom-color: transparent; }
    .bb-tab.naked ul li.active a {
      border-bottom-color: inherit; }
    .bb-tab.naked ul li .item {
      border-bottom-color: transparent; }
      .bb-tab.naked ul li .item:hover {
        border-bottom-color: inherit; }

.bb-table {
  background: #fafafa;
  color: #030303;
  border-color: #dbdbdb;
  border-radius: 0.4rem; }
  .bb-table.full-x {
    width: 100%; }
  .bb-table.no-border-radius {
    border-radius: 0rem; }
    .bb-table.no-border-radius * {
      border-radius: 0 !important; }
  .bb-table.naked tr:not(:last-child) {
    border: none; }
  .bb-table tr:first-child {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem; }
    .bb-table tr:first-child td:first-child {
      border-top-left-radius: 0.4rem; }
    .bb-table tr:first-child td:last-child {
      border-top-right-radius: 0.4rem; }
  .bb-table tr:last-child {
    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem; }
    .bb-table tr:last-child td:first-child {
      border-bottom-left-radius: 0.4rem; }
    .bb-table tr:last-child td:last-child {
      border-bottom-right-radius: 0.4rem; }
  .bb-table tr:not(:last-child) {
    border-bottom: 0.0625rem solid #dbdbdb; }
  .bb-table td,
  .bb-table th {
    padding: 0.75rem 0.75rem 0.75rem 0.75rem; }
  .bb-table th {
    text-align: left; }
  .bb-table.invert,
  .bb-table .invert {
    background: rgba(255, 255, 255, 0.2); }
    .bb-table.invert tr,
    .bb-table .invert tr {
      border-color: #e6e6e6; }
  .bb-table.primary,
  .bb-table .primary {
    background: rgba(10, 52, 77, 0.2); }
    .bb-table.primary tr,
    .bb-table .primary tr {
      border-color: #041620; }
  .bb-table.secondary,
  .bb-table .secondary {
    background: rgba(250, 114, 104, 0.2); }
    .bb-table.secondary tr,
    .bb-table .secondary tr {
      border-color: #f84437; }
  .bb-table.positive,
  .bb-table .positive {
    background: rgba(80, 191, 99, 0.2); }
    .bb-table.positive tr,
    .bb-table .positive tr {
      border-color: #3ba14c; }
  .bb-table.negative,
  .bb-table .negative {
    background: rgba(220, 76, 65, 0.2); }
    .bb-table.negative tr,
    .bb-table .negative tr {
      border-color: #c63024; }
  .bb-table.success,
  .bb-table .success {
    background: rgba(80, 191, 99, 0.2); }
    .bb-table.success tr,
    .bb-table .success tr {
      border-color: #3ba14c; }
  .bb-table.error,
  .bb-table .error {
    background: rgba(220, 76, 65, 0.2); }
    .bb-table.error tr,
    .bb-table .error tr {
      border-color: #c63024; }
  .bb-table.striped tr:nth-child(even) {
    background: #FFFFFF;
    color: #030303; }
  .bb-table.striped.invert tr:nth-child(even) {
    background: #FFFFFF;
    color: #FFFFFF; }
  .bb-table.striped.primary tr:nth-child(even) {
    background: #FFFFFF;
    color: #0A344D; }
  .bb-table.striped.secondary tr:nth-child(even) {
    background: #FFFFFF;
    color: #FA7268; }
  .bb-table.striped.positive tr:nth-child(even) {
    background: #FFFFFF;
    color: #50BF63; }
  .bb-table.striped.negative tr:nth-child(even) {
    background: #FFFFFF;
    color: #DC4C41; }
  .bb-table.striped.success tr:nth-child(even) {
    background: #FFFFFF;
    color: #50BF63; }
  .bb-table.striped.error tr:nth-child(even) {
    background: #FFFFFF;
    color: #DC4C41; }
  .bb-table.hover {
    cursor: pointer; }
    .bb-table.hover td:hover {
      background: #dbdbdb;
      color: #030303; }
    .bb-table.hover.invert td:hover,
    .bb-table.hover tr.invert td:hover {
      background: #FFFFFF;
      color: #030303; }
    .bb-table.hover.primary td:hover,
    .bb-table.hover tr.primary td:hover {
      background: #0A344D;
      color: #FFFFFF; }
    .bb-table.hover.secondary td:hover,
    .bb-table.hover tr.secondary td:hover {
      background: #FA7268;
      color: #FFFFFF; }
    .bb-table.hover.positive td:hover,
    .bb-table.hover tr.positive td:hover {
      background: #50BF63;
      color: #FFFFFF; }
    .bb-table.hover.negative td:hover,
    .bb-table.hover tr.negative td:hover {
      background: #DC4C41;
      color: #FFFFFF; }
    .bb-table.hover.success td:hover,
    .bb-table.hover tr.success td:hover {
      background: #50BF63;
      color: #FFFFFF; }
    .bb-table.hover.error td:hover,
    .bb-table.hover tr.error td:hover {
      background: #DC4C41;
      color: #FFFFFF; }
    .bb-table.hover td.invert:hover,
    .bb-table.hover th.invert:hover {
      background: #FFFFFF;
      color: #030303; }
    .bb-table.hover td.primary:hover,
    .bb-table.hover th.primary:hover {
      background: #0A344D;
      color: #FFFFFF; }
    .bb-table.hover td.secondary:hover,
    .bb-table.hover th.secondary:hover {
      background: #FA7268;
      color: #FFFFFF; }
    .bb-table.hover td.positive:hover,
    .bb-table.hover th.positive:hover {
      background: #50BF63;
      color: #FFFFFF; }
    .bb-table.hover td.negative:hover,
    .bb-table.hover th.negative:hover {
      background: #DC4C41;
      color: #FFFFFF; }
    .bb-table.hover td.success:hover,
    .bb-table.hover th.success:hover {
      background: #50BF63;
      color: #FFFFFF; }
    .bb-table.hover td.error:hover,
    .bb-table.hover th.error:hover {
      background: #DC4C41;
      color: #FFFFFF; }
  .bb-table .selected {
    background: #dbdbdb;
    color: #030303; }
    .bb-table .selected.invert,
    .bb-table .selected .invert {
      background: #FFFFFF;
      color: #030303; }
    .bb-table .selected.primary,
    .bb-table .selected .primary {
      background: #0A344D;
      color: #FFFFFF; }
    .bb-table .selected.secondary,
    .bb-table .selected .secondary {
      background: #FA7268;
      color: #FFFFFF; }
    .bb-table .selected.positive,
    .bb-table .selected .positive {
      background: #50BF63;
      color: #FFFFFF; }
    .bb-table .selected.negative,
    .bb-table .selected .negative {
      background: #DC4C41;
      color: #FFFFFF; }
    .bb-table .selected.success,
    .bb-table .selected .success {
      background: #50BF63;
      color: #FFFFFF; }
    .bb-table .selected.error,
    .bb-table .selected .error {
      background: #DC4C41;
      color: #FFFFFF; }

.d-none {
  display: none; }

.d-block {
  display: block; }

.d-inline-block {
  display: inline-block; }

.d-flex {
  display: flex; }

.d-inline-flex {
  display: inline-flex; }

.d-inline {
  display: inline; }

.d-table {
  display: table; }

.d-table-cell {
  display: table-cell; }

.flex-1 {
  flex: 1 1; }

.flex-first {
  order: -1; }

.flex-last {
  order: 1; }

.flex-unordered {
  order: 0; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-no-wrap {
  flex-wrap: nowrap; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.flex-row {
  flex-direction: row; }

.flex-column {
  flex-direction: column; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.justify-content-start {
  justify-content: flex-start; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-center {
  justify-content: center; }

.justify-content-around {
  justify-content: space-around; }

.justify-content-between {
  justify-content: space-between; }

.align-items-start {
  align-items: flex-start; }

.align-items-end {
  align-items: flex-end; }

.align-items-center {
  align-items: center; }

.align-items-baseline {
  align-items: baseline; }

.align-items-stretch {
  align-items: stretch; }

.align-content-start {
  align-content: flex-start; }

.align-content-end {
  align-content: flex-end; }

.align-content-center {
  align-content: center; }

.align-content-around {
  align-content: space-around; }

.align-content-between {
  align-content: space-between; }

.align-content-stretch {
  align-content: stretch; }

.align-self-auto {
  align-self: auto; }

.align-self-start {
  align-self: flex-start; }

.align-self-end {
  align-self: flex-end; }

.align-self-center {
  align-self: center; }

.align-self-baseline {
  align-self: baseline; }

.align-self-stretch {
  align-self: stretch; }

ul.unstyled {
  padding-left: 0;
  list-style: none; }

ul.inline {
  padding-left: 0;
  list-style: none; }
  ul.inline .inline-item {
    display: inline-block; }
    ul.inline .inline-item:not(:first-child) {
      padding-left: 1rem; }

ul.xs,
ul .xs {
  font-size: 0.5rem; }

ul.sm,
ul .sm {
  font-size: 0.75rem; }

ul.lg,
ul .lg {
  font-size: 1.25rem; }

ul.xl,
ul .xl {
  font-size: 1.5rem; }

ul li {
  padding-bottom: 0.5rem; }

ul.icon {
  list-style: none; }
  ul.icon.xs,
  ul.icon .xs {
    font-size: 0.5rem; }
  ul.icon.sm,
  ul.icon .sm {
    font-size: 0.75rem; }
  ul.icon.lg,
  ul.icon .lg {
    font-size: 1.25rem; }
  ul.icon.xl,
  ul.icon .xl {
    font-size: 1.5rem; }
  ul.icon li i {
    margin-left: -1.5rem;
    padding-right: 0.5rem; }

.p-absolute {
  position: absolute; }

.p-fixed {
  position: fixed; }

.p-relative {
  position: relative; }

.p-static {
  position: static; }

.p-sticky {
  position: -webkit-sticky;
  position: sticky; }

.scroll {
  overflow-x: scroll; }

.full-x {
  width: 100%; }

.full-y {
  height: 100%; }

.m-0 {
  margin: 0 0; }

.m-t-0 {
  margin-top: 0; }

.m-r-0 {
  margin-right: 0; }

.m-b-0 {
  margin-bottom: 0; }

.m-l-0 {
  margin-left: 0; }

.m-x-0 {
  margin-right: 0;
  margin-left: 0; }

.m-y-0 {
  margin-top: 0;
  margin-bottom: 0; }

.m-1 {
  margin: 0.25rem 0.25rem; }

.m-t-1 {
  margin-top: 0.25rem; }

.m-r-1 {
  margin-right: 0.25rem; }

.m-b-1 {
  margin-bottom: 0.25rem; }

.m-l-1 {
  margin-left: 0.25rem; }

.m-x-1 {
  margin-right: 0.25rem;
  margin-left: 0.25rem; }

.m-y-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.m-2 {
  margin: 0.5rem 0.5rem; }

.m-t-2 {
  margin-top: 0.5rem; }

.m-r-2 {
  margin-right: 0.5rem; }

.m-b-2 {
  margin-bottom: 0.5rem; }

.m-l-2 {
  margin-left: 0.5rem; }

.m-x-2 {
  margin-right: 0.5rem;
  margin-left: 0.5rem; }

.m-y-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.m-3 {
  margin: 1rem 1rem; }

.m-t-3 {
  margin-top: 1rem; }

.m-r-3 {
  margin-right: 1rem; }

.m-b-3 {
  margin-bottom: 1rem; }

.m-l-3 {
  margin-left: 1rem; }

.m-x-3 {
  margin-right: 1rem;
  margin-left: 1rem; }

.m-y-3 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.m-4 {
  margin: 1.5rem 1.5rem; }

.m-t-4 {
  margin-top: 1.5rem; }

.m-r-4 {
  margin-right: 1.5rem; }

.m-b-4 {
  margin-bottom: 1.5rem; }

.m-l-4 {
  margin-left: 1.5rem; }

.m-x-4 {
  margin-right: 1.5rem;
  margin-left: 1.5rem; }

.m-y-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.m-5 {
  margin: 2rem 2rem; }

.m-t-5 {
  margin-top: 2rem; }

.m-r-5 {
  margin-right: 2rem; }

.m-b-5 {
  margin-bottom: 2rem; }

.m-l-5 {
  margin-left: 2rem; }

.m-x-5 {
  margin-right: 2rem;
  margin-left: 2rem; }

.m-y-5 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.p-0 {
  padding: 0 0; }

.p-t-0 {
  padding-top: 0; }

.p-r-0 {
  padding-right: 0; }

.p-b-0 {
  padding-bottom: 0; }

.p-l-0 {
  padding-left: 0; }

.p-x-0 {
  padding-right: 0;
  padding-left: 0; }

.p-y-0 {
  padding-top: 0;
  padding-bottom: 0; }

.p-1 {
  padding: 0.25rem 0.25rem; }

.p-t-1 {
  padding-top: 0.25rem; }

.p-r-1 {
  padding-right: 0.25rem; }

.p-b-1 {
  padding-bottom: 0.25rem; }

.p-l-1 {
  padding-left: 0.25rem; }

.p-x-1 {
  padding-right: 0.25rem;
  padding-left: 0.25rem; }

.p-y-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.p-2 {
  padding: 0.5rem 0.5rem; }

.p-t-2 {
  padding-top: 0.5rem; }

.p-r-2 {
  padding-right: 0.5rem; }

.p-b-2 {
  padding-bottom: 0.5rem; }

.p-l-2 {
  padding-left: 0.5rem; }

.p-x-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.p-y-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.p-3 {
  padding: 1rem 1rem; }

.p-t-3 {
  padding-top: 1rem; }

.p-r-3 {
  padding-right: 1rem; }

.p-b-3 {
  padding-bottom: 1rem; }

.p-l-3 {
  padding-left: 1rem; }

.p-x-3 {
  padding-right: 1rem;
  padding-left: 1rem; }

.p-y-3 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.p-4 {
  padding: 1.5rem 1.5rem; }

.p-t-4 {
  padding-top: 1.5rem; }

.p-r-4 {
  padding-right: 1.5rem; }

.p-b-4 {
  padding-bottom: 1.5rem; }

.p-l-4 {
  padding-left: 1.5rem; }

.p-x-4 {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

.p-y-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.p-5 {
  padding: 2rem 2rem; }

.p-t-5 {
  padding-top: 2rem; }

.p-r-5 {
  padding-right: 2rem; }

.p-b-5 {
  padding-bottom: 2rem; }

.p-l-5 {
  padding-left: 2rem; }

.p-x-5 {
  padding-right: 2rem;
  padding-left: 2rem; }

.p-y-5 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.m-x-auto {
  margin-right: auto;
  margin-left: auto; }

.m-y-auto {
  margin-top: auto;
  margin-bottom: auto; }

.m-auto {
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto; }

.text-primary {
  color: #0A344D; }

a.text-primary:hover, a.text-primary:focus {
  color: black; }

.text-secondary {
  color: #FA7268; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #f41808; }

.text-positive {
  color: #50BF63; }

a.text-positive:hover, a.text-positive:focus {
  color: #2d7c3b; }

.text-negative {
  color: #DC4C41; }

a.text-negative:hover, a.text-negative:focus {
  color: #9b251c; }

.text-invert {
  color: #FFFFFF; }

a.text-invert:hover, a.text-invert:focus {
  color: #cccccc; }

.text-white {
  color: #FFFFFF; }

a.text-white:hover, a.text-white:focus {
  color: #cccccc; }

.text-light-grey {
  color: #A0A0A0; }

a.text-light-grey:hover, a.text-light-grey:focus {
  color: #6d6d6d; }

.text-lighter-grey {
  color: #E0E0E0; }

a.text-lighter-grey:hover, a.text-lighter-grey:focus {
  color: #adadad; }

.text-xs {
  font-size: 0.5rem; }

.text-sm {
  font-size: 0.75rem; }

.text-md {
  font-size: 1rem; }

.text-lg {
  font-size: 1.25rem; }

.text-xl {
  font-size: 1.5rem; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-capitalize {
  text-transform: capitalize; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-overline {
  text-decoration: overline; }

.text-line-through {
  text-decoration: line-through; }

.text-underline {
  text-decoration: underline; }

.font-family-body {
  font-family: "Inter", "Helvetica", "Arial", sans-serif; }

.font-family-heading {
  font-family: "Ubuntu", "Helvetica", "Arial", sans-serif; }

.font-weight-light {
  font-weight: 300; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: 700; }

.text-nowrap {
  white-space: nowrap; }

.text-italic {
  font-style: italic; }

@media (min-width: 28rem) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 27.9375rem) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 28rem) {
  .invisible-sm-up {
    visibility: hidden !important; } }

@media (max-width: 27.9375rem) {
  .invisible-sm-down {
    visibility: hidden !important; } }

@media (min-width: 40rem) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 39.9375rem) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 40rem) {
  .invisible-md-up {
    visibility: hidden !important; } }

@media (max-width: 39.9375rem) {
  .invisible-md-down {
    visibility: hidden !important; } }

@media (min-width: 57.5rem) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 57.4375rem) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 57.5rem) {
  .invisible-lg-up {
    visibility: hidden !important; } }

@media (max-width: 57.4375rem) {
  .invisible-lg-down {
    visibility: hidden !important; } }

@media (min-width: 75rem) {
  .hidden-xl-up {
    display: none !important; } }

@media (max-width: 74.9375rem) {
  .hidden-xl-down {
    display: none !important; } }

@media (min-width: 75rem) {
  .invisible-xl-up {
    visibility: hidden !important; } }

@media (max-width: 74.9375rem) {
  .invisible-xl-down {
    visibility: hidden !important; } }

.invisible {
  visibility: hidden !important; }

.hidden {
  display: none !important; }

html {
  font-family: 'Inter', sans-serif; }

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif; } }

.bb-box.example {
  display: flex;
  flex-direction: column;
  padding: 0;
  background: transparent;
  background: #FFFFFF;
  color: inherit;
  border: 0.125rem solid #E0E0E0; }
  .bb-box.example h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box.example h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box.example h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box.example h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box.example h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .bb-box.example p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
    color: inherit; }
  .bb-box.example a:not(.bb-btn):not(.link):not(.item) {
    color: inherit; }
  @media (min-width: 40rem) {
    .bb-box.example {
      flex-direction: row; } }
  .bb-box.example.full-x {
    flex-direction: column; }
    .bb-box.example.full-x .doc-preview,
    .bb-box.example.full-x .doc-snippet {
      width: 100%; }
  .bb-box.example .doc-preview,
  .bb-box.example .doc-snippet {
    padding: 1.5rem 1rem; }
    @media (min-width: 40rem) {
      .bb-box.example .doc-preview,
      .bb-box.example .doc-snippet {
        width: 50%; } }
    .bb-box.example .doc-preview pre,
    .bb-box.example .doc-snippet pre {
      margin: 0; }
  @media (min-width: 40rem) {
    .bb-box.example .doc-preview {
      padding-right: 1rem; } }
  @media (max-width: 39.9375rem) {
    .bb-box.example .doc-preview {
      overflow-x: scroll; } }
  @media (max-width: 39.9375rem) {
    .bb-box.example .doc-preview .doc-content {
      display: inline-flex;
      width: 100%; } }
  .bb-box.example .doc-preview .doc-content:not(:first-child) {
    margin-top: 0.75rem; }
  .bb-box.example .doc-snippet {
    padding-bottom: 2.5rem;
    background: #fafafa; }
    @media (min-width: 40rem) {
      .bb-box.example .doc-snippet {
        border-top: none; } }
    .bb-box.example .doc-snippet pre {
      max-height: 25rem; }
  .bb-box.example .doc-copy {
    position: absolute;
    right: -0.125rem;
    bottom: -0.125rem;
    border: none;
    border-radius: 0;
    border-top-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem; }

.doc-box {
  position: relative;
  display: block;
  padding: 1.5rem 1rem;
  min-height: 12rem;
  background: #FFFFFF;
  box-shadow: 0 1.25rem 1.5rem -1.25rem rgba(10, 10, 10, 0.1);
  -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
  -webkit-transition-property: box-shadow, -webkit-transform;
  transition-property: box-shadow, -webkit-transform;
  transition-property: box-shadow, transform;
  transition-property: box-shadow, transform, -webkit-transform;
  border-radius: 0.4rem;
  will-change: box-shadow, transform; }
  .doc-box:hover {
    background: #fafafa;
    box-shadow: 0 1.75rem 3rem -1.25rem rgba(10, 10, 10, 0.2);
    text-decoration: none !important;
    -webkit-transform: translateY(-0.25rem);
            transform: translateY(-0.25rem); }
  .doc-box.disabled {
    background: #FFFFFF;
    opacity: .4;
    pointer-events: none; }

@media (max-width: 57.4375rem) {
  .doc.bb-breadcrumb {
    margin-top: 4rem; }
    .doc.bb-breadcrumb ul li {
      padding-bottom: 0; } }

pre[class*='language-'] {
  display: block;
  overflow-x: auto;
  background: transparent; }
  pre[class*='language-'] code {
    padding: 0;
    background: transparent;
    color: #030303; }
  pre[class*='language-'].language-markup .punctuation {
    color: #0A344D;
    font-weight: 700; }
  pre[class*='language-'].language-markup .tag .token.tag {
    color: #0A344D;
    font-weight: 700; }
  pre[class*='language-'].language-markup .attr-name {
    color: #DC4C41; }
  pre[class*='language-'].language-markup .attr-value {
    color: #50BF63; }
  pre[class*='language-'].language-js {
    display: inline-block;
    padding: 0.5rem;
    width: 100%;
    border-left: solid 0.25rem #FA7268;
    border-radius: 0;
    background: #FFFFFF;
    color: #0A344D;
    font-weight: 700; }
    pre[class*='language-'].language-js code {
      color: #0A344D; }

.bb-table code {
  white-space: nowrap; }

code {
  padding: 0.125rem 0.5rem;
  border-radius: 0.2rem;
  background: #0A344D;
  color: #FFFFFF; }

.code-block {
  display: inline-block;
  margin: 1rem 0;
  padding: 0.5rem;
  width: 100%;
  border-left: solid 0.25rem #FA7268;
  border-radius: 0;
  background: #FFFFFF; }
  .code-block pre {
    margin: 0; }

.bb-footer .social-links li {
  padding: 0 0.5rem; }

.bb-footer .logo {
  display: block;
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
  background: url(/static/media/bb-logo.6f0a81bf.svg) no-repeat;
  background-size: 100%; }
  .bb-footer .logo:hover {
    background: url(/static/media/bb-logo-bone.56e4c235.svg) no-repeat;
    background-size: 100%; }

.doc.bb-navbar {
  border-top: 0.25rem solid #FA7268; }

.doc.bottom.bb-navbar {
  border-top: none; }
  .doc.bottom.bb-navbar .bb-btn {
    flex-direction: column;
    border-color: transparent;
    background: transparent;
    color: #030303;
    font-size: 0.75rem; }
    .doc.bottom.bb-navbar .bb-btn:hover, .doc.bottom.bb-navbar .bb-btn:focus, .doc.bottom.bb-navbar .bb-btn:active {
      border-color: transparent;
      background: transparent;
      color: #FA7268; }
    .doc.bottom.bb-navbar .bb-btn.icon span {
      margin: 0 0 0.5rem 0;
      font-size: 1.25rem; }

.doc-page-animation-enter {
  position: absolute;
  z-index: -1;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transition-delay: 0.25s;
          transition-delay: 0.25s;
  -webkit-transform: translateX(-5rem);
          transform: translateX(-5rem); }

.doc-page-animation-enter.doc-page-animation-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  will-change: opacity, transform; }

.doc-page-animation-exit {
  position: absolute;
  width: 100%;
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  will-change: opacity, transform; }
  .doc-page-animation-exit h1 {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: translateX(5rem);
            transform: translateX(5rem); }

.doc-page-animation-exit.doc-page-animation-exit-active {
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateX(5rem);
          transform: translateX(5rem);
  will-change: opacity, transform; }

.illustration.hero {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  width: 100vw;
  height: 100vh; }
  .illustration.hero.space {
    background-color: #000000; }
    .illustration.hero.space .container {
      z-index: 3;
      color: #FFFFFF; }
    .illustration.hero.space h1 {
      text-align: center;
      font-size: 40vw; }
      @media (min-width: 40rem) {
        .illustration.hero.space h1 {
          text-align: left;
          font-size: 20vw; } }
    .illustration.hero.space .title {
      color: #FFFFFF; }
    .illustration.hero.space .subtitle {
      margin-top: -2.5rem;
      text-align: center;
      font-size: 1.5rem; }
      @media (min-width: 40rem) {
        .illustration.hero.space .subtitle {
          margin-top: -5rem;
          text-align: left;
          font-size: 3rem; } }
    .illustration.hero.space .moon {
      z-index: 2;
      display: flex;
      flex-direction: row-reverse;
      margin-top: -10rem; }
      @media (min-width: 57.5rem) {
        .illustration.hero.space .moon {
          margin-top: -50rem; } }
      .illustration.hero.space .moon svg {
        max-width: 100vh;
        max-height: 100vh;
        width: 75vw;
        height: 75vw; }
        .illustration.hero.space .moon svg .hose,
        .illustration.hero.space .moon svg .space-bear {
          -webkit-animation: grow-space-bear-animation 20s infinite;
                  animation: grow-space-bear-animation 20s infinite;
          -webkit-animation-timing-function: cubic-bezier(0.5, -0.3, 0.5, 1.3);
                  animation-timing-function: cubic-bezier(0.5, -0.3, 0.5, 1.3);
          will-change: grow-space-bear-animation; }
        .illustration.hero.space .moon svg .moon,
        .illustration.hero.space .moon svg .base,
        .illustration.hero.space .moon svg .flag {
          -webkit-animation: rotate-moon-animation 3s infinite;
                  animation: rotate-moon-animation 3s infinite;
          -webkit-animation-timing-function: cubic-bezier(0.5, -0.3, 0.5, 1.3);
                  animation-timing-function: cubic-bezier(0.5, -0.3, 0.5, 1.3);
          will-change: rotate-moon-animation; }
    .illustration.hero.space .star {
      position: fixed;
      z-index: 1;
      width: .1875rem;
      height: .1875rem;
      border-radius: 50%;
      background-color: #FFFFFF;
      color: #FFFFFF;
      -webkit-animation-timing-function: ease-in-out;
              animation-timing-function: ease-in-out;
      will-change: star-animation; }
      .illustration.hero.space .star.lg {
        display: none; }
        @media (min-width: 40rem) {
          .illustration.hero.space .star.lg {
            display: block;
            width: .375rem;
            height: .375rem; } }
      .illustration.hero.space .star:nth-child(1) {
        top: 3vh;
        left: 49vw;
        -webkit-animation: star-animation 10116ms infinite;
                animation: star-animation 10116ms infinite; }
      .illustration.hero.space .star.lg:nth-child(1) {
        top: 10vh;
        left: 93vw;
        -webkit-animation: star-animation 5011ms infinite;
                animation: star-animation 5011ms infinite; }
      .illustration.hero.space .star:nth-child(2) {
        top: 15vh;
        left: 96vw;
        -webkit-animation: star-animation 8271ms infinite;
                animation: star-animation 8271ms infinite; }
      .illustration.hero.space .star.lg:nth-child(2) {
        top: 80vh;
        left: 21vw;
        -webkit-animation: star-animation 3568ms infinite;
                animation: star-animation 3568ms infinite; }
      .illustration.hero.space .star:nth-child(3) {
        top: 65vh;
        left: 44vw;
        -webkit-animation: star-animation 1123ms infinite;
                animation: star-animation 1123ms infinite; }
      .illustration.hero.space .star.lg:nth-child(3) {
        top: 84vh;
        left: 26vw;
        -webkit-animation: star-animation 6379ms infinite;
                animation: star-animation 6379ms infinite; }
      .illustration.hero.space .star:nth-child(4) {
        top: 37vh;
        left: 74vw;
        -webkit-animation: star-animation 3131ms infinite;
                animation: star-animation 3131ms infinite; }
      .illustration.hero.space .star.lg:nth-child(4) {
        top: 24vh;
        left: 72vw;
        -webkit-animation: star-animation 3674ms infinite;
                animation: star-animation 3674ms infinite; }
      .illustration.hero.space .star:nth-child(5) {
        top: 32vh;
        left: 7vw;
        -webkit-animation: star-animation 4200ms infinite;
                animation: star-animation 4200ms infinite; }
      .illustration.hero.space .star.lg:nth-child(5) {
        top: 57vh;
        left: 73vw;
        -webkit-animation: star-animation 3292ms infinite;
                animation: star-animation 3292ms infinite; }
      .illustration.hero.space .star:nth-child(6) {
        top: 55vh;
        left: 40vw;
        -webkit-animation: star-animation 5587ms infinite;
                animation: star-animation 5587ms infinite; }
      .illustration.hero.space .star.lg:nth-child(6) {
        top: 63vh;
        left: 14vw;
        -webkit-animation: star-animation 2305ms infinite;
                animation: star-animation 2305ms infinite; }
      .illustration.hero.space .star:nth-child(7) {
        top: 31vh;
        left: 52vw;
        -webkit-animation: star-animation 5992ms infinite;
                animation: star-animation 5992ms infinite; }
      .illustration.hero.space .star.lg:nth-child(7) {
        top: 51vh;
        left: 56vw;
        -webkit-animation: star-animation 5981ms infinite;
                animation: star-animation 5981ms infinite; }
      .illustration.hero.space .star:nth-child(8) {
        top: 46vh;
        left: 50vw;
        -webkit-animation: star-animation 8551ms infinite;
                animation: star-animation 8551ms infinite; }
      .illustration.hero.space .star.lg:nth-child(8) {
        top: 55vh;
        left: 31vw;
        -webkit-animation: star-animation 6236ms infinite;
                animation: star-animation 6236ms infinite; }
      .illustration.hero.space .star:nth-child(9) {
        top: 84vh;
        left: 39vw;
        -webkit-animation: star-animation 9240ms infinite;
                animation: star-animation 9240ms infinite; }
      .illustration.hero.space .star.lg:nth-child(9) {
        top: 44vh;
        left: 9vw;
        -webkit-animation: star-animation 7273ms infinite;
                animation: star-animation 7273ms infinite; }
      .illustration.hero.space .star:nth-child(10) {
        top: 5vh;
        left: 56vw;
        -webkit-animation: star-animation 2965ms infinite;
                animation: star-animation 2965ms infinite; }
      .illustration.hero.space .star.lg:nth-child(10) {
        top: 26vh;
        left: 64vw;
        -webkit-animation: star-animation 2881ms infinite;
                animation: star-animation 2881ms infinite; }
      .illustration.hero.space .star:nth-child(11) {
        top: 71vh;
        left: 22vw;
        -webkit-animation: star-animation 5847ms infinite;
                animation: star-animation 5847ms infinite; }
      .illustration.hero.space .star.lg:nth-child(11) {
        top: 16vh;
        left: 38vw;
        -webkit-animation: star-animation 1917ms infinite;
                animation: star-animation 1917ms infinite; }
      .illustration.hero.space .star:nth-child(12) {
        top: 51vh;
        left: 26vw;
        -webkit-animation: star-animation 8340ms infinite;
                animation: star-animation 8340ms infinite; }
      .illustration.hero.space .star.lg:nth-child(12) {
        top: 16vh;
        left: 72vw;
        -webkit-animation: star-animation 4364ms infinite;
                animation: star-animation 4364ms infinite; }
      .illustration.hero.space .star:nth-child(13) {
        top: 21vh;
        left: 66vw;
        -webkit-animation: star-animation 9245ms infinite;
                animation: star-animation 9245ms infinite; }
      .illustration.hero.space .star.lg:nth-child(13) {
        top: 49vh;
        left: 40vw;
        -webkit-animation: star-animation 6069ms infinite;
                animation: star-animation 6069ms infinite; }
      .illustration.hero.space .star:nth-child(14) {
        top: 92vh;
        left: 26vw;
        -webkit-animation: star-animation 5391ms infinite;
                animation: star-animation 5391ms infinite; }
      .illustration.hero.space .star.lg:nth-child(14) {
        top: 31vh;
        left: 22vw;
        -webkit-animation: star-animation 9771ms infinite;
                animation: star-animation 9771ms infinite; }
      .illustration.hero.space .star:nth-child(15) {
        top: 73vh;
        left: 43vw;
        -webkit-animation: star-animation 9986ms infinite;
                animation: star-animation 9986ms infinite; }
      .illustration.hero.space .star.lg:nth-child(15) {
        top: 77vh;
        left: 73vw;
        -webkit-animation: star-animation 8181ms infinite;
                animation: star-animation 8181ms infinite; }
      .illustration.hero.space .star:nth-child(16) {
        top: 23vh;
        left: 50vw;
        -webkit-animation: star-animation 1359ms infinite;
                animation: star-animation 1359ms infinite; }
      .illustration.hero.space .star.lg:nth-child(16) {
        top: 22vh;
        left: 13vw;
        -webkit-animation: star-animation 7566ms infinite;
                animation: star-animation 7566ms infinite; }
      .illustration.hero.space .star:nth-child(17) {
        top: 18vh;
        left: 24vw;
        -webkit-animation: star-animation 8022ms infinite;
                animation: star-animation 8022ms infinite; }
      .illustration.hero.space .star.lg:nth-child(17) {
        top: 81vh;
        left: 89vw;
        -webkit-animation: star-animation 1831ms infinite;
                animation: star-animation 1831ms infinite; }
      .illustration.hero.space .star:nth-child(18) {
        top: 68vh;
        left: 66vw;
        -webkit-animation: star-animation 2199ms infinite;
                animation: star-animation 2199ms infinite; }
      .illustration.hero.space .star.lg:nth-child(18) {
        top: 83vh;
        left: 64vw;
        -webkit-animation: star-animation 7408ms infinite;
                animation: star-animation 7408ms infinite; }
      .illustration.hero.space .star:nth-child(19) {
        top: 10vh;
        left: 39vw;
        -webkit-animation: star-animation 10390ms infinite;
                animation: star-animation 10390ms infinite; }
      .illustration.hero.space .star.lg:nth-child(19) {
        top: 81vh;
        left: 75vw;
        -webkit-animation: star-animation 3819ms infinite;
                animation: star-animation 3819ms infinite; }
      .illustration.hero.space .star:nth-child(20) {
        top: 31vh;
        left: 95vw;
        -webkit-animation: star-animation 7220ms infinite;
                animation: star-animation 7220ms infinite; }
      .illustration.hero.space .star.lg:nth-child(20) {
        top: 8vh;
        left: 75vw;
        -webkit-animation: star-animation 7630ms infinite;
                animation: star-animation 7630ms infinite; }
      .illustration.hero.space .star:nth-child(21) {
        top: 66vh;
        left: 34vw;
        -webkit-animation: star-animation 3278ms infinite;
                animation: star-animation 3278ms infinite; }
      .illustration.hero.space .star.lg:nth-child(21) {
        top: 43vh;
        left: 67vw;
        -webkit-animation: star-animation 9781ms infinite;
                animation: star-animation 9781ms infinite; }
      .illustration.hero.space .star:nth-child(22) {
        top: 34vh;
        left: 40vw;
        -webkit-animation: star-animation 2841ms infinite;
                animation: star-animation 2841ms infinite; }
      .illustration.hero.space .star.lg:nth-child(22) {
        top: 92vh;
        left: 30vw;
        -webkit-animation: star-animation 4479ms infinite;
                animation: star-animation 4479ms infinite; }
      .illustration.hero.space .star:nth-child(23) {
        top: 83vh;
        left: 12vw;
        -webkit-animation: star-animation 10031ms infinite;
                animation: star-animation 10031ms infinite; }
      .illustration.hero.space .star.lg:nth-child(23) {
        top: 68vh;
        left: 4vw;
        -webkit-animation: star-animation 10077ms infinite;
                animation: star-animation 10077ms infinite; }
      .illustration.hero.space .star:nth-child(24) {
        top: 61vh;
        left: 98vw;
        -webkit-animation: star-animation 6655ms infinite;
                animation: star-animation 6655ms infinite; }
      .illustration.hero.space .star.lg:nth-child(24) {
        top: 17vh;
        left: 8vw;
        -webkit-animation: star-animation 1181ms infinite;
                animation: star-animation 1181ms infinite; }
      .illustration.hero.space .star:nth-child(25) {
        top: 34vh;
        left: 45vw;
        -webkit-animation: star-animation 8877ms infinite;
                animation: star-animation 8877ms infinite; }
      .illustration.hero.space .star.lg:nth-child(25) {
        top: 14vh;
        left: 13vw;
        -webkit-animation: star-animation 9060ms infinite;
                animation: star-animation 9060ms infinite; }
      .illustration.hero.space .star:nth-child(26) {
        top: 76vh;
        left: 73vw;
        -webkit-animation: star-animation 10508ms infinite;
                animation: star-animation 10508ms infinite; }
      .illustration.hero.space .star.lg:nth-child(26) {
        top: 6vh;
        left: 28vw;
        -webkit-animation: star-animation 7987ms infinite;
                animation: star-animation 7987ms infinite; }
      .illustration.hero.space .star:nth-child(27) {
        top: 38vh;
        left: 36vw;
        -webkit-animation: star-animation 5187ms infinite;
                animation: star-animation 5187ms infinite; }
      .illustration.hero.space .star.lg:nth-child(27) {
        top: 71vh;
        left: 21vw;
        -webkit-animation: star-animation 2460ms infinite;
                animation: star-animation 2460ms infinite; }
      .illustration.hero.space .star:nth-child(28) {
        top: 8vh;
        left: 38vw;
        -webkit-animation: star-animation 9054ms infinite;
                animation: star-animation 9054ms infinite; }
      .illustration.hero.space .star.lg:nth-child(28) {
        top: 34vh;
        left: 44vw;
        -webkit-animation: star-animation 7348ms infinite;
                animation: star-animation 7348ms infinite; }
      .illustration.hero.space .star:nth-child(29) {
        top: 62vh;
        left: 66vw;
        -webkit-animation: star-animation 8681ms infinite;
                animation: star-animation 8681ms infinite; }
      .illustration.hero.space .star.lg:nth-child(29) {
        top: 87vh;
        left: 37vw;
        -webkit-animation: star-animation 5498ms infinite;
                animation: star-animation 5498ms infinite; }
      .illustration.hero.space .star:nth-child(30) {
        top: 39vh;
        left: 12vw;
        -webkit-animation: star-animation 9172ms infinite;
                animation: star-animation 9172ms infinite; }
      .illustration.hero.space .star.lg:nth-child(30) {
        top: 95vh;
        left: 83vw;
        -webkit-animation: star-animation 6420ms infinite;
                animation: star-animation 6420ms infinite; }
      .illustration.hero.space .star:nth-child(31) {
        top: 31vh;
        left: 35vw;
        -webkit-animation: star-animation 7119ms infinite;
                animation: star-animation 7119ms infinite; }
      .illustration.hero.space .star.lg:nth-child(31) {
        top: 43vh;
        left: 69vw;
        -webkit-animation: star-animation 9176ms infinite;
                animation: star-animation 9176ms infinite; }
      .illustration.hero.space .star:nth-child(32) {
        top: 88vh;
        left: 9vw;
        -webkit-animation: star-animation 1352ms infinite;
                animation: star-animation 1352ms infinite; }
      .illustration.hero.space .star.lg:nth-child(32) {
        top: 71vh;
        left: 77vw;
        -webkit-animation: star-animation 9290ms infinite;
                animation: star-animation 9290ms infinite; }
      .illustration.hero.space .star:nth-child(33) {
        top: 28vh;
        left: 15vw;
        -webkit-animation: star-animation 1086ms infinite;
                animation: star-animation 1086ms infinite; }
      .illustration.hero.space .star.lg:nth-child(33) {
        top: 81vh;
        left: 41vw;
        -webkit-animation: star-animation 5497ms infinite;
                animation: star-animation 5497ms infinite; }
      .illustration.hero.space .star:nth-child(34) {
        top: 89vh;
        left: 20vw;
        -webkit-animation: star-animation 1322ms infinite;
                animation: star-animation 1322ms infinite; }
      .illustration.hero.space .star.lg:nth-child(34) {
        top: 42vh;
        left: 84vw;
        -webkit-animation: star-animation 9944ms infinite;
                animation: star-animation 9944ms infinite; }
      .illustration.hero.space .star:nth-child(35) {
        top: 35vh;
        left: 71vw;
        -webkit-animation: star-animation 7169ms infinite;
                animation: star-animation 7169ms infinite; }
      .illustration.hero.space .star.lg:nth-child(35) {
        top: 53vh;
        left: 22vw;
        -webkit-animation: star-animation 2940ms infinite;
                animation: star-animation 2940ms infinite; }
      .illustration.hero.space .star:nth-child(36) {
        top: 93vh;
        left: 42vw;
        -webkit-animation: star-animation 1063ms infinite;
                animation: star-animation 1063ms infinite; }
      .illustration.hero.space .star.lg:nth-child(36) {
        top: 55vh;
        left: 41vw;
        -webkit-animation: star-animation 10189ms infinite;
                animation: star-animation 10189ms infinite; }
      .illustration.hero.space .star:nth-child(37) {
        top: 15vh;
        left: 26vw;
        -webkit-animation: star-animation 8616ms infinite;
                animation: star-animation 8616ms infinite; }
      .illustration.hero.space .star.lg:nth-child(37) {
        top: 7vh;
        left: 6vw;
        -webkit-animation: star-animation 1649ms infinite;
                animation: star-animation 1649ms infinite; }
      .illustration.hero.space .star:nth-child(38) {
        top: 50vh;
        left: 72vw;
        -webkit-animation: star-animation 7071ms infinite;
                animation: star-animation 7071ms infinite; }
      .illustration.hero.space .star.lg:nth-child(38) {
        top: 72vh;
        left: 81vw;
        -webkit-animation: star-animation 10061ms infinite;
                animation: star-animation 10061ms infinite; }
      .illustration.hero.space .star:nth-child(39) {
        top: 26vh;
        left: 77vw;
        -webkit-animation: star-animation 9043ms infinite;
                animation: star-animation 9043ms infinite; }
      .illustration.hero.space .star.lg:nth-child(39) {
        top: 98vh;
        left: 28vw;
        -webkit-animation: star-animation 2419ms infinite;
                animation: star-animation 2419ms infinite; }
      .illustration.hero.space .star:nth-child(40) {
        top: 54vh;
        left: 26vw;
        -webkit-animation: star-animation 10030ms infinite;
                animation: star-animation 10030ms infinite; }
      .illustration.hero.space .star.lg:nth-child(40) {
        top: 94vh;
        left: 31vw;
        -webkit-animation: star-animation 4124ms infinite;
                animation: star-animation 4124ms infinite; }
      .illustration.hero.space .star:nth-child(41) {
        top: 22vh;
        left: 86vw;
        -webkit-animation: star-animation 7187ms infinite;
                animation: star-animation 7187ms infinite; }
      .illustration.hero.space .star.lg:nth-child(41) {
        top: 78vh;
        left: 74vw;
        -webkit-animation: star-animation 3545ms infinite;
                animation: star-animation 3545ms infinite; }
      .illustration.hero.space .star:nth-child(42) {
        top: 16vh;
        left: 11vw;
        -webkit-animation: star-animation 1809ms infinite;
                animation: star-animation 1809ms infinite; }
      .illustration.hero.space .star.lg:nth-child(42) {
        top: 29vh;
        left: 67vw;
        -webkit-animation: star-animation 6345ms infinite;
                animation: star-animation 6345ms infinite; }
      .illustration.hero.space .star:nth-child(43) {
        top: 11vh;
        left: 10vw;
        -webkit-animation: star-animation 8545ms infinite;
                animation: star-animation 8545ms infinite; }
      .illustration.hero.space .star.lg:nth-child(43) {
        top: 8vh;
        left: 2vw;
        -webkit-animation: star-animation 7024ms infinite;
                animation: star-animation 7024ms infinite; }
      .illustration.hero.space .star:nth-child(44) {
        top: 52vh;
        left: 44vw;
        -webkit-animation: star-animation 8356ms infinite;
                animation: star-animation 8356ms infinite; }
      .illustration.hero.space .star.lg:nth-child(44) {
        top: 79vh;
        left: 29vw;
        -webkit-animation: star-animation 8329ms infinite;
                animation: star-animation 8329ms infinite; }
      .illustration.hero.space .star:nth-child(45) {
        top: 74vh;
        left: 33vw;
        -webkit-animation: star-animation 2108ms infinite;
                animation: star-animation 2108ms infinite; }
      .illustration.hero.space .star.lg:nth-child(45) {
        top: 34vh;
        left: 10vw;
        -webkit-animation: star-animation 5536ms infinite;
                animation: star-animation 5536ms infinite; }
      .illustration.hero.space .star:nth-child(46) {
        top: 76vh;
        left: 64vw;
        -webkit-animation: star-animation 2466ms infinite;
                animation: star-animation 2466ms infinite; }
      .illustration.hero.space .star.lg:nth-child(46) {
        top: 77vh;
        left: 33vw;
        -webkit-animation: star-animation 4225ms infinite;
                animation: star-animation 4225ms infinite; }
      .illustration.hero.space .star:nth-child(47) {
        top: 83vh;
        left: 66vw;
        -webkit-animation: star-animation 7488ms infinite;
                animation: star-animation 7488ms infinite; }
      .illustration.hero.space .star.lg:nth-child(47) {
        top: 32vh;
        left: 17vw;
        -webkit-animation: star-animation 1805ms infinite;
                animation: star-animation 1805ms infinite; }
      .illustration.hero.space .star:nth-child(48) {
        top: 71vh;
        left: 94vw;
        -webkit-animation: star-animation 10544ms infinite;
                animation: star-animation 10544ms infinite; }
      .illustration.hero.space .star.lg:nth-child(48) {
        top: 91vh;
        left: 66vw;
        -webkit-animation: star-animation 8525ms infinite;
                animation: star-animation 8525ms infinite; }
      .illustration.hero.space .star:nth-child(49) {
        top: 15vh;
        left: 43vw;
        -webkit-animation: star-animation 6338ms infinite;
                animation: star-animation 6338ms infinite; }
      .illustration.hero.space .star.lg:nth-child(49) {
        top: 72vh;
        left: 59vw;
        -webkit-animation: star-animation 6675ms infinite;
                animation: star-animation 6675ms infinite; }
      .illustration.hero.space .star:nth-child(50) {
        top: 55vh;
        left: 21vw;
        -webkit-animation: star-animation 2209ms infinite;
                animation: star-animation 2209ms infinite; }
      .illustration.hero.space .star.lg:nth-child(50) {
        top: 88vh;
        left: 62vw;
        -webkit-animation: star-animation 1121ms infinite;
                animation: star-animation 1121ms infinite; }
      .illustration.hero.space .star:nth-child(51) {
        top: 28vh;
        left: 80vw;
        -webkit-animation: star-animation 776ms infinite;
                animation: star-animation 776ms infinite; }
      .illustration.hero.space .star.lg:nth-child(51) {
        top: 64vh;
        left: 69vw;
        -webkit-animation: star-animation 1680ms infinite;
                animation: star-animation 1680ms infinite; }
      .illustration.hero.space .star:nth-child(52) {
        top: 44vh;
        left: 6vw;
        -webkit-animation: star-animation 3385ms infinite;
                animation: star-animation 3385ms infinite; }
      .illustration.hero.space .star.lg:nth-child(52) {
        top: 53vh;
        left: 92vw;
        -webkit-animation: star-animation 9915ms infinite;
                animation: star-animation 9915ms infinite; }
      .illustration.hero.space .star:nth-child(53) {
        top: 91vh;
        left: 21vw;
        -webkit-animation: star-animation 8657ms infinite;
                animation: star-animation 8657ms infinite; }
      .illustration.hero.space .star.lg:nth-child(53) {
        top: 28vh;
        left: 60vw;
        -webkit-animation: star-animation 7104ms infinite;
                animation: star-animation 7104ms infinite; }
      .illustration.hero.space .star:nth-child(54) {
        top: 28vh;
        left: 4vw;
        -webkit-animation: star-animation 1468ms infinite;
                animation: star-animation 1468ms infinite; }
      .illustration.hero.space .star.lg:nth-child(54) {
        top: 38vh;
        left: 76vw;
        -webkit-animation: star-animation 10239ms infinite;
                animation: star-animation 10239ms infinite; }
      .illustration.hero.space .star:nth-child(55) {
        top: 96vh;
        left: 51vw;
        -webkit-animation: star-animation 3713ms infinite;
                animation: star-animation 3713ms infinite; }
      .illustration.hero.space .star.lg:nth-child(55) {
        top: 53vh;
        left: 3vw;
        -webkit-animation: star-animation 2592ms infinite;
                animation: star-animation 2592ms infinite; }
      .illustration.hero.space .star:nth-child(56) {
        top: 24vh;
        left: 78vw;
        -webkit-animation: star-animation 8710ms infinite;
                animation: star-animation 8710ms infinite; }
      .illustration.hero.space .star.lg:nth-child(56) {
        top: 36vh;
        left: 79vw;
        -webkit-animation: star-animation 3272ms infinite;
                animation: star-animation 3272ms infinite; }
      .illustration.hero.space .star:nth-child(57) {
        top: 74vh;
        left: 63vw;
        -webkit-animation: star-animation 4493ms infinite;
                animation: star-animation 4493ms infinite; }
      .illustration.hero.space .star.lg:nth-child(57) {
        top: 66vh;
        left: 20vw;
        -webkit-animation: star-animation 5327ms infinite;
                animation: star-animation 5327ms infinite; }
      .illustration.hero.space .star:nth-child(58) {
        top: 30vh;
        left: 61vw;
        -webkit-animation: star-animation 10539ms infinite;
                animation: star-animation 10539ms infinite; }
      .illustration.hero.space .star.lg:nth-child(58) {
        top: 58vh;
        left: 15vw;
        -webkit-animation: star-animation 9355ms infinite;
                animation: star-animation 9355ms infinite; }
      .illustration.hero.space .star:nth-child(59) {
        top: 87vh;
        left: 73vw;
        -webkit-animation: star-animation 7356ms infinite;
                animation: star-animation 7356ms infinite; }
      .illustration.hero.space .star.lg:nth-child(59) {
        top: 10vh;
        left: 13vw;
        -webkit-animation: star-animation 3142ms infinite;
                animation: star-animation 3142ms infinite; }
      .illustration.hero.space .star:nth-child(60) {
        top: 93vh;
        left: 75vw;
        -webkit-animation: star-animation 3606ms infinite;
                animation: star-animation 3606ms infinite; }
      .illustration.hero.space .star.lg:nth-child(60) {
        top: 26vh;
        left: 86vw;
        -webkit-animation: star-animation 1804ms infinite;
                animation: star-animation 1804ms infinite; }
      .illustration.hero.space .star:nth-child(61) {
        top: 41vh;
        left: 37vw;
        -webkit-animation: star-animation 4255ms infinite;
                animation: star-animation 4255ms infinite; }
      .illustration.hero.space .star.lg:nth-child(61) {
        top: 52vh;
        left: 13vw;
        -webkit-animation: star-animation 8578ms infinite;
                animation: star-animation 8578ms infinite; }
      .illustration.hero.space .star:nth-child(62) {
        top: 10vh;
        left: 83vw;
        -webkit-animation: star-animation 1132ms infinite;
                animation: star-animation 1132ms infinite; }
      .illustration.hero.space .star.lg:nth-child(62) {
        top: 64vh;
        left: 35vw;
        -webkit-animation: star-animation 3546ms infinite;
                animation: star-animation 3546ms infinite; }
      .illustration.hero.space .star:nth-child(63) {
        top: 7vh;
        left: 51vw;
        -webkit-animation: star-animation 5744ms infinite;
                animation: star-animation 5744ms infinite; }
      .illustration.hero.space .star.lg:nth-child(63) {
        top: 2vh;
        left: 30vw;
        -webkit-animation: star-animation 5434ms infinite;
                animation: star-animation 5434ms infinite; }
      .illustration.hero.space .star:nth-child(64) {
        top: 42vh;
        left: 91vw;
        -webkit-animation: star-animation 2804ms infinite;
                animation: star-animation 2804ms infinite; }
      .illustration.hero.space .star.lg:nth-child(64) {
        top: 90vh;
        left: 36vw;
        -webkit-animation: star-animation 2334ms infinite;
                animation: star-animation 2334ms infinite; }
      .illustration.hero.space .star:nth-child(65) {
        top: 35vh;
        left: 90vw;
        -webkit-animation: star-animation 4994ms infinite;
                animation: star-animation 4994ms infinite; }
      .illustration.hero.space .star.lg:nth-child(65) {
        top: 17vh;
        left: 86vw;
        -webkit-animation: star-animation 2242ms infinite;
                animation: star-animation 2242ms infinite; }
      .illustration.hero.space .star:nth-child(66) {
        top: 63vh;
        left: 96vw;
        -webkit-animation: star-animation 864ms infinite;
                animation: star-animation 864ms infinite; }
      .illustration.hero.space .star.lg:nth-child(66) {
        top: 57vh;
        left: 3vw;
        -webkit-animation: star-animation 6679ms infinite;
                animation: star-animation 6679ms infinite; }
      .illustration.hero.space .star:nth-child(67) {
        top: 79vh;
        left: 40vw;
        -webkit-animation: star-animation 8487ms infinite;
                animation: star-animation 8487ms infinite; }
      .illustration.hero.space .star.lg:nth-child(67) {
        top: 30vh;
        left: 37vw;
        -webkit-animation: star-animation 8169ms infinite;
                animation: star-animation 8169ms infinite; }
      .illustration.hero.space .star:nth-child(68) {
        top: 34vh;
        left: 93vw;
        -webkit-animation: star-animation 5100ms infinite;
                animation: star-animation 5100ms infinite; }
      .illustration.hero.space .star.lg:nth-child(68) {
        top: 46vh;
        left: 86vw;
        -webkit-animation: star-animation 1322ms infinite;
                animation: star-animation 1322ms infinite; }
      .illustration.hero.space .star:nth-child(69) {
        top: 1vh;
        left: 90vw;
        -webkit-animation: star-animation 1435ms infinite;
                animation: star-animation 1435ms infinite; }
      .illustration.hero.space .star.lg:nth-child(69) {
        top: 91vh;
        left: 65vw;
        -webkit-animation: star-animation 3522ms infinite;
                animation: star-animation 3522ms infinite; }
      .illustration.hero.space .star:nth-child(70) {
        top: 46vh;
        left: 60vw;
        -webkit-animation: star-animation 1958ms infinite;
                animation: star-animation 1958ms infinite; }
      .illustration.hero.space .star.lg:nth-child(70) {
        top: 21vh;
        left: 32vw;
        -webkit-animation: star-animation 2398ms infinite;
                animation: star-animation 2398ms infinite; }
      .illustration.hero.space .star:nth-child(71) {
        top: 89vh;
        left: 84vw;
        -webkit-animation: star-animation 2910ms infinite;
                animation: star-animation 2910ms infinite; }
      .illustration.hero.space .star.lg:nth-child(71) {
        top: 69vh;
        left: 28vw;
        -webkit-animation: star-animation 6429ms infinite;
                animation: star-animation 6429ms infinite; }
      .illustration.hero.space .star:nth-child(72) {
        top: 78vh;
        left: 58vw;
        -webkit-animation: star-animation 9509ms infinite;
                animation: star-animation 9509ms infinite; }
      .illustration.hero.space .star.lg:nth-child(72) {
        top: 82vh;
        left: 22vw;
        -webkit-animation: star-animation 3871ms infinite;
                animation: star-animation 3871ms infinite; }
      .illustration.hero.space .star:nth-child(73) {
        top: 26vh;
        left: 3vw;
        -webkit-animation: star-animation 9953ms infinite;
                animation: star-animation 9953ms infinite; }
      .illustration.hero.space .star.lg:nth-child(73) {
        top: 62vh;
        left: 93vw;
        -webkit-animation: star-animation 6376ms infinite;
                animation: star-animation 6376ms infinite; }
      .illustration.hero.space .star:nth-child(74) {
        top: 69vh;
        left: 33vw;
        -webkit-animation: star-animation 10665ms infinite;
                animation: star-animation 10665ms infinite; }
      .illustration.hero.space .star.lg:nth-child(74) {
        top: 54vh;
        left: 71vw;
        -webkit-animation: star-animation 7839ms infinite;
                animation: star-animation 7839ms infinite; }
      .illustration.hero.space .star:nth-child(75) {
        top: 53vh;
        left: 87vw;
        -webkit-animation: star-animation 8163ms infinite;
                animation: star-animation 8163ms infinite; }
      .illustration.hero.space .star.lg:nth-child(75) {
        top: 90vh;
        left: 85vw;
        -webkit-animation: star-animation 5757ms infinite;
                animation: star-animation 5757ms infinite; }
      .illustration.hero.space .star:nth-child(76) {
        top: 68vh;
        left: 1vw;
        -webkit-animation: star-animation 3096ms infinite;
                animation: star-animation 3096ms infinite; }
      .illustration.hero.space .star.lg:nth-child(76) {
        top: 44vh;
        left: 82vw;
        -webkit-animation: star-animation 10550ms infinite;
                animation: star-animation 10550ms infinite; }
      .illustration.hero.space .star:nth-child(77) {
        top: 70vh;
        left: 86vw;
        -webkit-animation: star-animation 2809ms infinite;
                animation: star-animation 2809ms infinite; }
      .illustration.hero.space .star.lg:nth-child(77) {
        top: 9vh;
        left: 98vw;
        -webkit-animation: star-animation 4656ms infinite;
                animation: star-animation 4656ms infinite; }
      .illustration.hero.space .star:nth-child(78) {
        top: 70vh;
        left: 37vw;
        -webkit-animation: star-animation 3188ms infinite;
                animation: star-animation 3188ms infinite; }
      .illustration.hero.space .star.lg:nth-child(78) {
        top: 86vh;
        left: 34vw;
        -webkit-animation: star-animation 7399ms infinite;
                animation: star-animation 7399ms infinite; }
      .illustration.hero.space .star:nth-child(79) {
        top: 25vh;
        left: 71vw;
        -webkit-animation: star-animation 5132ms infinite;
                animation: star-animation 5132ms infinite; }
      .illustration.hero.space .star.lg:nth-child(79) {
        top: 97vh;
        left: 33vw;
        -webkit-animation: star-animation 9490ms infinite;
                animation: star-animation 9490ms infinite; }
      .illustration.hero.space .star:nth-child(80) {
        top: 89vh;
        left: 31vw;
        -webkit-animation: star-animation 4320ms infinite;
                animation: star-animation 4320ms infinite; }
      .illustration.hero.space .star.lg:nth-child(80) {
        top: 82vh;
        left: 87vw;
        -webkit-animation: star-animation 6626ms infinite;
                animation: star-animation 6626ms infinite; }
      .illustration.hero.space .star:nth-child(81) {
        top: 70vh;
        left: 49vw;
        -webkit-animation: star-animation 3908ms infinite;
                animation: star-animation 3908ms infinite; }
      .illustration.hero.space .star.lg:nth-child(81) {
        top: 43vh;
        left: 81vw;
        -webkit-animation: star-animation 7443ms infinite;
                animation: star-animation 7443ms infinite; }
      .illustration.hero.space .star:nth-child(82) {
        top: 3vh;
        left: 10vw;
        -webkit-animation: star-animation 1944ms infinite;
                animation: star-animation 1944ms infinite; }
      .illustration.hero.space .star.lg:nth-child(82) {
        top: 4vh;
        left: 28vw;
        -webkit-animation: star-animation 4194ms infinite;
                animation: star-animation 4194ms infinite; }
      .illustration.hero.space .star:nth-child(83) {
        top: 14vh;
        left: 88vw;
        -webkit-animation: star-animation 4868ms infinite;
                animation: star-animation 4868ms infinite; }
      .illustration.hero.space .star.lg:nth-child(83) {
        top: 78vh;
        left: 21vw;
        -webkit-animation: star-animation 9670ms infinite;
                animation: star-animation 9670ms infinite; }
      .illustration.hero.space .star:nth-child(84) {
        top: 25vh;
        left: 57vw;
        -webkit-animation: star-animation 1292ms infinite;
                animation: star-animation 1292ms infinite; }
      .illustration.hero.space .star.lg:nth-child(84) {
        top: 66vh;
        left: 36vw;
        -webkit-animation: star-animation 5710ms infinite;
                animation: star-animation 5710ms infinite; }
      .illustration.hero.space .star:nth-child(85) {
        top: 36vh;
        left: 24vw;
        -webkit-animation: star-animation 10245ms infinite;
                animation: star-animation 10245ms infinite; }
      .illustration.hero.space .star.lg:nth-child(85) {
        top: 59vh;
        left: 28vw;
        -webkit-animation: star-animation 7789ms infinite;
                animation: star-animation 7789ms infinite; }
      .illustration.hero.space .star:nth-child(86) {
        top: 19vh;
        left: 82vw;
        -webkit-animation: star-animation 952ms infinite;
                animation: star-animation 952ms infinite; }
      .illustration.hero.space .star.lg:nth-child(86) {
        top: 41vh;
        left: 53vw;
        -webkit-animation: star-animation 9129ms infinite;
                animation: star-animation 9129ms infinite; }
      .illustration.hero.space .star:nth-child(87) {
        top: 89vh;
        left: 80vw;
        -webkit-animation: star-animation 2651ms infinite;
                animation: star-animation 2651ms infinite; }
      .illustration.hero.space .star.lg:nth-child(87) {
        top: 31vh;
        left: 41vw;
        -webkit-animation: star-animation 3536ms infinite;
                animation: star-animation 3536ms infinite; }
      .illustration.hero.space .star:nth-child(88) {
        top: 95vh;
        left: 87vw;
        -webkit-animation: star-animation 3076ms infinite;
                animation: star-animation 3076ms infinite; }
      .illustration.hero.space .star.lg:nth-child(88) {
        top: 38vh;
        left: 36vw;
        -webkit-animation: star-animation 4488ms infinite;
                animation: star-animation 4488ms infinite; }
      .illustration.hero.space .star:nth-child(89) {
        top: 52vh;
        left: 67vw;
        -webkit-animation: star-animation 3071ms infinite;
                animation: star-animation 3071ms infinite; }
      .illustration.hero.space .star.lg:nth-child(89) {
        top: 94vh;
        left: 84vw;
        -webkit-animation: star-animation 5416ms infinite;
                animation: star-animation 5416ms infinite; }
      .illustration.hero.space .star:nth-child(90) {
        top: 18vh;
        left: 67vw;
        -webkit-animation: star-animation 5513ms infinite;
                animation: star-animation 5513ms infinite; }
      .illustration.hero.space .star.lg:nth-child(90) {
        top: 69vh;
        left: 97vw;
        -webkit-animation: star-animation 2458ms infinite;
                animation: star-animation 2458ms infinite; }
      .illustration.hero.space .star:nth-child(91) {
        top: 98vh;
        left: 23vw;
        -webkit-animation: star-animation 5453ms infinite;
                animation: star-animation 5453ms infinite; }
      .illustration.hero.space .star.lg:nth-child(91) {
        top: 57vh;
        left: 75vw;
        -webkit-animation: star-animation 10569ms infinite;
                animation: star-animation 10569ms infinite; }
      .illustration.hero.space .star:nth-child(92) {
        top: 47vh;
        left: 87vw;
        -webkit-animation: star-animation 2874ms infinite;
                animation: star-animation 2874ms infinite; }
      .illustration.hero.space .star.lg:nth-child(92) {
        top: 58vh;
        left: 62vw;
        -webkit-animation: star-animation 8563ms infinite;
                animation: star-animation 8563ms infinite; }
      .illustration.hero.space .star:nth-child(93) {
        top: 85vh;
        left: 37vw;
        -webkit-animation: star-animation 3687ms infinite;
                animation: star-animation 3687ms infinite; }
      .illustration.hero.space .star.lg:nth-child(93) {
        top: 93vh;
        left: 73vw;
        -webkit-animation: star-animation 6698ms infinite;
                animation: star-animation 6698ms infinite; }
      .illustration.hero.space .star:nth-child(94) {
        top: 66vh;
        left: 15vw;
        -webkit-animation: star-animation 4851ms infinite;
                animation: star-animation 4851ms infinite; }
      .illustration.hero.space .star.lg:nth-child(94) {
        top: 11vh;
        left: 44vw;
        -webkit-animation: star-animation 5253ms infinite;
                animation: star-animation 5253ms infinite; }
      .illustration.hero.space .star:nth-child(95) {
        top: 66vh;
        left: 22vw;
        -webkit-animation: star-animation 3136ms infinite;
                animation: star-animation 3136ms infinite; }
      .illustration.hero.space .star.lg:nth-child(95) {
        top: 54vh;
        left: 83vw;
        -webkit-animation: star-animation 4271ms infinite;
                animation: star-animation 4271ms infinite; }
      .illustration.hero.space .star:nth-child(96) {
        top: 89vh;
        left: 12vw;
        -webkit-animation: star-animation 1348ms infinite;
                animation: star-animation 1348ms infinite; }
      .illustration.hero.space .star.lg:nth-child(96) {
        top: 53vh;
        left: 9vw;
        -webkit-animation: star-animation 765ms infinite;
                animation: star-animation 765ms infinite; }
      .illustration.hero.space .star:nth-child(97) {
        top: 47vh;
        left: 79vw;
        -webkit-animation: star-animation 6290ms infinite;
                animation: star-animation 6290ms infinite; }
      .illustration.hero.space .star.lg:nth-child(97) {
        top: 23vh;
        left: 81vw;
        -webkit-animation: star-animation 8799ms infinite;
                animation: star-animation 8799ms infinite; }
      .illustration.hero.space .star:nth-child(98) {
        top: 64vh;
        left: 29vw;
        -webkit-animation: star-animation 10499ms infinite;
                animation: star-animation 10499ms infinite; }
      .illustration.hero.space .star.lg:nth-child(98) {
        top: 30vh;
        left: 88vw;
        -webkit-animation: star-animation 3366ms infinite;
                animation: star-animation 3366ms infinite; }
      .illustration.hero.space .star:nth-child(99) {
        top: 14vh;
        left: 92vw;
        -webkit-animation: star-animation 8881ms infinite;
                animation: star-animation 8881ms infinite; }
      .illustration.hero.space .star.lg:nth-child(99) {
        top: 92vh;
        left: 24vw;
        -webkit-animation: star-animation 6535ms infinite;
                animation: star-animation 6535ms infinite; }
      .illustration.hero.space .star:nth-child(100) {
        top: 82vh;
        left: 17vw;
        -webkit-animation: star-animation 6153ms infinite;
                animation: star-animation 6153ms infinite; }
      .illustration.hero.space .star.lg:nth-child(100) {
        top: 5vh;
        left: 54vw;
        -webkit-animation: star-animation 4304ms infinite;
                animation: star-animation 4304ms infinite; }
      .illustration.hero.space .star:nth-child(101) {
        top: 47vh;
        left: 2vw;
        -webkit-animation: star-animation 8393ms infinite;
                animation: star-animation 8393ms infinite; }
      .illustration.hero.space .star.lg:nth-child(101) {
        top: 17vh;
        left: 96vw;
        -webkit-animation: star-animation 9029ms infinite;
                animation: star-animation 9029ms infinite; }
      .illustration.hero.space .star:nth-child(102) {
        top: 35vh;
        left: 68vw;
        -webkit-animation: star-animation 6908ms infinite;
                animation: star-animation 6908ms infinite; }
      .illustration.hero.space .star.lg:nth-child(102) {
        top: 55vh;
        left: 13vw;
        -webkit-animation: star-animation 1719ms infinite;
                animation: star-animation 1719ms infinite; }
      .illustration.hero.space .star:nth-child(103) {
        top: 89vh;
        left: 19vw;
        -webkit-animation: star-animation 9665ms infinite;
                animation: star-animation 9665ms infinite; }
      .illustration.hero.space .star.lg:nth-child(103) {
        top: 86vh;
        left: 27vw;
        -webkit-animation: star-animation 6846ms infinite;
                animation: star-animation 6846ms infinite; }
      .illustration.hero.space .star:nth-child(104) {
        top: 64vh;
        left: 95vw;
        -webkit-animation: star-animation 6727ms infinite;
                animation: star-animation 6727ms infinite; }
      .illustration.hero.space .star.lg:nth-child(104) {
        top: 1vh;
        left: 54vw;
        -webkit-animation: star-animation 9339ms infinite;
                animation: star-animation 9339ms infinite; }
      .illustration.hero.space .star:nth-child(105) {
        top: 36vh;
        left: 14vw;
        -webkit-animation: star-animation 5749ms infinite;
                animation: star-animation 5749ms infinite; }
      .illustration.hero.space .star.lg:nth-child(105) {
        top: 66vh;
        left: 31vw;
        -webkit-animation: star-animation 6825ms infinite;
                animation: star-animation 6825ms infinite; }
      .illustration.hero.space .star:nth-child(106) {
        top: 18vh;
        left: 74vw;
        -webkit-animation: star-animation 992ms infinite;
                animation: star-animation 992ms infinite; }
      .illustration.hero.space .star.lg:nth-child(106) {
        top: 17vh;
        left: 68vw;
        -webkit-animation: star-animation 5624ms infinite;
                animation: star-animation 5624ms infinite; }
      .illustration.hero.space .star:nth-child(107) {
        top: 34vh;
        left: 55vw;
        -webkit-animation: star-animation 7481ms infinite;
                animation: star-animation 7481ms infinite; }
      .illustration.hero.space .star.lg:nth-child(107) {
        top: 63vh;
        left: 73vw;
        -webkit-animation: star-animation 3787ms infinite;
                animation: star-animation 3787ms infinite; }
      .illustration.hero.space .star:nth-child(108) {
        top: 87vh;
        left: 71vw;
        -webkit-animation: star-animation 4885ms infinite;
                animation: star-animation 4885ms infinite; }
      .illustration.hero.space .star.lg:nth-child(108) {
        top: 17vh;
        left: 91vw;
        -webkit-animation: star-animation 4430ms infinite;
                animation: star-animation 4430ms infinite; }
      .illustration.hero.space .star:nth-child(109) {
        top: 56vh;
        left: 55vw;
        -webkit-animation: star-animation 8689ms infinite;
                animation: star-animation 8689ms infinite; }
      .illustration.hero.space .star.lg:nth-child(109) {
        top: 96vh;
        left: 78vw;
        -webkit-animation: star-animation 8072ms infinite;
                animation: star-animation 8072ms infinite; }
      .illustration.hero.space .star:nth-child(110) {
        top: 5vh;
        left: 26vw;
        -webkit-animation: star-animation 2755ms infinite;
                animation: star-animation 2755ms infinite; }
      .illustration.hero.space .star.lg:nth-child(110) {
        top: 48vh;
        left: 95vw;
        -webkit-animation: star-animation 2522ms infinite;
                animation: star-animation 2522ms infinite; }
      .illustration.hero.space .star:nth-child(111) {
        top: 11vh;
        left: 69vw;
        -webkit-animation: star-animation 4102ms infinite;
                animation: star-animation 4102ms infinite; }
      .illustration.hero.space .star.lg:nth-child(111) {
        top: 15vh;
        left: 33vw;
        -webkit-animation: star-animation 3169ms infinite;
                animation: star-animation 3169ms infinite; }
      .illustration.hero.space .star:nth-child(112) {
        top: 69vh;
        left: 7vw;
        -webkit-animation: star-animation 1609ms infinite;
                animation: star-animation 1609ms infinite; }
      .illustration.hero.space .star.lg:nth-child(112) {
        top: 58vh;
        left: 3vw;
        -webkit-animation: star-animation 9743ms infinite;
                animation: star-animation 9743ms infinite; }
      .illustration.hero.space .star:nth-child(113) {
        top: 98vh;
        left: 89vw;
        -webkit-animation: star-animation 9394ms infinite;
                animation: star-animation 9394ms infinite; }
      .illustration.hero.space .star.lg:nth-child(113) {
        top: 36vh;
        left: 42vw;
        -webkit-animation: star-animation 2361ms infinite;
                animation: star-animation 2361ms infinite; }
      .illustration.hero.space .star:nth-child(114) {
        top: 29vh;
        left: 58vw;
        -webkit-animation: star-animation 8045ms infinite;
                animation: star-animation 8045ms infinite; }
      .illustration.hero.space .star.lg:nth-child(114) {
        top: 73vh;
        left: 90vw;
        -webkit-animation: star-animation 10301ms infinite;
                animation: star-animation 10301ms infinite; }
      .illustration.hero.space .star:nth-child(115) {
        top: 17vh;
        left: 98vw;
        -webkit-animation: star-animation 9005ms infinite;
                animation: star-animation 9005ms infinite; }
      .illustration.hero.space .star.lg:nth-child(115) {
        top: 85vh;
        left: 90vw;
        -webkit-animation: star-animation 6135ms infinite;
                animation: star-animation 6135ms infinite; }
      .illustration.hero.space .star:nth-child(116) {
        top: 20vh;
        left: 88vw;
        -webkit-animation: star-animation 9998ms infinite;
                animation: star-animation 9998ms infinite; }
      .illustration.hero.space .star.lg:nth-child(116) {
        top: 62vh;
        left: 27vw;
        -webkit-animation: star-animation 8976ms infinite;
                animation: star-animation 8976ms infinite; }
      .illustration.hero.space .star:nth-child(117) {
        top: 19vh;
        left: 58vw;
        -webkit-animation: star-animation 9630ms infinite;
                animation: star-animation 9630ms infinite; }
      .illustration.hero.space .star.lg:nth-child(117) {
        top: 53vh;
        left: 21vw;
        -webkit-animation: star-animation 1188ms infinite;
                animation: star-animation 1188ms infinite; }
      .illustration.hero.space .star:nth-child(118) {
        top: 21vh;
        left: 45vw;
        -webkit-animation: star-animation 714ms infinite;
                animation: star-animation 714ms infinite; }
      .illustration.hero.space .star.lg:nth-child(118) {
        top: 89vh;
        left: 94vw;
        -webkit-animation: star-animation 5047ms infinite;
                animation: star-animation 5047ms infinite; }
      .illustration.hero.space .star:nth-child(119) {
        top: 74vh;
        left: 11vw;
        -webkit-animation: star-animation 6205ms infinite;
                animation: star-animation 6205ms infinite; }
      .illustration.hero.space .star.lg:nth-child(119) {
        top: 5vh;
        left: 5vw;
        -webkit-animation: star-animation 9354ms infinite;
                animation: star-animation 9354ms infinite; }
      .illustration.hero.space .star:nth-child(120) {
        top: 39vh;
        left: 44vw;
        -webkit-animation: star-animation 4050ms infinite;
                animation: star-animation 4050ms infinite; }
      .illustration.hero.space .star.lg:nth-child(120) {
        top: 22vh;
        left: 55vw;
        -webkit-animation: star-animation 3147ms infinite;
                animation: star-animation 3147ms infinite; }
      .illustration.hero.space .star:nth-child(121) {
        top: 12vh;
        left: 78vw;
        -webkit-animation: star-animation 8762ms infinite;
                animation: star-animation 8762ms infinite; }
      .illustration.hero.space .star.lg:nth-child(121) {
        top: 88vh;
        left: 78vw;
        -webkit-animation: star-animation 6778ms infinite;
                animation: star-animation 6778ms infinite; }
      .illustration.hero.space .star:nth-child(122) {
        top: 83vh;
        left: 9vw;
        -webkit-animation: star-animation 4813ms infinite;
                animation: star-animation 4813ms infinite; }
      .illustration.hero.space .star.lg:nth-child(122) {
        top: 62vh;
        left: 45vw;
        -webkit-animation: star-animation 4866ms infinite;
                animation: star-animation 4866ms infinite; }
      .illustration.hero.space .star:nth-child(123) {
        top: 13vh;
        left: 65vw;
        -webkit-animation: star-animation 1673ms infinite;
                animation: star-animation 1673ms infinite; }
      .illustration.hero.space .star.lg:nth-child(123) {
        top: 6vh;
        left: 75vw;
        -webkit-animation: star-animation 5996ms infinite;
                animation: star-animation 5996ms infinite; }
      .illustration.hero.space .star:nth-child(124) {
        top: 48vh;
        left: 34vw;
        -webkit-animation: star-animation 2452ms infinite;
                animation: star-animation 2452ms infinite; }
      .illustration.hero.space .star.lg:nth-child(124) {
        top: 63vh;
        left: 62vw;
        -webkit-animation: star-animation 7353ms infinite;
                animation: star-animation 7353ms infinite; }
      .illustration.hero.space .star:nth-child(125) {
        top: 75vh;
        left: 84vw;
        -webkit-animation: star-animation 10586ms infinite;
                animation: star-animation 10586ms infinite; }
      .illustration.hero.space .star.lg:nth-child(125) {
        top: 82vh;
        left: 64vw;
        -webkit-animation: star-animation 7650ms infinite;
                animation: star-animation 7650ms infinite; }
      .illustration.hero.space .star:nth-child(126) {
        top: 14vh;
        left: 88vw;
        -webkit-animation: star-animation 4929ms infinite;
                animation: star-animation 4929ms infinite; }
      .illustration.hero.space .star.lg:nth-child(126) {
        top: 39vh;
        left: 89vw;
        -webkit-animation: star-animation 10528ms infinite;
                animation: star-animation 10528ms infinite; }
      .illustration.hero.space .star:nth-child(127) {
        top: 53vh;
        left: 54vw;
        -webkit-animation: star-animation 9231ms infinite;
                animation: star-animation 9231ms infinite; }
      .illustration.hero.space .star.lg:nth-child(127) {
        top: 85vh;
        left: 57vw;
        -webkit-animation: star-animation 7319ms infinite;
                animation: star-animation 7319ms infinite; }
      .illustration.hero.space .star:nth-child(128) {
        top: 28vh;
        left: 37vw;
        -webkit-animation: star-animation 3343ms infinite;
                animation: star-animation 3343ms infinite; }
      .illustration.hero.space .star.lg:nth-child(128) {
        top: 17vh;
        left: 95vw;
        -webkit-animation: star-animation 2333ms infinite;
                animation: star-animation 2333ms infinite; }
      .illustration.hero.space .star:nth-child(129) {
        top: 35vh;
        left: 25vw;
        -webkit-animation: star-animation 1530ms infinite;
                animation: star-animation 1530ms infinite; }
      .illustration.hero.space .star.lg:nth-child(129) {
        top: 58vh;
        left: 56vw;
        -webkit-animation: star-animation 8503ms infinite;
                animation: star-animation 8503ms infinite; }
      .illustration.hero.space .star:nth-child(130) {
        top: 15vh;
        left: 88vw;
        -webkit-animation: star-animation 3856ms infinite;
                animation: star-animation 3856ms infinite; }
      .illustration.hero.space .star.lg:nth-child(130) {
        top: 3vh;
        left: 97vw;
        -webkit-animation: star-animation 3851ms infinite;
                animation: star-animation 3851ms infinite; }
      .illustration.hero.space .star:nth-child(131) {
        top: 84vh;
        left: 87vw;
        -webkit-animation: star-animation 778ms infinite;
                animation: star-animation 778ms infinite; }
      .illustration.hero.space .star.lg:nth-child(131) {
        top: 41vh;
        left: 40vw;
        -webkit-animation: star-animation 7606ms infinite;
                animation: star-animation 7606ms infinite; }
      .illustration.hero.space .star:nth-child(132) {
        top: 55vh;
        left: 67vw;
        -webkit-animation: star-animation 10350ms infinite;
                animation: star-animation 10350ms infinite; }
      .illustration.hero.space .star.lg:nth-child(132) {
        top: 83vh;
        left: 8vw;
        -webkit-animation: star-animation 10307ms infinite;
                animation: star-animation 10307ms infinite; }
      .illustration.hero.space .star:nth-child(133) {
        top: 58vh;
        left: 1vw;
        -webkit-animation: star-animation 3271ms infinite;
                animation: star-animation 3271ms infinite; }
      .illustration.hero.space .star.lg:nth-child(133) {
        top: 82vh;
        left: 97vw;
        -webkit-animation: star-animation 6270ms infinite;
                animation: star-animation 6270ms infinite; }
      .illustration.hero.space .star:nth-child(134) {
        top: 91vh;
        left: 16vw;
        -webkit-animation: star-animation 7105ms infinite;
                animation: star-animation 7105ms infinite; }
      .illustration.hero.space .star.lg:nth-child(134) {
        top: 50vh;
        left: 58vw;
        -webkit-animation: star-animation 7404ms infinite;
                animation: star-animation 7404ms infinite; }
      .illustration.hero.space .star:nth-child(135) {
        top: 5vh;
        left: 51vw;
        -webkit-animation: star-animation 6896ms infinite;
                animation: star-animation 6896ms infinite; }
      .illustration.hero.space .star.lg:nth-child(135) {
        top: 28vh;
        left: 13vw;
        -webkit-animation: star-animation 8623ms infinite;
                animation: star-animation 8623ms infinite; }
      .illustration.hero.space .star:nth-child(136) {
        top: 25vh;
        left: 10vw;
        -webkit-animation: star-animation 5537ms infinite;
                animation: star-animation 5537ms infinite; }
      .illustration.hero.space .star.lg:nth-child(136) {
        top: 87vh;
        left: 7vw;
        -webkit-animation: star-animation 3180ms infinite;
                animation: star-animation 3180ms infinite; }
      .illustration.hero.space .star:nth-child(137) {
        top: 32vh;
        left: 18vw;
        -webkit-animation: star-animation 1659ms infinite;
                animation: star-animation 1659ms infinite; }
      .illustration.hero.space .star.lg:nth-child(137) {
        top: 36vh;
        left: 63vw;
        -webkit-animation: star-animation 10082ms infinite;
                animation: star-animation 10082ms infinite; }
      .illustration.hero.space .star:nth-child(138) {
        top: 5vh;
        left: 60vw;
        -webkit-animation: star-animation 3897ms infinite;
                animation: star-animation 3897ms infinite; }
      .illustration.hero.space .star.lg:nth-child(138) {
        top: 40vh;
        left: 17vw;
        -webkit-animation: star-animation 8487ms infinite;
                animation: star-animation 8487ms infinite; }
      .illustration.hero.space .star:nth-child(139) {
        top: 4vh;
        left: 40vw;
        -webkit-animation: star-animation 7653ms infinite;
                animation: star-animation 7653ms infinite; }
      .illustration.hero.space .star.lg:nth-child(139) {
        top: 19vh;
        left: 33vw;
        -webkit-animation: star-animation 10560ms infinite;
                animation: star-animation 10560ms infinite; }
      .illustration.hero.space .star:nth-child(140) {
        top: 17vh;
        left: 37vw;
        -webkit-animation: star-animation 8515ms infinite;
                animation: star-animation 8515ms infinite; }
      .illustration.hero.space .star.lg:nth-child(140) {
        top: 23vh;
        left: 38vw;
        -webkit-animation: star-animation 10537ms infinite;
                animation: star-animation 10537ms infinite; }
      .illustration.hero.space .star:nth-child(141) {
        top: 64vh;
        left: 54vw;
        -webkit-animation: star-animation 3509ms infinite;
                animation: star-animation 3509ms infinite; }
      .illustration.hero.space .star.lg:nth-child(141) {
        top: 47vh;
        left: 19vw;
        -webkit-animation: star-animation 7113ms infinite;
                animation: star-animation 7113ms infinite; }
      .illustration.hero.space .star:nth-child(142) {
        top: 41vh;
        left: 48vw;
        -webkit-animation: star-animation 5459ms infinite;
                animation: star-animation 5459ms infinite; }
      .illustration.hero.space .star.lg:nth-child(142) {
        top: 14vh;
        left: 46vw;
        -webkit-animation: star-animation 2413ms infinite;
                animation: star-animation 2413ms infinite; }
      .illustration.hero.space .star:nth-child(143) {
        top: 5vh;
        left: 7vw;
        -webkit-animation: star-animation 3791ms infinite;
                animation: star-animation 3791ms infinite; }
      .illustration.hero.space .star.lg:nth-child(143) {
        top: 27vh;
        left: 78vw;
        -webkit-animation: star-animation 5589ms infinite;
                animation: star-animation 5589ms infinite; }
      .illustration.hero.space .star:nth-child(144) {
        top: 89vh;
        left: 61vw;
        -webkit-animation: star-animation 2971ms infinite;
                animation: star-animation 2971ms infinite; }
      .illustration.hero.space .star.lg:nth-child(144) {
        top: 39vh;
        left: 55vw;
        -webkit-animation: star-animation 2530ms infinite;
                animation: star-animation 2530ms infinite; }
      .illustration.hero.space .star:nth-child(145) {
        top: 6vh;
        left: 14vw;
        -webkit-animation: star-animation 3516ms infinite;
                animation: star-animation 3516ms infinite; }
      .illustration.hero.space .star.lg:nth-child(145) {
        top: 78vh;
        left: 79vw;
        -webkit-animation: star-animation 2789ms infinite;
                animation: star-animation 2789ms infinite; }
      .illustration.hero.space .star:nth-child(146) {
        top: 54vh;
        left: 68vw;
        -webkit-animation: star-animation 5365ms infinite;
                animation: star-animation 5365ms infinite; }
      .illustration.hero.space .star.lg:nth-child(146) {
        top: 96vh;
        left: 15vw;
        -webkit-animation: star-animation 7359ms infinite;
                animation: star-animation 7359ms infinite; }
      .illustration.hero.space .star:nth-child(147) {
        top: 69vh;
        left: 57vw;
        -webkit-animation: star-animation 10236ms infinite;
                animation: star-animation 10236ms infinite; }
      .illustration.hero.space .star.lg:nth-child(147) {
        top: 36vh;
        left: 58vw;
        -webkit-animation: star-animation 7796ms infinite;
                animation: star-animation 7796ms infinite; }
      .illustration.hero.space .star:nth-child(148) {
        top: 8vh;
        left: 26vw;
        -webkit-animation: star-animation 7169ms infinite;
                animation: star-animation 7169ms infinite; }
      .illustration.hero.space .star.lg:nth-child(148) {
        top: 44vh;
        left: 13vw;
        -webkit-animation: star-animation 3412ms infinite;
                animation: star-animation 3412ms infinite; }
      .illustration.hero.space .star:nth-child(149) {
        top: 47vh;
        left: 55vw;
        -webkit-animation: star-animation 3140ms infinite;
                animation: star-animation 3140ms infinite; }
      .illustration.hero.space .star.lg:nth-child(149) {
        top: 35vh;
        left: 52vw;
        -webkit-animation: star-animation 5475ms infinite;
                animation: star-animation 5475ms infinite; }
      .illustration.hero.space .star:nth-child(150) {
        top: 86vh;
        left: 37vw;
        -webkit-animation: star-animation 10174ms infinite;
                animation: star-animation 10174ms infinite; }
      .illustration.hero.space .star.lg:nth-child(150) {
        top: 77vh;
        left: 26vw;
        -webkit-animation: star-animation 2833ms infinite;
                animation: star-animation 2833ms infinite; }
      .illustration.hero.space .star:nth-child(151) {
        top: 64vh;
        left: 88vw;
        -webkit-animation: star-animation 4204ms infinite;
                animation: star-animation 4204ms infinite; }
      .illustration.hero.space .star.lg:nth-child(151) {
        top: 13vh;
        left: 15vw;
        -webkit-animation: star-animation 1794ms infinite;
                animation: star-animation 1794ms infinite; }
      .illustration.hero.space .star:nth-child(152) {
        top: 52vh;
        left: 8vw;
        -webkit-animation: star-animation 3975ms infinite;
                animation: star-animation 3975ms infinite; }
      .illustration.hero.space .star.lg:nth-child(152) {
        top: 80vh;
        left: 96vw;
        -webkit-animation: star-animation 2325ms infinite;
                animation: star-animation 2325ms infinite; }
      .illustration.hero.space .star:nth-child(153) {
        top: 45vh;
        left: 13vw;
        -webkit-animation: star-animation 9876ms infinite;
                animation: star-animation 9876ms infinite; }
      .illustration.hero.space .star.lg:nth-child(153) {
        top: 26vh;
        left: 18vw;
        -webkit-animation: star-animation 6326ms infinite;
                animation: star-animation 6326ms infinite; }
      .illustration.hero.space .star:nth-child(154) {
        top: 1vh;
        left: 49vw;
        -webkit-animation: star-animation 9717ms infinite;
                animation: star-animation 9717ms infinite; }
      .illustration.hero.space .star.lg:nth-child(154) {
        top: 77vh;
        left: 18vw;
        -webkit-animation: star-animation 6963ms infinite;
                animation: star-animation 6963ms infinite; }
      .illustration.hero.space .star:nth-child(155) {
        top: 29vh;
        left: 50vw;
        -webkit-animation: star-animation 6903ms infinite;
                animation: star-animation 6903ms infinite; }
      .illustration.hero.space .star.lg:nth-child(155) {
        top: 78vh;
        left: 22vw;
        -webkit-animation: star-animation 3346ms infinite;
                animation: star-animation 3346ms infinite; }
      .illustration.hero.space .star:nth-child(156) {
        top: 79vh;
        left: 37vw;
        -webkit-animation: star-animation 10655ms infinite;
                animation: star-animation 10655ms infinite; }
      .illustration.hero.space .star.lg:nth-child(156) {
        top: 90vh;
        left: 56vw;
        -webkit-animation: star-animation 3086ms infinite;
                animation: star-animation 3086ms infinite; }
      .illustration.hero.space .star:nth-child(157) {
        top: 98vh;
        left: 32vw;
        -webkit-animation: star-animation 3656ms infinite;
                animation: star-animation 3656ms infinite; }
      .illustration.hero.space .star.lg:nth-child(157) {
        top: 49vh;
        left: 40vw;
        -webkit-animation: star-animation 8653ms infinite;
                animation: star-animation 8653ms infinite; }
      .illustration.hero.space .star:nth-child(158) {
        top: 53vh;
        left: 19vw;
        -webkit-animation: star-animation 4464ms infinite;
                animation: star-animation 4464ms infinite; }
      .illustration.hero.space .star.lg:nth-child(158) {
        top: 38vh;
        left: 42vw;
        -webkit-animation: star-animation 5820ms infinite;
                animation: star-animation 5820ms infinite; }
      .illustration.hero.space .star:nth-child(159) {
        top: 54vh;
        left: 71vw;
        -webkit-animation: star-animation 4964ms infinite;
                animation: star-animation 4964ms infinite; }
      .illustration.hero.space .star.lg:nth-child(159) {
        top: 85vh;
        left: 98vw;
        -webkit-animation: star-animation 2558ms infinite;
                animation: star-animation 2558ms infinite; }
      .illustration.hero.space .star:nth-child(160) {
        top: 14vh;
        left: 4vw;
        -webkit-animation: star-animation 4234ms infinite;
                animation: star-animation 4234ms infinite; }
      .illustration.hero.space .star.lg:nth-child(160) {
        top: 2vh;
        left: 91vw;
        -webkit-animation: star-animation 855ms infinite;
                animation: star-animation 855ms infinite; }
      .illustration.hero.space .star:nth-child(161) {
        top: 44vh;
        left: 25vw;
        -webkit-animation: star-animation 2908ms infinite;
                animation: star-animation 2908ms infinite; }
      .illustration.hero.space .star.lg:nth-child(161) {
        top: 7vh;
        left: 13vw;
        -webkit-animation: star-animation 2456ms infinite;
                animation: star-animation 2456ms infinite; }
      .illustration.hero.space .star:nth-child(162) {
        top: 32vh;
        left: 57vw;
        -webkit-animation: star-animation 8086ms infinite;
                animation: star-animation 8086ms infinite; }
      .illustration.hero.space .star.lg:nth-child(162) {
        top: 78vh;
        left: 33vw;
        -webkit-animation: star-animation 7710ms infinite;
                animation: star-animation 7710ms infinite; }
      .illustration.hero.space .star:nth-child(163) {
        top: 86vh;
        left: 42vw;
        -webkit-animation: star-animation 7037ms infinite;
                animation: star-animation 7037ms infinite; }
      .illustration.hero.space .star.lg:nth-child(163) {
        top: 63vh;
        left: 14vw;
        -webkit-animation: star-animation 4898ms infinite;
                animation: star-animation 4898ms infinite; }
      .illustration.hero.space .star:nth-child(164) {
        top: 15vh;
        left: 73vw;
        -webkit-animation: star-animation 5028ms infinite;
                animation: star-animation 5028ms infinite; }
      .illustration.hero.space .star.lg:nth-child(164) {
        top: 70vh;
        left: 92vw;
        -webkit-animation: star-animation 2283ms infinite;
                animation: star-animation 2283ms infinite; }
      .illustration.hero.space .star:nth-child(165) {
        top: 93vh;
        left: 92vw;
        -webkit-animation: star-animation 1513ms infinite;
                animation: star-animation 1513ms infinite; }
      .illustration.hero.space .star.lg:nth-child(165) {
        top: 97vh;
        left: 48vw;
        -webkit-animation: star-animation 5965ms infinite;
                animation: star-animation 5965ms infinite; }
      .illustration.hero.space .star:nth-child(166) {
        top: 69vh;
        left: 9vw;
        -webkit-animation: star-animation 10330ms infinite;
                animation: star-animation 10330ms infinite; }
      .illustration.hero.space .star.lg:nth-child(166) {
        top: 51vh;
        left: 21vw;
        -webkit-animation: star-animation 6157ms infinite;
                animation: star-animation 6157ms infinite; }
      .illustration.hero.space .star:nth-child(167) {
        top: 64vh;
        left: 32vw;
        -webkit-animation: star-animation 4306ms infinite;
                animation: star-animation 4306ms infinite; }
      .illustration.hero.space .star.lg:nth-child(167) {
        top: 50vh;
        left: 75vw;
        -webkit-animation: star-animation 1268ms infinite;
                animation: star-animation 1268ms infinite; }
      .illustration.hero.space .star:nth-child(168) {
        top: 60vh;
        left: 37vw;
        -webkit-animation: star-animation 8806ms infinite;
                animation: star-animation 8806ms infinite; }
      .illustration.hero.space .star.lg:nth-child(168) {
        top: 42vh;
        left: 60vw;
        -webkit-animation: star-animation 4745ms infinite;
                animation: star-animation 4745ms infinite; }
      .illustration.hero.space .star:nth-child(169) {
        top: 79vh;
        left: 76vw;
        -webkit-animation: star-animation 5554ms infinite;
                animation: star-animation 5554ms infinite; }
      .illustration.hero.space .star.lg:nth-child(169) {
        top: 58vh;
        left: 29vw;
        -webkit-animation: star-animation 7673ms infinite;
                animation: star-animation 7673ms infinite; }
      .illustration.hero.space .star:nth-child(170) {
        top: 3vh;
        left: 46vw;
        -webkit-animation: star-animation 1812ms infinite;
                animation: star-animation 1812ms infinite; }
      .illustration.hero.space .star.lg:nth-child(170) {
        top: 11vh;
        left: 62vw;
        -webkit-animation: star-animation 2349ms infinite;
                animation: star-animation 2349ms infinite; }
      .illustration.hero.space .star:nth-child(171) {
        top: 83vh;
        left: 23vw;
        -webkit-animation: star-animation 6818ms infinite;
                animation: star-animation 6818ms infinite; }
      .illustration.hero.space .star.lg:nth-child(171) {
        top: 79vh;
        left: 22vw;
        -webkit-animation: star-animation 3618ms infinite;
                animation: star-animation 3618ms infinite; }
      .illustration.hero.space .star:nth-child(172) {
        top: 58vh;
        left: 7vw;
        -webkit-animation: star-animation 10355ms infinite;
                animation: star-animation 10355ms infinite; }
      .illustration.hero.space .star.lg:nth-child(172) {
        top: 98vh;
        left: 44vw;
        -webkit-animation: star-animation 8548ms infinite;
                animation: star-animation 8548ms infinite; }
      .illustration.hero.space .star:nth-child(173) {
        top: 38vh;
        left: 89vw;
        -webkit-animation: star-animation 2117ms infinite;
                animation: star-animation 2117ms infinite; }
      .illustration.hero.space .star.lg:nth-child(173) {
        top: 24vh;
        left: 93vw;
        -webkit-animation: star-animation 881ms infinite;
                animation: star-animation 881ms infinite; }
      .illustration.hero.space .star:nth-child(174) {
        top: 98vh;
        left: 69vw;
        -webkit-animation: star-animation 7934ms infinite;
                animation: star-animation 7934ms infinite; }
      .illustration.hero.space .star.lg:nth-child(174) {
        top: 19vh;
        left: 31vw;
        -webkit-animation: star-animation 7990ms infinite;
                animation: star-animation 7990ms infinite; }
      .illustration.hero.space .star:nth-child(175) {
        top: 75vh;
        left: 8vw;
        -webkit-animation: star-animation 8741ms infinite;
                animation: star-animation 8741ms infinite; }
      .illustration.hero.space .star.lg:nth-child(175) {
        top: 54vh;
        left: 97vw;
        -webkit-animation: star-animation 8794ms infinite;
                animation: star-animation 8794ms infinite; }
      .illustration.hero.space .star:nth-child(176) {
        top: 2vh;
        left: 96vw;
        -webkit-animation: star-animation 5032ms infinite;
                animation: star-animation 5032ms infinite; }
      .illustration.hero.space .star.lg:nth-child(176) {
        top: 79vh;
        left: 45vw;
        -webkit-animation: star-animation 9566ms infinite;
                animation: star-animation 9566ms infinite; }
      .illustration.hero.space .star:nth-child(177) {
        top: 91vh;
        left: 8vw;
        -webkit-animation: star-animation 9627ms infinite;
                animation: star-animation 9627ms infinite; }
      .illustration.hero.space .star.lg:nth-child(177) {
        top: 95vh;
        left: 48vw;
        -webkit-animation: star-animation 6952ms infinite;
                animation: star-animation 6952ms infinite; }
      .illustration.hero.space .star:nth-child(178) {
        top: 11vh;
        left: 66vw;
        -webkit-animation: star-animation 4125ms infinite;
                animation: star-animation 4125ms infinite; }
      .illustration.hero.space .star.lg:nth-child(178) {
        top: 94vh;
        left: 13vw;
        -webkit-animation: star-animation 6369ms infinite;
                animation: star-animation 6369ms infinite; }
      .illustration.hero.space .star:nth-child(179) {
        top: 29vh;
        left: 98vw;
        -webkit-animation: star-animation 3021ms infinite;
                animation: star-animation 3021ms infinite; }
      .illustration.hero.space .star.lg:nth-child(179) {
        top: 64vh;
        left: 45vw;
        -webkit-animation: star-animation 1601ms infinite;
                animation: star-animation 1601ms infinite; }
      .illustration.hero.space .star:nth-child(180) {
        top: 98vh;
        left: 31vw;
        -webkit-animation: star-animation 9047ms infinite;
                animation: star-animation 9047ms infinite; }
      .illustration.hero.space .star.lg:nth-child(180) {
        top: 7vh;
        left: 37vw;
        -webkit-animation: star-animation 1875ms infinite;
                animation: star-animation 1875ms infinite; }
      .illustration.hero.space .star:nth-child(181) {
        top: 61vh;
        left: 25vw;
        -webkit-animation: star-animation 5043ms infinite;
                animation: star-animation 5043ms infinite; }
      .illustration.hero.space .star.lg:nth-child(181) {
        top: 64vh;
        left: 35vw;
        -webkit-animation: star-animation 2583ms infinite;
                animation: star-animation 2583ms infinite; }
      .illustration.hero.space .star:nth-child(182) {
        top: 13vh;
        left: 53vw;
        -webkit-animation: star-animation 1290ms infinite;
                animation: star-animation 1290ms infinite; }
      .illustration.hero.space .star.lg:nth-child(182) {
        top: 56vh;
        left: 80vw;
        -webkit-animation: star-animation 5068ms infinite;
                animation: star-animation 5068ms infinite; }
      .illustration.hero.space .star:nth-child(183) {
        top: 40vh;
        left: 62vw;
        -webkit-animation: star-animation 7464ms infinite;
                animation: star-animation 7464ms infinite; }
      .illustration.hero.space .star.lg:nth-child(183) {
        top: 79vh;
        left: 55vw;
        -webkit-animation: star-animation 892ms infinite;
                animation: star-animation 892ms infinite; }
      .illustration.hero.space .star:nth-child(184) {
        top: 44vh;
        left: 67vw;
        -webkit-animation: star-animation 4727ms infinite;
                animation: star-animation 4727ms infinite; }
      .illustration.hero.space .star.lg:nth-child(184) {
        top: 9vh;
        left: 45vw;
        -webkit-animation: star-animation 4935ms infinite;
                animation: star-animation 4935ms infinite; }
      .illustration.hero.space .star:nth-child(185) {
        top: 17vh;
        left: 72vw;
        -webkit-animation: star-animation 9389ms infinite;
                animation: star-animation 9389ms infinite; }
      .illustration.hero.space .star.lg:nth-child(185) {
        top: 86vh;
        left: 66vw;
        -webkit-animation: star-animation 6155ms infinite;
                animation: star-animation 6155ms infinite; }
      .illustration.hero.space .star:nth-child(186) {
        top: 11vh;
        left: 72vw;
        -webkit-animation: star-animation 8491ms infinite;
                animation: star-animation 8491ms infinite; }
      .illustration.hero.space .star.lg:nth-child(186) {
        top: 42vh;
        left: 18vw;
        -webkit-animation: star-animation 6524ms infinite;
                animation: star-animation 6524ms infinite; }
      .illustration.hero.space .star:nth-child(187) {
        top: 42vh;
        left: 12vw;
        -webkit-animation: star-animation 6405ms infinite;
                animation: star-animation 6405ms infinite; }
      .illustration.hero.space .star.lg:nth-child(187) {
        top: 88vh;
        left: 40vw;
        -webkit-animation: star-animation 8800ms infinite;
                animation: star-animation 8800ms infinite; }
      .illustration.hero.space .star:nth-child(188) {
        top: 1vh;
        left: 27vw;
        -webkit-animation: star-animation 3391ms infinite;
                animation: star-animation 3391ms infinite; }
      .illustration.hero.space .star.lg:nth-child(188) {
        top: 15vh;
        left: 98vw;
        -webkit-animation: star-animation 725ms infinite;
                animation: star-animation 725ms infinite; }
      .illustration.hero.space .star:nth-child(189) {
        top: 39vh;
        left: 63vw;
        -webkit-animation: star-animation 1048ms infinite;
                animation: star-animation 1048ms infinite; }
      .illustration.hero.space .star.lg:nth-child(189) {
        top: 42vh;
        left: 90vw;
        -webkit-animation: star-animation 10353ms infinite;
                animation: star-animation 10353ms infinite; }
      .illustration.hero.space .star:nth-child(190) {
        top: 80vh;
        left: 48vw;
        -webkit-animation: star-animation 10140ms infinite;
                animation: star-animation 10140ms infinite; }
      .illustration.hero.space .star.lg:nth-child(190) {
        top: 46vh;
        left: 70vw;
        -webkit-animation: star-animation 7076ms infinite;
                animation: star-animation 7076ms infinite; }
      .illustration.hero.space .star:nth-child(191) {
        top: 51vh;
        left: 48vw;
        -webkit-animation: star-animation 5365ms infinite;
                animation: star-animation 5365ms infinite; }
      .illustration.hero.space .star.lg:nth-child(191) {
        top: 4vh;
        left: 5vw;
        -webkit-animation: star-animation 2731ms infinite;
                animation: star-animation 2731ms infinite; }
      .illustration.hero.space .star:nth-child(192) {
        top: 75vh;
        left: 83vw;
        -webkit-animation: star-animation 2280ms infinite;
                animation: star-animation 2280ms infinite; }
      .illustration.hero.space .star.lg:nth-child(192) {
        top: 48vh;
        left: 91vw;
        -webkit-animation: star-animation 3468ms infinite;
                animation: star-animation 3468ms infinite; }
      .illustration.hero.space .star:nth-child(193) {
        top: 60vh;
        left: 58vw;
        -webkit-animation: star-animation 3255ms infinite;
                animation: star-animation 3255ms infinite; }
      .illustration.hero.space .star.lg:nth-child(193) {
        top: 5vh;
        left: 40vw;
        -webkit-animation: star-animation 6563ms infinite;
                animation: star-animation 6563ms infinite; }
      .illustration.hero.space .star:nth-child(194) {
        top: 71vh;
        left: 67vw;
        -webkit-animation: star-animation 7573ms infinite;
                animation: star-animation 7573ms infinite; }
      .illustration.hero.space .star.lg:nth-child(194) {
        top: 88vh;
        left: 35vw;
        -webkit-animation: star-animation 2951ms infinite;
                animation: star-animation 2951ms infinite; }
      .illustration.hero.space .star:nth-child(195) {
        top: 89vh;
        left: 73vw;
        -webkit-animation: star-animation 5244ms infinite;
                animation: star-animation 5244ms infinite; }
      .illustration.hero.space .star.lg:nth-child(195) {
        top: 76vh;
        left: 55vw;
        -webkit-animation: star-animation 3966ms infinite;
                animation: star-animation 3966ms infinite; }
      .illustration.hero.space .star:nth-child(196) {
        top: 15vh;
        left: 8vw;
        -webkit-animation: star-animation 10156ms infinite;
                animation: star-animation 10156ms infinite; }
      .illustration.hero.space .star.lg:nth-child(196) {
        top: 70vh;
        left: 11vw;
        -webkit-animation: star-animation 2402ms infinite;
                animation: star-animation 2402ms infinite; }
      .illustration.hero.space .star:nth-child(197) {
        top: 6vh;
        left: 60vw;
        -webkit-animation: star-animation 5581ms infinite;
                animation: star-animation 5581ms infinite; }
      .illustration.hero.space .star.lg:nth-child(197) {
        top: 93vh;
        left: 71vw;
        -webkit-animation: star-animation 1363ms infinite;
                animation: star-animation 1363ms infinite; }
      .illustration.hero.space .star:nth-child(198) {
        top: 23vh;
        left: 61vw;
        -webkit-animation: star-animation 8634ms infinite;
                animation: star-animation 8634ms infinite; }
      .illustration.hero.space .star.lg:nth-child(198) {
        top: 40vh;
        left: 85vw;
        -webkit-animation: star-animation 9598ms infinite;
                animation: star-animation 9598ms infinite; }
      .illustration.hero.space .star:nth-child(199) {
        top: 58vh;
        left: 10vw;
        -webkit-animation: star-animation 6966ms infinite;
                animation: star-animation 6966ms infinite; }
      .illustration.hero.space .star.lg:nth-child(199) {
        top: 12vh;
        left: 96vw;
        -webkit-animation: star-animation 8528ms infinite;
                animation: star-animation 8528ms infinite; }
      .illustration.hero.space .star:nth-child(200) {
        top: 11vh;
        left: 94vw;
        -webkit-animation: star-animation 6212ms infinite;
                animation: star-animation 6212ms infinite; }
      .illustration.hero.space .star.lg:nth-child(200) {
        top: 8vh;
        left: 90vw;
        -webkit-animation: star-animation 1506ms infinite;
                animation: star-animation 1506ms infinite; }

@-webkit-keyframes star-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  50% {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); } }

@keyframes star-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  50% {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); } }

@-webkit-keyframes grow-space-bear-animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2) rotate(5deg);
            transform: scale(1.2) rotate(5deg); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes grow-space-bear-animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2) rotate(5deg);
            transform: scale(1.2) rotate(5deg); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes rotate-moon-animation {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(0.25rem);
            transform: translateY(0.25rem); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes rotate-moon-animation {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(0.25rem);
            transform: translateY(0.25rem); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@media (min-width: 57.5rem) {
  .section {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

.bb-body-parts {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%; }
  .bb-body-parts .body-part {
    overflow-x: hidden;
    padding: 0;
    width: 50%; }
    @media (min-width: 57.5rem) {
      .bb-body-parts .body-part {
        width: 75%; } }
  .bb-body-parts .head {
    z-index: 10;
    opacity: 0;
    -webkit-transform: translateY(calc(-4rem + 20%));
            transform: translateY(calc(-4rem + 20%));
    -webkit-animation: head-animation 1.25s forwards;
            animation: head-animation 1.25s forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-delay: .25s;
            animation-delay: .25s;
    will-change: head-animation; }
  .bb-body-parts .torso {
    z-index: 9;
    opacity: 0;
    -webkit-transform: translateX(-4rem);
            transform: translateX(-4rem);
    -webkit-animation: torso-animation 1.25s forwards;
            animation: torso-animation 1.25s forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-delay: .25s;
            animation-delay: .25s;
    will-change: torso-animation; }
  .bb-body-parts .legs {
    z-index: 8;
    opacity: 0;
    -webkit-transform: translateY(calc(4rem - 70%));
            transform: translateY(calc(4rem - 70%));
    -webkit-animation: legs-animation 1.25s forwards;
            animation: legs-animation 1.25s forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-delay: .25s;
            animation-delay: .25s;
    will-change: legs-animation; }

.intro {
  opacity: 0;
  -webkit-transform: translateY(calc(-2rem + 10%));
          transform: translateY(calc(-2rem + 10%));
  -webkit-animation: intro-animation 1s forwards;
          animation: intro-animation 1s forwards;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: .25s;
          animation-delay: .25s;
  will-change: intro-animation; }
  .intro h1 {
    font-weight: 400;
    font-family: "Baloo Bhaina", "Helvetica", "Arial", sans-serif;
    line-height: 1; }

@-webkit-keyframes head-animation {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); } }

@keyframes head-animation {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); } }

@-webkit-keyframes torso-animation {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes torso-animation {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes legs-animation {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(-70%);
            transform: translateY(-70%); } }

@keyframes legs-animation {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(-70%);
            transform: translateY(-70%); } }

@-webkit-keyframes intro-animation {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); } }

@keyframes intro-animation {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); } }

.doc-svg .head,
.doc-svg .bones {
  -webkit-transform: rotate(0);
          transform: rotate(0);
  -webkit-transform-origin: center;
          transform-origin: center; }

.doc-svg .head {
  -webkit-animation: head-rotate-movement 4s infinite;
          animation: head-rotate-movement 4s infinite; }

.doc-svg .bones {
  -webkit-animation: bones-rotate-movement 4s infinite;
          animation: bones-rotate-movement 4s infinite; }

.doc-svg .eyes {
  -webkit-animation: eye-flicker 4s infinite;
          animation: eye-flicker 4s infinite;
  fill: #181818; }

.doc-svg .arm {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-animation: arm-movement 6s infinite;
          animation: arm-movement 6s infinite; }

@-webkit-keyframes head-rotate-movement {
  0%,
  12% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  4% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  8% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg); } }

@keyframes head-rotate-movement {
  0%,
  12% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  4% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  8% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg); } }

@-webkit-keyframes bones-rotate-movement {
  0%,
  12% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  4% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg); }
  8% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); } }

@keyframes bones-rotate-movement {
  0%,
  12% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  4% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg); }
  8% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); } }

@-webkit-keyframes eye-flicker {
  0%,
  90% {
    fill: #181818; }
  10% {
    fill: #FFFFFF; } }

@keyframes eye-flicker {
  0%,
  90% {
    fill: #181818; }
  10% {
    fill: #FFFFFF; } }

@-webkit-keyframes arm-movement {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem); } }

@keyframes arm-movement {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem); } }

.warning.bb-alert {
  background: #faae68;
  color: black;
  border-color: #f89437; }
  .warning.bb-alert h1:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .warning.bb-alert h2:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .warning.bb-alert h3:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .warning.bb-alert h4:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .warning.bb-alert h5:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title),
  .warning.bb-alert p:not(.text-primary):not(.text-secondary):not(.text-positive):not(.text-negative):not(.text-invert):not(.text-white):not(.text-light-grey):not(.text-lighter-grey):not(.header-title) {
    color: black; }
  .warning.bb-alert a:not(.bb-btn):not(.link) {
    color: black; }

body {
  padding-bottom: env(safe-area-inset-bottom);
  background: #fafafa; }

@media (min-width: 57.5rem) {
  .text-center-lg {
    text-align: center; } }

