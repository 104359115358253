pre[class*='language-'] {
	display: block;
	overflow-x: auto;
	background: transparent;
	code {
		padding: 0;
		background: transparent;
		color: $base-color;
	}
	&.language-markup {
		.punctuation {
			color: $primary-color;
			font-weight: $font-weight-bold;
		}
		.tag {
			.token.tag {
				color: $primary-color;
				font-weight: $font-weight-bold;
			}
		}
		.attr-name {
			color: $negative-color;
		}
		.attr-value {
			color: $positive-color;
		}
	}
	&.language-js {
		display: inline-block;
		padding: $padding / 2;
		width: 100%;
		border-left: $border-style $border-width * 2 $secondary-color;
		border-radius: 0;
		background: $base-pair;
		color: $primary-color;
		font-weight: $font-weight-bold;
		code {
			color: $primary-color;
		}
	}
}

.bb-table code {
	white-space: nowrap;
}

code {
	padding: $padding / 8 $padding / 2;
	border-radius: $border-radius;
	background: $primary-color;
	color: $primary-pair;
}

.code-block {
	display: inline-block;
	margin: 1rem 0;
	padding: $padding / 2;
	width: 100%;
	border-left: $border-style $border-width * 2 $secondary-color;
	border-radius: 0;
	background: $base-pair;
	pre {
		margin: 0;
	}
}
