//// CORE ////

// Colour //

$primary-color: #0A344D !default;
$primary-pair: #FFFFFF !default;
//
$secondary-color: #FA7268 !default;
$secondary-pair: #FFFFFF !default;
//
$positive-color: #50BF63 !default;
$positive-pair: #FFFFFF !default;
//
$negative-color: #DC4C41 !default;
$negative-pair: #FFFFFF !default;
//
$base-color: #030303 !default;
$base-pair: #FFFFFF !default;
//
$white: #FFFFFF !default;
$light-grey: #A0A0A0 !default;
$lighter-grey: #E0E0E0 !default;
$black: #000000 !default;
$body-background: $white !default;

// Base
$base-variant-background: $base-color !default;
$base-variant-border: darken($base-variant-background, 10%) !default;
$base-variant-color: $base-pair !default;
$base-variant-hover: darken($base-variant-background, 10%) !default;
$base-variant-active: darken($base-variant-background, 12%) !default;

// Invert
$invert-variant-background: $base-pair !default;
$invert-variant-border: darken($invert-variant-background, 10%) !default;
$invert-variant-color: $base-color !default;
$invert-variant-hover: darken($invert-variant-background, 10%) !default;
$invert-variant-active: darken($invert-variant-background, 12%) !default;

// Primary
$primary-variant-background: $primary-color !default;
$primary-variant-border: darken($primary-variant-background, 10%) !default;
$primary-variant-color: $primary-pair !default;
$primary-variant-hover: darken($primary-variant-background, 10%) !default;
$primary-variant-active: darken($primary-variant-background, 12%) !default;

// Secondary
$secondary-variant-background: $secondary-color !default;
$secondary-variant-border: darken($secondary-variant-background, 10%) !default;
$secondary-variant-color: $secondary-pair !default;
$secondary-variant-hover: darken($secondary-variant-background, 10%) !default;
$secondary-variant-active: darken($secondary-variant-background, 12%) !default;

// Positive
$positive-variant-background: $positive-color !default;
$positive-variant-border: darken($positive-variant-background, 10%) !default;
$positive-variant-color: $positive-pair !default;
$positive-variant-hover: darken($positive-variant-background, 10%) !default;
$positive-variant-active: darken($positive-variant-background, 12%) !default;

// Negative
$negative-variant-background: $negative-color !default;
$negative-variant-border: darken($negative-variant-background, 10%) !default;
$negative-variant-color: $negative-pair !default;
$negative-variant-hover: darken($negative-variant-background, 10%) !default;
$negative-variant-active: darken($negative-variant-background, 12%) !default;

// Spacing //

$padding: 1rem !default;
$margin: 1.5rem !default;
$body-spacing-y: .5rem !default;

////****************** LAYOUT ////

$layout-variant-background: darken($base-pair, 2%) !default;
$layout-variant-color: $base-color !default;
//
$container-max-width: 75rem !default;
$layout-spacing-x: 1.5rem !default;
$layout-spacing-y: 2rem !default;
$layout-spacing-lg: $layout-spacing-y * 4 !default;

//// GRID ////

$grid-spacing-x: .5rem !default;
$grid-spacing-y: .5rem !default;
$column-spacing-x: .5rem !default;
$column-spacing-y: .5rem !default;
$grid-max-column: 16 !default;

// Breakpoints
$breakpoints: (
  sm: 28rem,
  md: 40rem,
  lg: 57.5rem,
  xl: 75rem
) !default;

// Border //

$border-width: .125rem !default;
$border-style: solid !default;
$border-radius: .2rem !default;
$border-radius-circle: 50% !default;
$no-border-radius: 0rem !default;

// Link //

$link-color: $base-color !default;
$link-decoration: none !default;
$link-hover-decoration: underline !default;

// Typography //

// Body
$body-font-size: 1rem !default;
$font-color: $base-color !default;
$font-family: 'Inter', 'Helvetica', 'Arial', sans-serif !default;
$font-line-height: 1.6 !default;
$font-size: 1rem !default;
$font-style: normal !default;
$font-variant: normal !default;
$font-weight: normal !default;
$font-weight-bold: 700 !default;
$font-weight-light: 300 !default;

// Variant
$font-size-xs: .5rem !default;
$font-size-sm: .75rem !default;
$font-size-md: 1rem !default;
$font-size-lg: 1.25rem !default;
$font-size-xl: 1.5rem !default;

//******************Heading
$heading-font-color: $base-color !default;
$logo-font-family: 'Baloo Bhaina', 'Helvetica', 'Arial', sans-serif !default;
$heading-font-family: 'Ubuntu', 'Helvetica', 'Arial', sans-serif !default;
$heading-font-weight: $font-weight-bold !default;
$heading-line-height: $font-line-height !default;
$heading-min-width: 20rem !default;
$heading-max-width: $container-max-width !default;
$heading-min-font: 1rem !default;
$heading-min-modifier: 1.25 !default;
$heading-max-modifier: 1.5 !default;

//******************Title
$subtitle-font-size: $font-size-lg !default;
$subtitle-font-size-sm: $font-size-md !default;
$subtitle-font-size-lg: $font-size-xl !default;
$title-line-height: $heading-line-height !default;
$title-spacing-y: 1.5rem !default;

//******************Transition //

$transition-effect: ease-in-out !default;
$transition-duration: .25s !default;

//// COMPONENTS ////

//******************Accordion //

$accordion-variant-background: transparent !default;
$accordion-variant-border: $lighter-grey !default;
$accordion-variant-toggle: $light-grey !default;
$accordion-variant-toggle-active: $light-grey !default;
//
$accordion-border-style: $border-style !default;
$accordion-border-width: $border-width !default;
$accordion-border-radius: $border-radius !default;
//
$accordion-heading-font-family: $font-family !default;
$accordion-heading-font-size: $font-size-xl !default;
$accordion-heading-font-weight: $font-weight-bold !default;
$accordion-subheading-font-family: $font-family !default;
$accordion-subheading-font-size: $font-size-lg !default;
$accordion-subheading-font-weight: $font-weight-bold !default;
//
$accordion-heading-spacing-x: 0 !default;
$accordion-heading-spacing-y: 1rem !default;
$accordion-content-spacing-x: 1rem !default;
$accordion-content-spacing-y: 1rem !default;
$accordion-toggle-spacing-y: 0 !default;
$accordion-toggle-spacing-x: 1rem !default;
$accordion-toggle-width: .5rem !default;
$accordion-toggle-height: .5rem !default;
//
$accordion-toggle-transition: all $transition-effect $transition-duration !default;

// Alert //

$alert-variant-background: $base-variant-background !default;
$alert-variant-color: $base-variant-color !default;
$alert-variant-border: $base-variant-border !default;
//
$alert-border-radius: $border-radius !default;
$alert-border-style: $border-style !default;
$alert-border-width: $border-width !default;
//
$alert-spacing-x: 1rem !default;
$alert-spacing-y: 1rem !default;

//******************Animation //

$slide-transition-duration: 2s !default;
$slide-transition-effect: $transition-effect !default;
$animation-bar-background: $lighter-grey !default;
$animation-bar-border: .5rem !default;
$animation-bar-border-radius: 50% !default;
$animation-bar-size: .5rem !default;
$animation-bar-transition-duration: 5s !default;
$loader-pulse-border-radius: 50% !default;
$loader-pulse-size: 2rem !default;
$loader-pulse-transition-duration: 1.75s !default;
$loader-spinner-border: .5rem !default;
$loader-spinner-border-radius: 50% !default;
$loader-spinner-size: 4rem !default;
$loader-spinner-offset: -($loader-spinner-size / 2) !default;
$loader-spinner-transition-duration: 1s !default;
$animation-audio-width: 4rem;
$animation-audio-height: 4rem;
$animation-audio-bar-width: .25rem;
$animation-audio-bar-variant-color: $white;
$animation-audio-bar-animation-duration: 1.5s;
$animation-flash-transition-duration: 1.75s !default;

// Box //

$box-variant-background: $base-variant-background !default;
$box-variant-color: $base-variant-color !default;
$box-variant-border: $base-variant-border !default;
//
$box-border-radius: $border-radius !default;
$box-border-style: $border-style !default;
$box-border-width: $border-width !default;
//
$box-spacing-x: 1rem !default;
$box-spacing-y: 1.5rem !default;

// Breadcrumb //

$breadcrumb-variant-background: transparent !default;
$breadcrumb-variant-color: $base-color !default;
$breadcrumb-variant-active: $secondary-color !default;
$breadcrumb-divider: '|' !default;
$breadcrumb-variant-divider: $base-color !default;
//
$breadcrumb-item-spacing: 1rem !default;
$breadcrumb-spacing: 1rem !default;

// Button //

$button: ('.bb-btn, a.bb-btn') !default;
//
$button-variant-background: $base-variant-background !default;
$button-variant-color: $base-variant-color !default;
$button-variant-border: $base-variant-border !default;
$button-variant-hover: $base-variant-hover !default;
$button-variant-active: $base-variant-active !default;
$button-variant-disabled-opacity: .2 !default;
//
$button-border-radius: $border-radius !default;
$button-border-style: $border-style !default;
$button-border-width: $border-width !default;
//
$button-box-shadow: none;
$button-box-shadow-hover: none;
//
$button-font-family: $font-family !default;
$button-font-size: $font-size !default;
$button-font-weight: $font-weight !default;
$button-line-height: 1.3 !default;
$button-text-transform: none !default;
//
$button-spacing-x: .5rem !default;
$button-spacing-y: .5rem !default;
//
$button-transition: all $transition-effect $transition-duration !default;
// Close button
$button-close-icon-size: .125rem !default;
$button-close-size: 1.25rem !default;
$button-close-size-xs: .85rem !default;
$button-close-size-sm: 1.05rem !default;
$button-close-size-lg: 1.45rem !default;
$button-close-size-xl: 1.65rem !default;
$button-close-transform: translateX(-50%) translateY(-50%) rotate(45deg) !default;
// Loading button
$button-loader-border: .1rem !default;
$button-loader-circle: 50% !default;
$button-loader-size: .75rem !default;
$button-loader-offset: -($button-loader-size / 2) !default;
$button-loader-spacing: $button-loader-offset 0 0 $button-loader-offset !default;
// Button Group
$button-group-border-radius: $button-border-radius !default;
$button-group-spacing-x: $button-spacing-x;
$button-group-spacing-y: $button-spacing-y;

// Card //

$card-variant-background: $white !default;
$card-variant-color: $base-variant-background !default;
$card-variant-border: $lighter-grey !default;
$card-variant-hover: $lighter-grey !default;
//
$card-border-radius: $border-radius * 2 !default;
$card-border-style: $border-style !default;
$card-border-width: $border-width !default;
//
$card-header-variant-border: $card-variant-border !default;
$card-header-variant-color: $primary-variant-background !default;
$card-header-font-family: $heading-font-family !default;
$card-header-font-weight: $font-weight-bold !default;
$card-header-border-style: $card-border-style !default;
$card-header-border-width: $card-border-width / 2 !default;
$card-header-spacing-x: $padding !default;
$card-header-spacing-y: $padding !default;
//
$card-content-font-family: $font-family !default;
$card-content-spacing-x: $padding !default;
$card-content-spacing-y: $padding !default;
//
$card-footer-variant-border: $card-variant-border !default;
$card-footer-variant-color: $primary-variant-background !default;
$card-footer-item-variant-border: $card-footer-variant-border !default;
$card-footer-border-style: $card-border-style !default;
$card-footer-border-width: $card-border-width / 2 !default;
$card-footer-spacing-x: $padding !default;
$card-footer-spacing-y: $padding !default;
//
$card-box-shadow: 0 1.25rem 1.5rem -1.25rem rgba(10, 10, 10, .1) !default;

// Divider //

$divider-variant-border: $light-grey !default;
$divider-variant-color: $base-variant-background !default;
//
$divider-border-radius: $border-width !default;
$divider-border-style: $border-style !default;
$divider-border-width: $border-width / 2 !default;
//
$divider-content-border-width: $divider-border-width * 1.5 !default;
$divider-font-weight: $font-weight !default;
//
$divider-spacing-x: 1rem !default;
$divider-spacing-y: 1.5rem !default;

// Dropdown //

$dropdown-variant-background: $base-pair !default;
$dropdown-variant-background-hover: rgba($primary-color, .05) !default;
$dropdown-variant-background-active: rgba($primary-color, .05) !default;
$dropdown-variant-color: $base-pair !default;
$dropdown-variant-color-hover: $base-color !default;
$dropdown-variant-color-active: $base-color !default;
//
$dropdown-box-shadow: 0 3px 1px 0 rgba(0, 0, 0, .1),
  0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !default;
$dropdown-border-width: $border-width !default;
$dropdown-variant-border: $dropdown-border-width $border-style transparent !default;
$dropdown-border-radius: $border-radius !default;
//
$dropdown-menu-spacing-x: 0 !default;
$dropdown-menu-spacing-y: .5rem !default;
$dropdown-item-spacing-x: 1.5rem !default;
$dropdown-item-spacing-y: .5rem !default;
$dropdown-divider-spacing-y: .5rem !default;
$dropdown-divider-spacing-x: 0 !default;
//
$dropdown-min-width: 10rem !default;
$dropdown-offset: -.125rem !default;
$dropdown-transition: color 0s, opacity $transition-effect $transition-duration,
  transform $transition-effect $transition-duration !default;
//
$dropdown-toggle-width: .5rem !default;
$dropdown-toggle-height: .5rem !default;

// Field //

$field-variant-background: $white !default;
$field-variant-color: $base-variant-background !default;
$field-variant-border: $lighter-grey !default;
$field-variant-active: $base-variant-active !default;
$field-variant-focus: $field-variant-active !default;
$field-variant-disabled-opacity: .2 !default;
$field-variant-toggle: $light-grey !default;
$field-variant-toggle-active: $light-grey !default;
$field-variant-placeholder: $lighter-grey !default;
$field-variant-placeholder-focus: $field-variant-focus !default;
$field-variant-placeholder-active: $field-variant-active !default;
$field-variant-label: $field-variant-color !default;
$field-variant-success-color: $positive-color !default;
$field-variant-success-placeholder: $positive-color !default;
$field-variant-success-border: $positive-color !default;
$field-variant-error-color: $negative-color !default;
$field-variant-error-placeholder: $negative-color !default;
$field-variant-error-border: $negative-color !default;
$field-variant-checkbox: $field-variant-background !default;
$field-variant-checkbox-active: $primary-color !default;
$field-variant-checkbox-background-active: $field-variant-background !default;
//
$field-border-radius: $border-radius !default;
$field-border-style: $border-style !default;
$field-border-width: $border-width !default;
//
$field-font-family: $font-family !default;
$field-font-size: $font-size !default;
$field-font-weight: $font-weight !default;
$field-line-height: $font-line-height !default;
//
$field-minimum-height: 2.625rem !default;
$field-spacing-x: .5rem !default;
$field-spacing-y: .5rem !default;
//
$field-toggle-spacing-x: .75rem !default;
$field-toggle-width: .5rem !default;
$field-toggle-height: .5rem !default;
//
$field-checkbox-height: 1.5rem;
$field-checkbox-width: 1.5rem;
$field-checkbox-border-width: .125rem;
$field-checkbox-label-font-size: $font-size-sm;
//
$field-label-spacing-y: .5rem !default;
$field-label-font-weight: $font-weight !default;
$field-label-font-family: $heading-font-family !default;
$field-label-font-size: $font-size !default;
//
$field-text-font-size: $font-size-sm !default;
$field-text-spacing-y: .25rem !default;
//
$field-transition: all $transition-effect $transition-duration !default;

// Addon
$field-addon-variant-background: $field-variant-active !default;
$field-addon-variant-color: $field-variant-background !default;
$field-addon-variant-border: $field-variant-active !default;
$field-addon-variant-disabled-opacity: .2 !default;
//
$field-addon-spacing-x: .5rem !default;
$field-addon-spacing-y: .5rem !default;

// Footer //

$footer-variant-background: $primary-color !default;
$footer-variant-color: $primary-pair !default;
//
$footer-box-shadow: 0 0 1.5rem 0 rgba(10, 10, 10, .1);
//
$footer-spacing: 3rem $layout-spacing-x 6rem $layout-spacing-x !default;

// Media object //

$media-variant-border: $light-grey !default;
//
$media-border-radius: $border-radius !default;
$media-border-style: $border-style !default;
$media-border-width: $border-width !default;
//
$media-spacing-x: 1rem !default;
$media-spacing-y: 1rem !default;

// Navbar //

$navbar-variant-background: $white !default;
$navbar-variant-color: $base-variant-background !default;
$navbar-variant-border: $base-variant-border !default;
$navbar-variant-hover: rgba($primary-color, .05) !default;
$navbar-variant-active: rgba($primary-color, .05) !default;
$navbar-variant-toggle: $secondary-color !default;
//
$navbar-font-family: $heading-font-family !default;
$navbar-font-size: $font-size !default;
$navbar-font-weight: $font-weight !default;
$navbar-line-height: 1.6 !default;
//
$navbar-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .12) !default;
$navbar-height: 4rem !default;
$navbar-logo-max-height: 3rem !default;
$navbar-logo-height: $navbar-height !default;
$navbar-logo-width: $navbar-height !default;
//
$navbar-item-spacing-x: .75rem !default;
$navbar-item-spacing-y: .5rem !default;
$navbar-fixed-spacing-y: 4rem !default;
//
$navbar-toggle-width: 4rem !default;
$navbar-toggle-height: 4rem !default;

// Pagination //

$pagination-variant-background: $base-variant-background !default;
$pagination-variant-color: $base-variant-color !default;
$pagination-variant-border: $base-variant-border !default;
$pagination-variant-hover: $base-variant-hover !default;
$pagination-variant-active: $base-variant-active !default;
//
$pagination-border-radius: $border-radius !default;
$pagination-border-style: $border-style !default;
$pagination-border-width: $border-width !default;
//
$pagination-font-size: $font-size !default;
$pagination-font-weight: $font-weight-bold !default;
//
$pagination-min-width: 3rem !default;
$pagination-spacing-y: .5rem !default;
$pagination-spacing-x: .75rem !default;
//
$pagination-transition: all $transition-effect $transition-duration !default;
// Dots
$pagination-dot-border-radius: $border-radius-circle !default;
$pagination-dot-size: .3rem !default;
$pagination-dot-spacing: .1rem !default;

// Stat //

$stat-value-font-family: $heading-font-family !default;
$stat-value-font-weight: $font-weight !default;
$stat-value-font-size: 3rem !default;
$stat-value-text-transform: uppercase !default;
$stat-label-font-family: $font-family !default;
$stat-label-font-weight: $font-weight-bold !default;
$stat-label-font-size: $stat-value-font-size / 4 !default;
$stat-label-text-transform: uppercase !default;
$stat-text-font-weight: $font-weight-bold !default;
$stat-text-font-size: $stat-value-font-size / 2 !default;
$stat-line-height: 1em !default;
//
$stat-spacing-x: 1rem;
$stat-spacing-y: 1rem;
$stat-value-font-sizes: (
  xs: $stat-value-font-size - 1rem,
  sm: $stat-value-font-size - .5rem,
  lg: $stat-value-font-size + .5rem,
  xl: $stat-value-font-size + 1rem
) !default;

//FIX FONT SIZING

// Tab //

$tab-variant-background: $base-color !default;
$tab-variant-color: $base-color !default;
$tab-variant-border: rgba($tab-variant-background, .2) !default;
$tab-variant-hover: rgba($tab-variant-background, .2) !default;
$tab-variant-active: $tab-variant-hover !default;
//
$tab-border-radius: $border-radius !default;
$tab-border-style: $border-style !default;
$tab-border-width: $border-width !default;
//
$tab-spacing-x: 1rem !default;
$tab-spacing-y: .25rem !default;
//
$tab-transition: all $transition-effect $transition-duration !default;

// ******* Table //

$table-variant-background: darken($base-variant-color, 2%) !default;
$table-variant-color: $base-variant-background !default;
$table-variant-border: darken($table-variant-background, 12%) !default;
$table-variant-hover: darken($table-variant-background, 12%) !default;
$table-variant-active: darken($table-variant-background, 12%) !default;
//
$table-border-radius: $border-radius * 2 !default;
$table-border-style: $border-style !default;
$table-border-width: $border-width / 2 !default;
//
$table-spacing-x: .75rem !default;
$table-spacing-y: .75rem !default;
//
$table-transition: all $transition-effect $transition-duration !default;

//// UTILITIES ///

// List //

$list-spacing-x: 1rem !default;
$list-spacing-y: .5rem !default;

//******************Chevron

$icon-size: .5rem !default;
$icon-border-color: $light-grey !default;
$icon-border-width: .0625rem !default;
$icon-border-style: $border-style !default;
