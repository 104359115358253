@import 'core/all';
@import 'components/box/all';
@import 'components/breadcrumb/all';
@import 'components/code/all';
@import 'components/footer/all';
@import 'components/navbar/all';
@import 'components/animation/all';
@import 'layout/all';

//
$warning-color: #faae68 !default;
$warning-pair: darken($warning-color, 90%) !default;
$warning-variant-background: $warning-color !default;
$warning-variant-border: darken($warning-variant-background, 10%) !default;
$warning-variant-color: $warning-pair !default;
$warning-variant-hover: darken($warning-variant-background, 10%) !default;
$warning-variant-active: darken($warning-variant-background, 12%) !default;

.warning.bb-alert {
  @include alert-variant(
    $warning-variant-color,
    $warning-variant-background,
    $warning-variant-border
  );
}

body {
  padding-bottom: env(safe-area-inset-bottom);
  background: darken($base-pair, 2%);
}

.text-center-lg {
  @include respond-to('lg') {
    text-align: center;
  }
}
