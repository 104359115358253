.doc-page-animation-enter {
  position: absolute;
  z-index: -1;
  width: 100%;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  transition-delay: 0.25s;
  transform: translateX(-5rem);
}
.doc-page-animation-enter.doc-page-animation-enter-active {
  opacity: 1;
  transform: translateX(0);

  will-change: opacity, transform;
}
.doc-page-animation-exit {
  position: absolute;
  width: 100%;
  opacity: 1;
  transform: translateX(0);

  will-change: opacity, transform;

  h1 {
    transition: all 0.2s ease-in-out;
    transform: translateX(5rem);
  }
}
.doc-page-animation-exit.doc-page-animation-exit-active {
  opacity: 0;
  transition: all 0.25s ease-in-out;
  transform: translateX(5rem);

  will-change: opacity, transform;
}
