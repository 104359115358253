.bb-box {
	&.example {
		display: flex;
		flex-direction: column;
		padding: 0;
		background: transparent;

		@include box-variant(inherit, $base-pair, $lighter-grey);
		@include respond-to('md') {
			flex-direction: row;
		}
		&.full-x {
			flex-direction: column;
			.doc-preview,
			.doc-snippet {
				width: 100%;
			}
		}
		.doc-preview,
		.doc-snippet {
			padding: 1.5rem 1rem;

			@include respond-to('md') {
				width: 50%;
			}
			pre {
				margin: 0;
			}
		}
		.doc-preview {
			@include respond-to('md') {
				padding-right: $box-spacing-x;
			}
			@include respond-to-down('md') {
				overflow-x: scroll;
			}
			.doc-content {
				@include respond-to-down('md') {
					display: inline-flex;
					width: 100%;
				}
				&:not(:first-child) {
					margin-top: $box-spacing-y / 2;
				}
			}
		}
		.doc-snippet {
			padding-bottom: 2.5rem;
			background: darken($base-pair, 2%);

			@include respond-to('md') {
				border-top: none;
			}
			pre {
				max-height: 25rem;
			}
		}
		.doc-copy {
			position: absolute;
			right: -$border-width;
			bottom: -$border-width;
			border: none;
			border-radius: 0;
			border-top-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}
}

.doc-box {
	position: relative;
	display: block;
	padding: $box-spacing-y $box-spacing-x;
	min-height: 12rem;
	background: $base-pair;
	box-shadow: 0 1.25rem 1.5rem -1.25rem rgba(10, 10, 10, .1);
	transition-duration: 250ms;
	transition-property: box-shadow, transform;

	@include border-radius($border-radius * 2);
	@include hover {
		background: darken($base-pair, 2%);
		box-shadow: 0 1.75rem 3rem -1.25rem rgba(10, 10, 10, .2);
		text-decoration: none !important;
		transform: translateY(-.25rem);
	}
	will-change: box-shadow, transform;
	&.disabled {
		background: $base-pair;
		opacity: .4;
		pointer-events: none;
	}
}
